import React, { useState } from 'react';

const ListComponent = ({ isCustom, actions, corridorKey, corridorSegments, selectedCorridors, selectedSegments }) => {
  const [expandToggle, setExpandToggle] = useState(true);

  const hasMultipleSegments = corridorSegments.length > 1;
  const corridorId = hasMultipleSegments ? corridorKey : corridorSegments[0].Corridor_ID;

  const isIndeterminate =
    corridorSegments.length > 1 &&
    selectedSegments.length >= 1 &&
    hasMultipleSegments &&
    selectedSegments.map(sc => sc.split('_')[0]).includes(corridorId) &&
    !selectedCorridors.includes(corridorId);

  return (
    <div className="row bg-white">
      <span>
        <input
          id={corridorId}
          key={corridorId}
          name={corridorSegments[0].HWY}
          type="checkbox"
          checked={hasMultipleSegments ? selectedCorridors.includes(corridorId) : selectedSegments.includes(corridorId)}
          onChange={actions.onCorridorChecked}
          ref={input => {
            if (input) {
              input.indeterminate = isIndeterminate;
            }
          }}
        />
        <label htmlFor={corridorId}>
          {corridorKey}
          {isCustom && !hasMultipleSegments && (
            <img
              src="/assets/svg/SpecialPlus_segments.svg"
              alt="custom segment icon"
              className="custom-segment-icon-alone"
            />
          )}
          {hasMultipleSegments && <span className="segments-counter">({corridorSegments.length} segments)</span>}
        </label>

        {hasMultipleSegments && (
          <span className="display-toggle" style={{ float: 'right' }}>
            {expandToggle ? (
              <i id={`${corridorId}_collapse`} className="fa-solid fa-minus" onClick={() => setExpandToggle(false)}></i>
            ) : (
              <i id={`${corridorId}_expand`} className="fa-solid fa-plus" onClick={() => setExpandToggle(true)}></i>
            )}
          </span>
        )}
      </span>
      {expandToggle &&
        hasMultipleSegments &&
        corridorSegments.map(c => (
          <div className="row segment bg-white">
            <span>
              <input
                type="checkbox"
                id={c.Corridor_ID}
                className="segment-checkbox"
                checked={selectedSegments.includes(c.Corridor_ID)}
                onChange={actions.onSegmentChecked}
              />
              <label htmlFor={c.Corridor_ID}>
                {c.HWY}
                {c.Custom === 1 && (
                  <img
                    src="/assets/svg/SpecialPlus_segments.svg"
                    alt="custom segment icon"
                    className="custom-segment-icon"
                  />
                )}
                <span className="segment-details">
                  {c.Begin_Milepost} to {c.End_Milepost}
                </span>
                <span className="segment-details">{c.Description}</span>
              </label>
            </span>
          </div>
        ))}
      <hr className="corridor-selection-divider" />
    </div>
  );
};

export default ListComponent;
