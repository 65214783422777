import { useState } from 'react';

import useFetchCorridorData from './Hooks/useFetchCorridorData';
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner';

import CorridorSelection from './CorridorSelection';
import CorridorCreation from './CorridorCreation';
import './CorridorSelection.css';
import ErrorPAge from '../../../Components/ErrorPage/ErrorPage';

function CorridorSelectionContainer() {
  const [createNewSelected, setCreateNewSelected] = useState(false);
  const { isFetching, fetchData, error } = useFetchCorridorData();

  if (isFetching) return <LoadingSpinner />;

  if (error) return <ErrorPAge />;

  return (
    <div className="container-fluid cover-container d-flex flex-column">
      <div className="row flex-fill">
        {!createNewSelected && <CorridorSelection setCreateNewSelected={setCreateNewSelected} />}
        {createNewSelected && <CorridorCreation setCreateNewSelected={setCreateNewSelected} fetchData={fetchData} />}
      </div>
    </div>
  );
}

export default CorridorSelectionContainer;
