import { orderBy, round } from 'lodash';

export const convertToPercentage = (value = 0) => {
  if (isNaN(value)) return 'N/A';
  return (value * 100).toFixed(1) + '%';
};

export const numberFormatter = (number, roundTo = 2) => {
  if (isNaN(number)) return number;
  const roundedNumber = round(number, roundTo);
  const formattedNumber = roundedNumber.toFixed(roundTo);
  return formattedNumber;
};

export const filterSegmentData = (originalData, selectedId, selectedMode, selectedDistrict) => {
  let filteredData = originalData;

  if (selectedMode === 'District') {
    filteredData = filteredData.filter(item => item.attributes.Districts === selectedDistrict);
  }

  if (selectedId) {
    filteredData = filteredData.filter(item => item.attributes.HWY === selectedId);
  }

  return orderBy(filteredData, [`attributes.Segment`]);
};

const GOOD = '#92d050';
const FAIR = '#fec000';
const POOR = '#ff0000';
const NEUTRAL = '#adb5bd';

export const getNeedColorCode = value => {
  const rank = () => {
    if (value >= 2.5 || value === 'High') return 1;
    if (value >= 1.5 || value === 'Medium') return 3;
    if (value >= 0.5 || value === 'Low') return 4;
    return 5;
  };
  return getTierColorCodes(rank());
};

export function getColorCode(value, rangeMaps) {
  if (value === 'Good' || value === 'No UP') return GOOD;
  if (value === 'Fair') return FAIR;
  if (value === 'Poor') return POOR;
  if (value === 'N/A' || !rangeMaps) return NEUTRAL;

  let colorCode = '';
  for (const rangeMap of rangeMaps) {
    const rangeStart = rangeMap[0];
    const rangeEnd = rangeMap[1];
    const label = rangeMap[2];

    if (value >= rangeStart && value <= rangeEnd) {
      colorCode = label;
      break;
    }
  }
  return colorCode;
}

export const colorCodeMapper = {
  //  ----- Pavement Performance  ----- //
  Pavement: {
    // Payment Index
    Pavement_I: [
      [80, 10000, GOOD],
      [60, 80, FAIR],
      [-1000, 60, POOR],
    ],
    // Mainlane Directional Distress Score
    Distress_1: [
      [80, 10000, GOOD],
      [70, 80, FAIR],
      [-1000, 70, POOR],
    ],
    Distress_2: [
      [80, 10000, GOOD],
      [70, 80, FAIR],
      [-1000, 70, POOR],
    ],
    // Mainlane Directional Ride Score
    Ride_Sc_1: [
      [3.0, 10000, GOOD],
      [2.0, 3.0, FAIR],
      [-1000, 2.0, POOR],
    ],
    Ride_Sc_2: [
      [3.0, 10000, GOOD],
      [2.0, 3.0, FAIR],
      [-1000, 2.0, POOR],
    ],
    // Frontage Road Condition Score
    Frontage_1: [
      [80, 10000, GOOD],
      [60, 80, FAIR],
      [-1000, 60, POOR],
    ],
    Frontage_2: [
      [80, 10000, GOOD],
      [60, 80, FAIR],
      [-1000, 60, POOR],
    ],
    // Substandard Pavement
    Sub_Standa: [
      [-100, 5, GOOD],
      [5, 20, FAIR],
      [20, 100, POOR],
    ],
  },

  //  ----- Bridge Performance   ----- //
  Bridge: {
    // Payment Index
    Bridge_I: [
      [6.5, 10000, GOOD],
      [5, 6.5, FAIR],
      [-1000, 5, POOR],
    ],
    // Bridge Sufficiency
    Bridge_Suf: [
      [80, 10000, GOOD],
      [50, 80, FAIR],
      [-1000, 50, POOR],
    ],
    // Bridge Rating
    Bridge_Rat: [
      [5.5, 10000, GOOD],
      [4.5, 5.5, FAIR],
      [-1000, 4.5, POOR],
    ],
    // Culvert Rating
    Culvert_Ra: [
      [6.5, 10000, GOOD],
      [5, 6.5, FAIR],
      [-1000, 5, POOR],
    ],
    // Functionally Obsolete Bridges
    Obsolete_B: [
      [-100, 12, GOOD],
      [12, 40, FAIR],
      [40, 100, POOR],
    ],
    NoB: [[-1000, 1000, NEUTRAL]],
    NoFOB: [[-1000, 1000, NEUTRAL]],
  },

  // ---  Mobility Performance ---- //
  Mobility: {
    Rural: {
      // Mobility Index,
      Mobility_I: [
        [-1000, 0.56, GOOD],
        [0.56, 0.76, FAIR],
        [0.76, 1000, POOR],
      ],
      // Existing Mainlane Peak Hour V/C
      Peak_VC_1: [
        [-1000, 0.56, GOOD],
        [0.56, 0.76, FAIR],
        [0.76, 1000, POOR],
      ],
      Peak_VC_2: [
        [-1000, 0.56, GOOD],
        [0.56, 0.76, FAIR],
        [0.76, 1000, POOR],
      ],
      // Mainlane Future V/C
      Future_VC: [
        [-1000, 0.56, GOOD],
        [0.56, 0.76, FAIR],
        [0.76, 1000, POOR],
      ],
      // Frontage Road V/C
      FrontVC_21: [
        [-1000, 0.56, GOOD],
        [0.56, 0.76, FAIR],
        [0.76, 1000, POOR],
      ],
      FrontVC_41: [
        [-1000, 0.56, GOOD],
        [0.56, 0.76, FAIR],
        [0.76, 1000, POOR],
      ],
      //  Interchange V/C
      Inte_VC_21: [
        [-1000, 0.56, GOOD],
        [0.56, 0.76, FAIR],
        [0.76, 1000, POOR],
      ],
      Inte_VC_41: [
        [-1000, 0.56, GOOD],
        [0.56, 0.76, FAIR],
        [0.76, 1000, POOR],
      ],
      // Directional Travel Time Index
      TTI_all_B1: {
        I: [
          [-1000, 1.2, GOOD],
          [1.2, 2.0, FAIR],
          [2.0, 1000, POOR],
        ],
        UI: [
          [-1000, 1.05, GOOD],
          [1.05, 1.25, FAIR],
          [1.25, 1000, POOR],
        ],
      },
      TTI_all_B2: {
        I: [
          [-1000, 1.2, GOOD],
          [1.2, 2.0, FAIR],
          [2.0, 1000, POOR],
        ],
        UI: [
          [-1000, 1.05, GOOD],
          [1.05, 1.25, FAIR],
          [1.25, 1000, POOR],
        ],
      },
      // Directional Planning Time Index
      PTI_all_B1: {
        I: [
          [-1000, 1.25, GOOD],
          [1.25, 2.25, FAIR],
          [2.25, 1000, POOR],
        ],
        UI: [
          [-1000, 1.15, GOOD],
          [1.15, 1.6, FAIR],
          [1.6, 1000, POOR],
        ],
      },
      PTI_all_B2: {
        I: [
          [-1000, 1.25, GOOD],
          [1.25, 2.25, FAIR],
          [2.25, 1000, POOR],
        ],
        UI: [
          [-1000, 1.15, GOOD],
          [1.15, 1.6, FAIR],
          [1.6, 1000, POOR],
        ],
      },
    },

    Urban: {
      // Mobility Index,
      Mobility_I: [
        [-1000, 0.71, GOOD],
        [0.71, 0.89, FAIR],
        [0.89, 1000, POOR],
      ],
      // Existing Mainlane Peak Hour V/C
      Peak_VC_1: [
        [-1000, 0.71, GOOD],
        [0.71, 0.89, FAIR],
        [0.89, 1000, POOR],
      ],
      Peak_VC_2: [
        [-1000, 0.71, GOOD],
        [0.71, 0.89, FAIR],
        [0.89, 1000, POOR],
      ],
      // Mainlane Future V/C
      Future_VC: [
        [-1000, 0.71, GOOD],
        [0.71, 0.89, FAIR],
        [0.89, 1000, POOR],
      ],
      // Frontage Road V/C
      FrontVC_21: [
        [-1000, 0.71, GOOD],
        [0.71, 0.89, FAIR],
        [0.89, 1000, POOR],
      ],
      FrontVC_41: [
        [-1000, 0.71, GOOD],
        [0.71, 0.89, FAIR],
        [0.89, 1000, POOR],
      ],
      //  Interchange V/C
      Inte_VC_21: [
        [-1000, 0.71, GOOD],
        [0.71, 0.89, FAIR],
        [0.89, 1000, POOR],
      ],
      Inte_VC_41: [
        [-1000, 0.71, GOOD],
        [0.71, 0.89, FAIR],
        [0.89, 1000, POOR],
      ],
      // Directional Travel Time Index
      TTI_all_B1: {
        I: [
          [-1000, 1.5, GOOD],
          [1.5, 2.5, FAIR],
          [2.5, 1000, POOR],
        ],
        UI: [
          [-1000, 1.1, GOOD],
          [1.1, 1.4, FAIR],
          [1.4, 1000, POOR],
        ],
      },
      TTI_all_B2: {
        I: [
          [-1000, 1.5, GOOD],
          [1.5, 2.5, FAIR],
          [2.5, 1000, POOR],
        ],
        UI: [
          [-1000, 1.1, GOOD],
          [1.1, 1.4, FAIR],
          [1.4, 1000, POOR],
        ],
      },
      // Directional Planning Time Index
      PTI_all_B1: {
        I: [
          [-1000, 1.55, GOOD],
          [1.55, 3.4, FAIR],
          [3.4, 1000, POOR],
        ],
        UI: [
          [-1000, 1.25, GOOD],
          [1.25, 2.5, FAIR],
          [2.5, 1000, POOR],
        ],
      },
      PTI_all_B2: {
        I: [
          [-1000, 1.55, GOOD],
          [1.55, 3.4, FAIR],
          [3.4, 1000, POOR],
        ],
        UI: [
          [-1000, 1.25, GOOD],
          [1.25, 2.5, FAIR],
          [2.5, 1000, POOR],
        ],
      },
    },
  },

  // ---- Freight Performance ---- //
  Freight: {
    Rural: {
      // Freight Index
      Freight_I: {
        I: [
          [-1000, 0.04, GOOD],
          [0.04, 0.88, FAIR],
          [0.88, 1000, POOR],
        ],
        UI: [
          [-1000, 0.1, GOOD],
          [0.1, 0.52, FAIR],
          [0.52, 1000, POOR],
        ],
      },
      // Directional Travel Time Index (trucks only)
      TTI_trk_B1: {
        I: [
          [-1000, 1.2, GOOD],
          [1.2, 2.0, FAIR],
          [2.0, 1000, POOR],
        ],
        UI: [
          [-1000, 1.05, GOOD],
          [1.05, 1.25, FAIR],
          [1.25, 1000, POOR],
        ],
      },
      TTI_trk_B2: {
        I: [
          [-1000, 1.2, GOOD],
          [1.2, 2.0, FAIR],
          [2.0, 1000, POOR],
        ],
        UI: [
          [-1000, 1.05, GOOD],
          [1.05, 1.25, FAIR],
          [1.25, 1000, POOR],
        ],
      },
      // Directional Planning Time Index (trucks only)
      PTI_trk_B1: {
        I: [
          [-1000, 1.25, GOOD],
          [1.25, 2.25, FAIR],
          [2.25, 1000, POOR],
        ],
        UI: [
          [-1000, 1.15, GOOD],
          [1.15, 1.6, FAIR],
          [1.6, 1000, POOR],
        ],
      },
      PTI_trk_B2: {
        I: [
          [-1000, 1.25, GOOD],
          [1.25, 2.25, FAIR],
          [2.25, 1000, POOR],
        ],
        UI: [
          [-1000, 1.15, GOOD],
          [1.15, 1.6, FAIR],
          [1.6, 1000, POOR],
        ],
      },
    },

    Urban: {
      // Freight Index
      Freight_I: {
        I: [
          [-1000, 0.03, GOOD],
          [0.03, 1.27, FAIR],
          [1.27, 1000, POOR],
        ],
        UI: [
          [-1000, 0.14, GOOD],
          [0.14, 1.27, FAIR],
          [1.27, 1000, POOR],
        ],
      },
      // Directional Travel Time Index (trucks only)
      TTI_trk_B1: {
        I: [
          [-1000, 1.5, GOOD],
          [1.5, 2.5, FAIR],
          [2.5, 1000, POOR],
        ],
        UI: [
          [-1000, 1.1, GOOD],
          [1.1, 1.4, FAIR],
          [1.4, 1000, POOR],
        ],
      },
      TTI_trk_B2: {
        I: [
          [-1000, 1.5, GOOD],
          [1.5, 2.5, FAIR],
          [2.5, 1000, POOR],
        ],
        UI: [
          [-1000, 1.1, GOOD],
          [1.1, 1.4, FAIR],
          [1.4, 1000, POOR],
        ],
      },
      // Directional Planning Time Index (trucks only)
      PTI_trk_B1: {
        I: [
          [-1000, 1.55, GOOD],
          [1.55, 3.4, FAIR],
          [3.4, 1000, POOR],
        ],
        UI: [
          [-1000, 1.25, GOOD],
          [1.25, 2.5, FAIR],
          [2.5, 1000, POOR],
        ],
      },
      PTI_trk_B2: {
        I: [
          [-1000, 1.55, GOOD],
          [1.55, 3.4, FAIR],
          [3.4, 1000, POOR],
        ],
        UI: [
          [-1000, 1.25, GOOD],
          [1.25, 2.5, FAIR],
          [2.5, 1000, POOR],
        ],
      },
    },

    // Bridge Vertical Clearance
    Bridge_Ver: [
      [16.5, 1000, GOOD],
      [16.0, 16.5, FAIR],
      [-1000, 16.0, POOR],
    ],

    // Bridge Load Rating
    Bridge_Loa: [
      [16.5, 1000, GOOD],
      [16.0, 16.5, FAIR],
      [-1000, 16.0, POOR],
    ],
  },

  // ---- Safety Performance ---- //
  Safety: {
    Rural: {
      // Safety Index
      Safety_I: {
        IH: [
          [-1000, 2.65, GOOD],
          [2.65, 3.98, FAIR],
          [3.98, 1000, POOR],
        ],
        US: [
          [-1000, 5.48, GOOD],
          [5.48, 8.21, FAIR],
          [8.21, 1000, POOR],
        ],
        SH: [
          [-1000, 7.11, GOOD],
          [7.11, 10.66, FAIR],
          [10.66, 1000, POOR],
        ],
        FM: [
          [-1000, 9.78, GOOD],
          [9.78, 14.8, FAIR],
          [14.8, 1000, POOR],
        ],
        RM: [
          [-1000, 9.78, GOOD],
          [9.78, 14.8, FAIR],
          [14.8, 1000, POOR],
        ],
      },
      // Directional Mainlane Rates
      ML_Rate_B1: {
        IH: [
          [-1000, 49.69, GOOD],
          [49.69, 74.53, FAIR],
          [74.53, 1000, POOR],
        ],
        US: [
          [-1000, 67.95, GOOD],
          [67.95, 101.92, FAIR],
          [101.92, 1000, POOR],
        ],
        SH: [
          [-1000, 86.76, GOOD],
          [86.76, 130.13, FAIR],
          [130.13, 1000, POOR],
        ],
        FM: [
          [-1000, 117.88, GOOD],
          [117.88, 176.82, FAIR],
          [176.82, 1000, POOR],
        ],
        RM: [
          [-1000, 117.88, GOOD],
          [117.88, 176.82, FAIR],
          [176.82, 1000, POOR],
        ],
      },
      ML_Rate_B2: {
        IH: [
          [-1000, 49.69, GOOD],
          [49.69, 74.53, FAIR],
          [74.53, 1000, POOR],
        ],
        US: [
          [-1000, 67.95, GOOD],
          [67.95, 101.92, FAIR],
          [101.92, 1000, POOR],
        ],
        SH: [
          [-1000, 86.76, GOOD],
          [86.76, 130.13, FAIR],
          [130.13, 1000, POOR],
        ],
        FM: [
          [-1000, 117.88, GOOD],
          [117.88, 176.82, FAIR],
          [176.82, 1000, POOR],
        ],
        RM: [
          [-1000, 117.88, GOOD],
          [117.88, 176.82, FAIR],
          [176.82, 1000, POOR],
        ],
      },
      // Frontage Road Rate
      Front_Rate: [
        [-1000, 141.18, GOOD],
        [141.18, 211.77, FAIR],
        [211.77, 1000, POOR],
      ],
    },
    Suburban: {
      // Safety Index
      Safety_I: {
        IH: [
          [-1000, 3.27, GOOD],
          [3.27, 4.9, FAIR],
          [4.9, 1000, POOR],
        ],
        US: [
          [-1000, 5.94, GOOD],
          [5.94, 8.92, FAIR],
          [8.92, 1000, POOR],
        ],
        SH: [
          [-1000, 9.12, GOOD],
          [9.12, 13.69, FAIR],
          [13.69, 1000, POOR],
        ],
        FM: [
          [-1000, 8.34, GOOD],
          [8.34, 12.51, FAIR],
          [12.51, 1000, POOR],
        ],
        RM: [
          [-1000, 8.34, GOOD],
          [8.34, 12.51, FAIR],
          [12.51, 1000, POOR],
        ],
      },
      // Directional Mainlane Rates
      ML_Rate_B1: {
        IH: [
          [-1000, 76.21, GOOD],
          [76.21, 114.32, FAIR],
          [114.32, 1000, POOR],
        ],
        US: [
          [-1000, 177.83, GOOD],
          [177.83, 266.75, FAIR],
          [266.75, 1000, POOR],
        ],
        SH: [
          [-1000, 265.67, GOOD],
          [265.67, 398.51, FAIR],
          [398.51, 1000, POOR],
        ],
        FM: [
          [-1000, 216.41, GOOD],
          [216.41, 324.61, FAIR],
          [324.61, 1000, POOR],
        ],
        RM: [
          [-1000, 216.41, GOOD],
          [216.41, 324.61, FAIR],
          [324.61, 1000, POOR],
        ],
      },
      ML_Rate_B2: {
        IH: [
          [-1000, 76.21, GOOD],
          [76.21, 114.32, FAIR],
          [114.32, 1000, POOR],
        ],
        US: [
          [-1000, 177.83, GOOD],
          [177.83, 266.75, FAIR],
          [266.75, 1000, POOR],
        ],
        SH: [
          [-1000, 265.67, GOOD],
          [265.67, 398.51, FAIR],
          [398.51, 1000, POOR],
        ],
        FM: [
          [-1000, 216.41, GOOD],
          [216.41, 324.61, FAIR],
          [324.61, 1000, POOR],
        ],
        RM: [
          [-1000, 216.41, GOOD],
          [216.41, 324.61, FAIR],
          [324.61, 1000, POOR],
        ],
      },
      // Frontage Road Rate
      Front_Rate: [
        [-1000, 233.97, GOOD],
        [233.97, 350.95, FAIR],
        [350.95, 1000, POOR],
      ],
    },
    Urban: {
      // Safety Index
      Safety_I: {
        IH: [
          [-1000, 2.56, GOOD],
          [2.56, 3.84, FAIR],
          [3.84, 1000, POOR],
        ],
        US: [
          [-1000, 4, 52, GOOD],
          [4.52, 6.78, FAIR],
          [6.78, 1000, POOR],
        ],
        SH: [
          [-1000, 5.08, GOOD],
          [5.08, 7.62, FAIR],
          [7.62, 1000, POOR],
        ],
        FM: [
          [-1000, 7.6, GOOD],
          [7.6, 11.39, FAIR],
          [11.39, 1000, POOR],
        ],
        RM: [
          [-1000, 7.6, GOOD],
          [7.6, 11.39, FAIR],
          [11.39, 1000, POOR],
        ],
      },
      // Directional Mainlane Rates
      ML_Rate_B1: {
        IH: [
          [-1000, 102.99, GOOD],
          [102.99, 154.49, FAIR],
          [154.49, 1000, POOR],
        ],
        US: [
          [-1000, 152.79, GOOD],
          [152.79, 229.18, FAIR],
          [229.18, 1000, POOR],
        ],
        SH: [
          [-1000, 164.74, GOOD],
          [164.74, 247.1, FAIR],
          [274.1, 1000, POOR],
        ],
        FM: [
          [-1000, 256.41, GOOD],
          [256.41, 384.61, FAIR],
          [384.61, 1000, POOR],
        ],
        RM: [
          [-1000, 256.41, GOOD],
          [256.41, 384.61, FAIR],
          [384.61, 1000, POOR],
        ],
      },
      ML_Rate_B2: {
        IH: [
          [-1000, 102.99, GOOD],
          [102.99, 154.49, FAIR],
          [154.49, 1000, POOR],
        ],
        US: [
          [-1000, 152.79, GOOD],
          [152.79, 229.18, FAIR],
          [229.18, 1000, POOR],
        ],
        SH: [
          [-1000, 164.74, GOOD],
          [164.74, 247.1, FAIR],
          [274.1, 1000, POOR],
        ],
        FM: [
          [-1000, 256.41, GOOD],
          [256.41, 384.61, FAIR],
          [384.61, 1000, POOR],
        ],
        RM: [
          [-1000, 256.41, GOOD],
          [256.41, 384.61, FAIR],
          [384.61, 1000, POOR],
        ],
      },
      // Frontage Road Rate
      Front_Rate: [
        [-1000, 254.73, GOOD],
        [254.73, 382.1, FAIR],
        [382.1, 1000, POOR],
      ],
    },
    // Total Crashes
    NoTC: [[-1000, 1000, NEUTRAL]],
    NoIC_NoFC: [[-1000, 1000, NEUTRAL]],
  },
};

export const getIndexColorCode = (value, selectedIndex, switchValue, segmentData) => {
  if (switchValue === 'Need') {
    const rank = () => {
      if (value >= 2.5 || value === 'High') return 1;
      if (value >= 1.5 || value === 'Medium') return 3;
      if (value >= 0.5 || value === 'Low') return 4;
      return 5;
    };
    return getTierColorCodes(rank(), value);
  }

  let code = NEUTRAL;
  const formattedValue = numberFormatter(value);
  const Facility_T = segmentData?.attributes?.Facility_T;
  const I_U = segmentData?.attributes?.I_U;
  const HWY = segmentData?.attributes?.HWY?.slice(0, 2);
  switch (selectedIndex) {
    case 'Pavement':
      code = getColorCode(formattedValue, colorCodeMapper['Pavement']['Pavement_I']);
      break;
    case 'Bridge':
      code = getColorCode(formattedValue, colorCodeMapper['Bridge']['Bridge_I']);
      break;
    case 'Mobility':
      code = getColorCode(formattedValue, colorCodeMapper['Mobility'][Facility_T]?.Mobility_I);
      break;
    case 'Safety':
      code = getColorCode(formattedValue, colorCodeMapper['Safety'][Facility_T]?.Safety_I[HWY]);
      break;
    case 'Freight':
      code = getColorCode(formattedValue, colorCodeMapper['Freight'][Facility_T]?.Freight_I[I_U]);
      break;
    default:
      code = NEUTRAL;
  }

  return {
    value: formattedValue,
    backgroundColor: code,
    color: '#000000',
  };
};

export const getTierColorCodes = (tier, value) => {
  const formattedValue = numberFormatter(value);
  const outputValue = isNaN(value) ? value : formattedValue;

  switch (tier) {
    case 1:
      return {
        backgroundColor: '#25306E',
        color: '#FFFFFF',
        value: outputValue,
        valueWord: 'High',
      };
    case 2:
      return {
        backgroundColor: '#0186A9',
        color: '#FFFFFF',
        value: outputValue,
        valueWord: 'None',
      };
    case 3:
      return {
        backgroundColor: '#81B4E1',
        color: '#000000',
        value: outputValue,
        valueWord: 'Medium',
      };
    case 4:
      return {
        backgroundColor: '#D9F1FD',
        color: '#000000',
        value: outputValue,
        valueWord: 'Low',
      };
    case 5:
      return {
        backgroundColor: '',
        color: '#000000',
        value: outputValue,
        valueWord: 'None',
      };
    default:
      return {
        backgroundColor: '#707070',
        color: '#000000',
        value: outputValue,
        valueWord: 'None',
      };
  }
};
