export const MAP_API_KEY =
  'AAPKeb28cfbec1f14f1cb4be69d112e05144j88PtflG97gYy764VKLnY7yN6cMAjP5tTiqlC84vmi_nR-oVfq0g17HQJ42EIz5w';
export const CORRIDOR_FEATURE_SERVICE_URL_HOUSTON =
  // "https://services7.arcgis.com/wGTEYtsrVhoPXek1/ArcGIS/rest/services/Corridor_Assessment_District_Segments/FeatureServer/4";
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/ArcGIS/rest/services/Corridor_Assessment_District_Segments_202403/FeatureServer/1';
export const CORRIDOR_FEATURE_SERVICE_URL =
  // "https://services7.arcgis.com/wGTEYtsrVhoPXek1/arcgis/rest/services/CPT_Statewide/FeatureServer/0";
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/ArcGIS/rest/services/Corridor_Assessment_Statewide_Segments_20240506/FeatureServer/2';
export const CORRIDOR_CREATE_NEW_MARKER_URL =
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/ArcGIS/rest/services/Corridor_Assessment_Create_Markers_202404/FeatureServer/0';
export const CORRIDOR_CREATE_NEW_SEGMENT_URL =
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/ArcGIS/rest/services/Corridor_Assessment_Create_Segments_20240419/FeatureServer/8';
export const SEGMENT_EVALUTION_SERVICE_URL =
  // "https://services7.arcgis.com/wGTEYtsrVhoPXek1/arcgis/rest/services/CET_Statewide_Segment/FeatureServer";
  // "https://services7.arcgis.com/wGTEYtsrVhoPXek1/arcgis/rest/services/CET_Statewide_Segment_w_LoN/FeatureServer/0";
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/arcgis/rest/services/Segment_Evaluation_Statewide_Segments_202404/FeatureServer';
export const SEGMENT_EVALUTION_KEY_MARKERS_SERVICE_URL =
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/arcgis/rest/services/Segment_Evaluation_Statewide_Markers_202404/FeatureServer';
export const DISTRICT_FEATURE_SERVICE_URL =
  'https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Districts/FeatureServer/0';
export const COUNTY_FEATURE_SERVICE_URL =
  'https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/Texas_County_Boundaries_Line/FeatureServer/0';
export const URBANIZED_AREA_FEATURE_SERVICE_URL =
  'https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Urbanized_Areas/FeatureServer/0';
export const MPO_FEATURE_SERVICE_URL =
  'https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/Texas_Metropolitan_Planning_Organizations/FeatureServer/0';
export const CPT_METHODOLOGY_DOC_URL =
  'https://aecomarl.maps.arcgis.com/sharing/rest/content/items/4c2c92387d7747c8ac3838fdcb92e692/data';
export const CET_METHODOLOGY_DOC_URL =
  'https://aecomarl.maps.arcgis.com/sharing/rest/content/items/81dcdfe0ffa849bc95f5f9a730e7ebc6/data';
export const PERFORMANCE_TRACKING_SEGMENTS_URL =
  // "https://services7.arcgis.com/wGTEYtsrVhoPXek1/arcgis/rest/services/Performance_Tracking_Segments/FeatureServer/3";
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/ArcGIS/rest/services/Performance_Tracking_Segments_202403/FeatureServer/0';
export const PERFORMANCE_TRACKING_SEGMENTS_URL_DISTRICTS =
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/ArcGIS/rest/services/Performance_Tracking_District_Segments_20240326/FeatureServer/1';
export const FUTURE_INTERSTATES_FEATURE_SERVICE_URL =
  'https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/Future_Interstates/FeatureServer/0';
export const HURRICANCE_EVACUATION_ROUTE =
  'https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Evacuation_Routes/FeatureServer/0';
export const TEXAS_TRUNK_SYSTEM =
  'https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Texas_Trunk_System/FeatureServer/0';
export const ENERGY_SECTOR_CORRIDORS =
  'https://services.arcgis.com/KTcxiTD9dsQw4r7Z/arcgis/rest/services/TxDOT_Energy_Sector_Corridors/FeatureServer/0';

export const ROADWAY_SHIELD_ONE =
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/ArcGIS/rest/services/TX_Roadway_Labels/FeatureServer/11';
export const ROADWAY_SHIELD_TWO =
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/ArcGIS/rest/services/TX_Roadway_Labels/FeatureServer/9';
export const ROADWAY_SHIELD_THREE =
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/ArcGIS/rest/services/TX_Roadway_Labels/FeatureServer/7';
export const ROADWAY_SHIELD_FOUR =
  'https://services7.arcgis.com/wGTEYtsrVhoPXek1/ArcGIS/rest/services/TX_Roadway_Labels/FeatureServer/8';

export const DEFAULT_SPATIAL_REF = { wkid: 3857 };
export const DEFAULT_MAP_CENTRAL_POINT = [-99.13168096993665, 31.503809189893104];
export const WEIGHTS_DEFAULT_VALUES = {
  pavement: 11,
  bridge: 11,
  safety: 28,
  congestion: 22,
  economic: 11,
  connectivity: 17,
};

export const ROADWAY_CLASSIFICATIONS = [
  { label: 'IH', value: 'IH' },
  { label: 'US', value: 'US' },
  { label: 'SH', value: 'SH' },
  { label: 'FM', value: 'FM' },
  { label: 'RM', value: 'RM' },
  { label: 'OTHERS', value: 'OTHERS' },
];

export const CUSTOM_OPTIONS = [
  { label: 'No Filter', value: -1 },
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

export const DISTRICTS = [
  'Abilene',
  'Amarillo',
  'Atlanta',
  'Austin',
  'Beaumont',
  'Brownwood',
  'Bryan',
  'Childress',
  'Corpus Christi',
  'Dallas',
  'El Paso',
  'Fort Worth',
  'Houston',
  'Laredo',
  'Lubbock',
  'Lufkin',
  'Odessa',
  'Paris',
  'Pharr',
  'San Angelo',
  'San Antonio',
  'Tyler',
  'Waco',
  'Wichita Falls',
  'Yoakum',
];

export const DISTRICT_LOOKUP = [
  {
    TXDOT_DIST_ABRVN_NM: 'ABL',
    TXDOT_DIST_NBR: 8,
    TXDOT_DIST_NM: 'Abilene',
    TYPE: 'Rural',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'AMA',
    TXDOT_DIST_NBR: 4,
    TXDOT_DIST_NM: 'Amarillo',
    TYPE: 'Rural',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'ATL',
    TXDOT_DIST_NBR: 19,
    TXDOT_DIST_NM: 'Atlanta',
    TYPE: 'Rural',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'AUS',
    TXDOT_DIST_NBR: 14,
    TXDOT_DIST_NM: 'Austin',
    TYPE: 'Metro',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'BMT',
    TXDOT_DIST_NBR: 20,
    TXDOT_DIST_NM: 'Beaumont',
    TYPE: 'Urban',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'BWD',
    TXDOT_DIST_NBR: 23,
    TXDOT_DIST_NM: 'Brownwood',
    TYPE: 'Rural',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'BRY',
    TXDOT_DIST_NBR: 17,
    TXDOT_DIST_NM: 'Bryan',
    TYPE: 'Urban',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'CHS',
    TXDOT_DIST_NBR: 25,
    TXDOT_DIST_NM: 'Childress',
    TYPE: 'Rural',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'CRP',
    TXDOT_DIST_NBR: 16,
    TXDOT_DIST_NM: 'Corpus Christi',
    TYPE: 'Urban',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'DAL',
    TXDOT_DIST_NBR: 18,
    TXDOT_DIST_NM: 'Dallas',
    TYPE: 'Metro',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'ELP',
    TXDOT_DIST_NBR: 24,
    TXDOT_DIST_NM: 'El Paso',
    TYPE: 'Urban',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'FTW',
    TXDOT_DIST_NBR: 2,
    TXDOT_DIST_NM: 'Fort Worth',
    TYPE: 'Metro',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'HOU',
    TXDOT_DIST_NBR: 12,
    TXDOT_DIST_NM: 'Houston',
    TYPE: 'Metro',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'LRD',
    TXDOT_DIST_NBR: 22,
    TXDOT_DIST_NM: 'Laredo',
    TYPE: 'Urban',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'LBB',
    TXDOT_DIST_NBR: 5,
    TXDOT_DIST_NM: 'Lubbock',
    TYPE: 'Urban',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'LFK',
    TXDOT_DIST_NBR: 11,
    TXDOT_DIST_NM: 'Lufkin',
    TYPE: 'Rural',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'ODA',
    TXDOT_DIST_NBR: 6,
    TXDOT_DIST_NM: 'Odessa',
    TYPE: 'Urban',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'PAR',
    TXDOT_DIST_NBR: 1,
    TXDOT_DIST_NM: 'Paris',
    TYPE: 'Urban',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'PHR',
    TXDOT_DIST_NBR: 21,
    TXDOT_DIST_NM: 'Pharr',
    TYPE: 'Metro',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'SJT',
    TXDOT_DIST_NBR: 7,
    TXDOT_DIST_NM: 'San Angelo',
    TYPE: 'Rural',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'SAT',
    TXDOT_DIST_NBR: 15,
    TXDOT_DIST_NM: 'San Antonio',
    TYPE: 'Metro',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'TYL',
    TXDOT_DIST_NBR: 10,
    TXDOT_DIST_NM: 'Tyler',
    TYPE: 'Urban',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'WAC',
    TXDOT_DIST_NBR: 9,
    TXDOT_DIST_NM: 'Waco',
    TYPE: 'Urban',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'WFS',
    TXDOT_DIST_NBR: 3,
    TXDOT_DIST_NM: 'Wichita Falls',
    TYPE: 'Rural',
  },
  {
    TXDOT_DIST_ABRVN_NM: 'YKM',
    TXDOT_DIST_NBR: 13,
    TXDOT_DIST_NM: 'Yoakum',
    TYPE: 'Rural',
  },
];

export const MPOs = [
  'Non-MPO',
  'Abilene',
  'Alamo Area',
  'Amarillo',
  'Brownsville',
  'Bryan-College Station',
  'Capital Area',
  'Corpus Christi',
  'El Paso',
  'Harlingen-San Benito',
  'Hidalgo County',
  'Houston-Galveston Area Council',
  'Killeen-Temple',
  'Laredo Urban Transportation Study',
  'Longview',
  'Lubbock',
  'North Central Texas',
  'Permian Basin',
  'San Angelo',
  'Sherman-Denison',
  'South East Texas',
  'Texarkana',
  'Tyler Area',
  'Victoria',
  'Waco',
  'Wichita Falls',
];

export const MPO_LOOKUP = [
  {
    MPO_NBR: 15,
    MPO_NM: 'Houston-Galveston Area Council',
    MPO_LBL: 'Houston-Galveston Area Council MPO',
  },
  {
    MPO_NBR: 28,
    MPO_NM: 'Alamo Area',
    MPO_LBL: 'Alamo Area MPO',
  },
  {
    MPO_NBR: 66,
    MPO_NM: 'El Paso',
    MPO_LBL: 'El Paso MPO',
  },
  {
    MPO_NBR: 90,
    MPO_NM: 'Capital Area',
    MPO_LBL: 'CAMPO',
  },
  {
    MPO_NBR: 96,
    MPO_NM: 'Corpus Christi',
    MPO_LBL: 'Corpus Christi MPO',
  },
  {
    MPO_NBR: 120,
    MPO_NM: 'Amarillo',
    MPO_LBL: 'Amarillo MPO',
  },
  {
    MPO_NBR: 122,
    MPO_NM: 'Lubbock',
    MPO_LBL: 'Lubbock MPO',
  },
  {
    MPO_NBR: 135,
    MPO_NM: 'South East Texas Regional Planning Commission',
    MPO_LBL: 'South East Texas RPC MPO',
  },
  {
    MPO_NBR: 140,
    MPO_NM: 'Waco',
    MPO_LBL: 'Waco MPO',
  },
  {
    MPO_NBR: 151,
    MPO_NM: 'Wichita Falls',
    MPO_LBL: 'Wichita Falls MPO',
  },
  {
    MPO_NBR: 166,
    MPO_NM: 'Abilene',
    MPO_LBL: 'Abilene MPO',
  },
  {
    MPO_NBR: 174,
    MPO_NM: 'Permian Basin',
    MPO_LBL: 'Permian Basin MPO',
  },
  {
    MPO_NBR: 205,
    MPO_NM: 'Laredo Webb County Area',
    MPO_LBL: 'Laredo Webb County Area MPO',
  },
  {
    MPO_NBR: 208,
    MPO_NM: 'San Angelo',
    MPO_LBL: 'San Angelo MPO',
  },
  {
    MPO_NBR: 211,
    MPO_NM: 'Texarkana',
    MPO_LBL: 'Texarkana MPO',
  },
  {
    MPO_NBR: 213,
    MPO_NM: 'Tyler',
    MPO_LBL: 'Tyler MPO',
  },
  {
    MPO_NBR: 230,
    MPO_NM: 'Rio Grande Valley',
    MPO_LBL: 'Rio Grande Valley MPO',
  },
  {
    MPO_NBR: 232,
    MPO_NM: 'Grayson County',
    MPO_LBL: 'Grayson County MPO',
  },
  {
    MPO_NBR: 249,
    MPO_NM: 'Bryan-College Station',
    MPO_LBL: 'Bryan-College Station MPO',
  },
  {
    MPO_NBR: 277,
    MPO_NM: 'Killeen-Temple',
    MPO_LBL: 'Killeen-Temple MPO',
  },
  {
    MPO_NBR: 282,
    MPO_NM: 'North Central Texas Council of Governments',
    MPO_LBL: 'North Central Texas COG',
  },
  {
    MPO_NBR: 361,
    MPO_NM: 'Longview',
    MPO_LBL: 'Longview MPO',
  },
  {
    MPO_NBR: 363,
    MPO_NM: 'Victoria',
    MPO_LBL: 'Victoria MPO',
  },
];

export const mode = ['Statewide', 'District', 'MPO'];

export const RENDERER_HIGHLIGHT = {
  type: 'simple',
  symbol: {
    type: 'simple-line',
    width: 5,
    color: '#E09231',
  },
};

export const RENDERER_BACKGROUND = {
  type: 'simple',
  symbol: {
    type: 'simple-line',
    width: 1.5,
    color: '#d3d3d3',
  },
};

export const DROPDOWN_LIST = [
  {
    label: 'Overall',
    value: 'Overall',
    img_url: '/assets/svg/Icon_Overall.svg',
  },
  {
    label: 'Pavement',
    value: 'Pavement',
    img_url: '/assets/svg/Pavement.svg',
  },
  {
    label: 'Bridge',
    value: 'Bridge',
    img_url: '/assets/svg/Bridge.svg',
  },
  {
    label: 'Mobility',
    value: 'Mobility',
    img_url: '/assets/svg/Congestion.svg',
  },
  {
    label: 'Safety',
    value: 'Safety',
    img_url: '/assets/svg/Safety.svg',
  },
  {
    label: 'Freight',
    value: 'Freight',
    img_url: '/assets/svg/Economic.svg',
  },
];

export const RENDERER_HIGHLIGHT_MARKER = {
  type: 'simple',
  symbol: {
    type: 'simple-marker',
    style: 'circle',
    color: '#14375A',
    size: '7px',
    outline: {
      color: '#14375A',
      width: 1,
    },
  },
};
