import { useContext, useState } from 'react';
import './ModeSelector.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { DISTRICTS, MPOs } from '../../Utils/Constants';
import { localStorageGetWithExpiry } from '../../Utils/LocalStorage';
import { GlobalContext } from '../../Context/GlobalContext';
import { useSegmentEvalutionStore } from '../../store/segment-evaluation/store';

const ModeSelector = () => {
  const {
    selectedMode,
    setSelectedMode,
    selectedDistrict,
    setSelectedDistrict,
    selectedMPO,
    setSelectedMPO,
    setSelectedCorridorId,
  } = useSegmentEvalutionStore(state => state);
  const { setDistrictFilterSW, onReset } = useContext(GlobalContext);
  const [showMode, setShowMode] = useState(false);
  const [showDistrictMode, setShowDistrictMode] = useState(false);
  const [showMPOMode, setShowMPOMode] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleShowMode = () => setShowMode(true);
  const handleCloseMode = () => setShowMode(false);

  const handleShowDistrictMode = () => setShowDistrictMode(true);
  const handleCloseDistrictMode = () => setShowDistrictMode(false);

  const handleShowMPOMode = () => setShowMPOMode(true);
  const handleCloseMPOMode = () => setShowMPOMode(false);

  const runAssessmentRedirectLogic = async () => {
    const AssessmentPaths = ['/performance-tracking', '/map-view', '/table-view', '/view-trend'];
    localStorage.removeItem('selectedSegments');
    localStorage.removeItem('selectViewData');
    localStorage.removeItem('selectViewFilters');
    if (AssessmentPaths.includes(location.pathname)) {
      navigate('/select-corridors');
    }
  };

  const handleDistrictClick = async district => {
    await setSelectedMode('District');
    await setSelectedDistrict(district);
    await setSelectedMPO('');
    await setDistrictFilterSW(district);
    handleCloseMode();
    handleCloseDistrictMode();
    onReset('District', district, '');
    setSelectedCorridorId('');
    runAssessmentRedirectLogic();
  };

  const handleMPOClick = MPO => {
    setSelectedMode('MPO');
    setSelectedMPO(MPO);
    setSelectedDistrict('');
    setDistrictFilterSW(null);
    handleCloseMode();
    handleCloseMPOMode();
    setSelectedCorridorId('');
    runAssessmentRedirectLogic();
  };

  const handleShowStateWideMode = () => {
    setSelectedMode('Statewide');
    setSelectedDistrict('');
    setSelectedMPO('');
    setDistrictFilterSW(null);
    handleCloseMode();
    handleCloseDistrictMode();
    onReset('Statewide', '', '');
    setSelectedCorridorId('');
    runAssessmentRedirectLogic();
  };

  const getDistrictsOptions = () => {
    const groupStrings = (array, groupSize) => {
      const groupedArrays = [];
      for (let i = 0; i < array.length; i += groupSize) {
        const group = array.slice(i, i + groupSize);
        groupedArrays.push(group);
      }
      return groupedArrays;
    };
    const groupedDistricts = groupStrings(DISTRICTS, 7);

    return (
      <>
        {groupedDistricts.map((districtsGroup, columnIndex) => (
          <div key={columnIndex} className="col-3">
            {districtsGroup.map((district, rowIndex) => (
              <ul key={rowIndex} className="list-group">
                <li onClick={() => handleDistrictClick(district)}>{district}</li>
              </ul>
            ))}
          </div>
        ))}
      </>
    );
  };

  const getMPOOptions = () => {
    const groupStrings = (array, groupSize) => {
      const groupedArrays = [];
      for (let i = 0; i < array.length; i += groupSize) {
        const group = array.slice(i, i + groupSize);
        groupedArrays.push(group);
      }
      return groupedArrays;
    };
    const groupedMPOs = groupStrings(MPOs, 13);

    return (
      <>
        {groupedMPOs.map((mpoGroup, columnIndex) => (
          <div key={columnIndex} className="col-6">
            {mpoGroup.map((mpo, rowIndex) => (
              <ul key={rowIndex} className="list-group">
                <li onClick={() => handleMPOClick(mpo)}>{mpo}</li>
              </ul>
            ))}
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {/* Trigger of main mode selector modal */}
      <div className="mode-selector" onClick={handleShowMode}>
        <span className="mx-2">
          {selectedMode}
          {selectedDistrict ? `: ${selectedDistrict}` : ''}
          {selectedMPO ? `: ${selectedMPO}` : ''}
        </span>
        <i className="fa-solid fa-sliders"></i>
      </div>

      {/* Mode selector modal */}
      <div className={`modal fade selector-modal ${showMode ? 'show' : ''}`}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="mode-selector-modal-label">
                Select a mode to begin your Corridor Assessment.
              </h4>
              <button type="button" className="btn-close" onClick={handleCloseMode}></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <div
                    className="mode-card"
                    onClick={() => {
                      handleCloseMode();
                      handleShowStateWideMode();
                    }}
                  >
                    <img className="card-img-top" src="assets/svg/icon_statewide.svg" alt="Statewide" />
                    <h4>Statewide</h4>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="mode-card"
                    onClick={() => {
                      handleCloseMode();
                      handleShowDistrictMode();
                    }}
                  >
                    <img className="card-img-top" src="assets/svg/icon_district.svg" alt="District" />
                    <h4>District</h4>
                  </div>
                </div>
                <div className="col">
                  <div
                    style={{
                      opacity: 0.2,
                      pointerEvents: 'none',
                    }}
                    className="mode-card"
                    onClick={() => {
                      handleCloseMode();
                      handleShowMPOMode();
                    }}
                  >
                    <img className="card-img-top" src="assets/svg/icon_mpo.svg" alt="Urban/Rural" />
                    <h4>Urban/Rural</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* District Mode selector modal */}
      <div className={`modal fade selector-modal ${showDistrictMode ? 'show' : ''}`}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="district-selector-modal-label">
                Select a district.
              </h4>
              <button type="button" className="btn-close" onClick={handleCloseDistrictMode}></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-3">
                  <div className="mode-card">
                    <img className="card-img-top" src="assets/svg/Pavement.svg" alt="Pavement" />
                    <h4>District</h4>
                  </div>
                </div>
                <div className="col-9">
                  <div className="row">{getDistrictsOptions()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MPO Mode selector modal */}
      <div className={`modal fade selector-modal ${showMPOMode ? 'show' : ''}`}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="district-selector-modal-label">
                Select an option.
              </h4>
              <button type="button" className="btn-close" onClick={handleCloseMPOMode}></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-3">
                  <div className="mode-card">
                    <img className="card-img-top" src="assets/svg/Pavement.svg" alt="Pavement" />
                    <h4>Urban/Rural</h4>
                  </div>
                </div>
                <div className="col-9">
                  <div className="row">{getMPOOptions()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModeSelector;
