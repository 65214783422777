import Graphic from '@arcgis/core/Graphic';
import { Polyline } from '@arcgis/core/geometry';
import { SimpleLineSymbol } from '@arcgis/core/symbols';
import PopupTemplate from '@arcgis/core/PopupTemplate';

import { RENDERER_HIGHLIGHT, WEIGHTS_DEFAULT_VALUES, ROADWAY_CLASSIFICATIONS } from './Constants';
import { getCutOffs } from './CorridorRankingHelper';
import { localStorageGetWithExpiry } from './LocalStorage';
import { getOverallScore, getCorridorTierColors } from './CorridorRankingHelper';

export const renderer_overallscore = (
  PavementWeight = 0.11,
  BridgeWeight = 0.11,
  CongestionWeight = 0.22,
  SafetyWeight = 0.28,
  ConnectivityWeight = 0.17,
  EconomicWeight = 0.11,

  tier1Cutoff = 100,
  tier2Cutoff = 80,
  tier3Cutoff = 65,
  tier4Cutoff = 50,
  tier5Cutoff = 35,
) => {
  return {
    type: 'simple',
    symbol: { type: 'simple-line', width: 3 },
    // label: "scores",
    visualVariables: [
      {
        type: 'color',
        valueExpression:
          '$feature.Pavement_Score *10*' +
          PavementWeight +
          ' + $feature.Bridge_Score *10*' +
          BridgeWeight +
          '+ $feature.Congestion_Score*10*' +
          CongestionWeight +
          '+ $feature.Safety_Score*10*' +
          SafetyWeight +
          '+ $feature.Economic_Score*10*' +
          EconomicWeight +
          '+ $feature.Connectivity_Score *10*' +
          ConnectivityWeight,
        stops: [
          {
            value: tier5Cutoff,
            color: '#707070',
            label: 'Below 35th Percentile',
            width: 1,
          },
          {
            value: tier4Cutoff,
            color: '#D9F1FD',
            label: '35th-50th Percentile',
            width: 1.75,
          },
          {
            value: tier3Cutoff,
            color: '#81B4E1',
            label: '50th-65th Percentile',
            width: 2.5,
          },
          {
            value: tier2Cutoff,
            color: '#0186A9',
            label: '65th-80th Percentile',
            width: 3,
          },
          {
            value: tier1Cutoff,
            color: '#25306E',
            label: 'Above 80th Percentile',
            width: 4,
          },
        ],
      },
      {
        type: 'size',
        valueExpression:
          '$feature.Pavement_Score *20*' +
          PavementWeight +
          ' + $feature.Bridge_Score *20*' +
          BridgeWeight +
          '+ $feature.Congestion_Score*20*' +
          CongestionWeight +
          '+ $feature.Safety_Score*20*' +
          SafetyWeight +
          '+ $feature.Economic_Score*20*' +
          EconomicWeight +
          '+ $feature.Connectivity_Score *20*' +
          ConnectivityWeight,
        stops: [
          {
            value: tier5Cutoff,
            size: 1.5,
          },
          {
            value: tier4Cutoff,
            size: 2,
          },
          {
            value: tier3Cutoff,
            size: 2.5,
          },
          {
            value: tier2Cutoff,
            size: 3,
          },
          {
            value: tier1Cutoff,
            size: 4,
          },
        ],
      },
    ],
  };
};

export const rendererHighlight = {
  type: 'simple',
  symbol: {
    type: 'simple-line',
    width: 5,
    color: '#E09231',
  },
};

export const queryStringFromSelectedSegments = async (
  selectedMode,
  selectedDistrict,
  selectedMPO,
  fromLocalStorage = true,
  segments = null,
  globalObject = [],
  isLimited = false,
) => {
  //
  // build query string based on selected segments from local storage (fromLocalStroage=true)
  //  OR
  // build query string based on segments array passed to the function (fromLocalStorage=false, segments={segmentArray})
  //
  var queryString = '';

  let selectedSegments = segments;
  if (fromLocalStorage === true) {
    selectedSegments = (await localStorageGetWithExpiry('selectedSegments')) || null;
  }

  if (isLimited) {
    selectedSegments =
      globalObject?.filter(item => selectedSegments?.includes(item?.Corridor_ID)).map(item => item.Corridor_ID) || [];
  }

  if (selectedSegments) {
    if (selectedSegments.length > 0) {
      await selectedSegments.forEach(element => (queryString += `'${element}',`));
      queryString = queryString.substring(0, queryString.length - 1);
      queryString = `ID in (${queryString})`;
    }
  } else {
    queryString = selectedMode === 'District' ? `Districts = '${selectedDistrict}'` : '';
  }

  return queryString;
};

export const definitionExpression = async (selectedMode, selectedDistrict, selectedMPO, globalObject, isLimited) => {
  // build definition Expression with selection and filter

  var query = '';
  await main();

  async function main() {
    //
    // get query from selector
    var selectorString = await queryStringFromSelectedSegments(
      selectedMode,
      selectedDistrict,
      selectedMPO,
      true,
      null,
      globalObject,
      isLimited,
    );

    //
    // get query from filter
    var filterString = '';
    var customFilters = localStorageGetWithExpiry('customFilters');

    if (customFilters) {
      var { IH, US, SH, FM, RM, BS, district, urbanRural, OTHERS } = customFilters;
      var typeFilter =
        (OTHERS
          ? `
           (HWY LIKE 'SL%') OR 
           (HWY LIKE 'SS%') OR 
           (HWY LIKE 'UA%') OR 
           (HWY LIKE 'FS%') OR 
           (HWY LIKE 'PR%') OR 
           (HWY LIKE 'BF%') OR 
           (HWY LIKE 'BU%') OR 
           (HWY LIKE 'BS%') OR`
          : '') +
        (IH ? `(HWY LIKE 'IH%') OR` : '') +
        (US ? `(HWY LIKE 'US%') OR` : '') +
        (SH ? `(HWY LIKE 'SH%') OR` : '') +
        (FM ? `(HWY LIKE 'FM%') OR` : '') +
        (RM ? `(HWY LIKE 'RM%') OR` : '') +
        (BS
          ? `(
                (HWY NOT LIKE 'IH%') AND 
                (HWY NOT LIKE 'US%') AND 
                (HWY NOT LIKE 'SH%') AND 
                (HWY NOT LIKE 'FM%') AND 
                (HWY NOT LIKE 'RM%')
                ) OR`
          : '');
      typeFilter = typeFilter.length > 0 ? typeFilter.substring(0, typeFilter.length - 2) : '';

      var districtFilter = ''; //TO BE IMPLEMENTED
      if (district === 'No Filter') {
        districtFilter = '';
      } else {
        districtFilter = `Districts LIKE '%${district}%'`;
      }

      var mpoFilter = ''; //TO BE IMPLEMENTED
      if (urbanRural === 'No Filter' || urbanRural.length === 0) {
        mpoFilter = '';
      } else if (urbanRural === 'nonMpo') {
        mpoFilter = `MPO is NULL OR MPO ='' `;
      } else {
        mpoFilter = `MPO LIKE '%${urbanRural.trim()}%'`;
      }

      if (typeFilter.length > 0) {
        typeFilter = `(${typeFilter}) AND `;
      }

      if (districtFilter.length > 0) {
        districtFilter = `(${districtFilter}) AND `;
      }

      if (mpoFilter.length > 0) {
        mpoFilter = `(${mpoFilter}) AND `;
      }

      filterString = typeFilter + districtFilter + mpoFilter;

      if (filterString.length > 0) {
        filterString = filterString.substring(0, filterString.length - 4);
      }
    }

    // build query string
    if (selectorString.length > 0 && filterString.length > 0) {
      query = `${filterString} AND ${selectorString}`;
    } else if (selectorString.length > 0) {
      query = selectorString;
    } else if (filterString.length > 0) {
      query = filterString;
    }
  }
  return query;
};

export const updateDefinitionQuery = async layer => {
  const selectedSegments = await localStorageGetWithExpiry('selectedSegments');
  layer.definitionExpression = await definitionExpression(false, selectedSegments);
};

export async function setupMapAndData(
  layer,
  mapview,
  globalObject,
  selectedMode,
  selectedDistrict,
  selectedMPO,
  isLimited = false,
) {
  try {
    // Update corridor layer in Map
    layer.definitionExpression = await definitionExpression(
      selectedMode,
      selectedDistrict,
      selectedMPO,
      globalObject,
      isLimited,
    );

    // Get custom weights
    const customWeights = localStorageGetWithExpiry('customWeights');
    const { pavement, bridge, safety, congestion, economic, connectivity } = customWeights || WEIGHTS_DEFAULT_VALUES;

    // Update color renderer
    const scoresArray = globalObject.map(go => go.Scr_Tot);
    const scoreCutOffs = getCutOffs(scoresArray);

    layer.renderer = renderer_overallscore(
      pavement / 100,
      bridge / 100,
      congestion / 100,
      safety / 100,
      connectivity / 100,
      economic / 100,
      scoreCutOffs.tier1 - 0.01,
      scoreCutOffs.tier2 - 0.01,
      scoreCutOffs.tier3 - 0.01,
      scoreCutOffs.tier4 - 0.01,
      scoreCutOffs.tier5 - 0.01,
    );

    // Zoom to state
    const fullExtent = await layer.queryExtent();
    await mapview.goTo(fullExtent?.extent?.center);
  } catch (error) {
    console.error('Error setting up the map and data:', error);
  }
}
export const resetHighlightLayer = highlightLayer => {
  highlightLayer.renderer = RENDERER_HIGHLIGHT;
  highlightLayer.definitionExpression = `ID = '*'`;
};

export const highlightCorridor = async (query, highlightLayer, mapview) => {
  highlightLayer.definitionExpression = query;

  const queryExtentResult = await highlightLayer.queryExtent();
  const { extent } = queryExtentResult;

  if (extent && mapview) {
    await mapview.goTo(extent.center);

    if (mapview.zoom >= 6) {
      mapview.zoom = 6;
    }
  }
};

export const zoomCorridor = async (highlightLayer, mapview) => {
  const fullExtent = await highlightLayer.queryExtent();

  //adjust extent to zoom out slightly
  const expansionFactor = 0.25;
  let extent = fullExtent.extent;
  if (extent) {
    const expandedBoundingBox = expandBoundingBox(extent.xmin, extent.xmax, extent.ymin, extent.ymax, expansionFactor);
    extent.xmax = expandedBoundingBox.xmax;
    extent.xmin = expandedBoundingBox.xmin;
    extent.ymax = expandedBoundingBox.ymax;
    extent.ymin = expandedBoundingBox.ymin;
  }

  const opts = { duration: 1000 };
  console.log(extent);
  await mapview.goTo(
    {
      extent: extent,
    },
    opts,
  );
};

function expandBoundingBox(xmin, xmax, ymin, ymax, expansionFactor) {
  // Calculate the expansion amount for each direction
  let xExpansion = (xmax - xmin) * expansionFactor;
  let yExpansion = (ymax - ymin) * expansionFactor;

  // Apply the expansion to each side
  let newXmin = xmin - xExpansion;
  let newXmax = xmax + xExpansion;
  let newYmin = ymin - yExpansion;
  let newYmax = ymax + yExpansion;

  console.log('xmin', 'xmax', 'ymin', 'ymax');
  console.log(xmin, xmax, ymin, ymax);
  console.log('newXmin', 'newXmax', 'newYmin', 'newYmax');
  console.log(newXmin, newXmax, newYmin, newYmax);
  // Return the new bounding box coordinates
  return {
    xmin: newXmin,
    xmax: newXmax,
    ymin: newYmin,
    ymax: newYmax,
  };
}

//function set definition query
export const updateSelectViewDefinitionQuery = async (
  selectedMode,
  selectedDistrict,
  selectedMPO,
  fromLocalStorage = false,
  segments = [],
  layer,
) => {
  var expression = await queryStringFromSelectedSegments(
    selectedMode,
    selectedDistrict,
    selectedMPO,
    fromLocalStorage,
    segments,
  );
  if (layer) {
    if (expression !== '') {
      layer.definitionExpression = expression;
    } else {
      layer.definitionExpression = `ID = '*'`;
    }
  }
};

export const convertMultiLineStringToGraphic = (feature, segment, customColor) => {
  const { coordinates } = feature.geometry;

  // Create a Polyline geometry from GeoJSON coordinates
  const polyline = new Polyline({
    paths: coordinates,
    spatialReference: { wkid: 3857 }, // Assuming WGS84 spatial reference
  });

  // Optionally, define a symbol
  const symbol = new SimpleLineSymbol({
    color: customColor ?? '#E09231',
    width: 4,
  });

  // Inside convertMultiLineStringToGraphic function
  const popupTemplate = new PopupTemplate({
    title: `{HWY} - Segment {Begin_Milepost} to {End_Milepost}`,
    content: [
      {
        type: 'fields',
        fieldInfos: [
          { fieldName: 'Corridor_ID', label: 'ID' },
          { fieldName: 'HWY', label: 'Route' },
          { fieldName: 'Begin_Milepost', label: 'Begin Marker' },
          { fieldName: 'End_Milepost', label: 'End Marke' },
          {
            fieldName: 'Scr_Tot',
            label: 'Overall Score (0-100)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Scr_1',
            label: 'Pavement Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Scr_2',
            label: 'Bridge Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Scr_3',
            label: 'Safety Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Scr_4',
            label: 'Congestion Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Scr_5',
            label: 'Economic Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Scr_6',
            label: 'Connectivity Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
        ],
      },
    ],
  });

  // Create a graphic
  return new Graphic({
    geometry: polyline,
    symbol: symbol,
    attributes: segment,
    popupTemplate: popupTemplate,
  });
};
