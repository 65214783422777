import { useState } from 'react';

import MapView from './MapView';
import CorridorCreation from './CorridorCreation';

import './CorridorCreation.css';

function CorridorCreationContainer({ setCreateNewSelected, fetchData }) {
  const [selectedCustomData, setSelectedCustomData] = useState([]);

  return (
    <>
      <div className="col-lg-6 p-0">
        <MapView selectedCustomData={selectedCustomData} />
      </div>
      <div className="col-lg-6 p-0">
        <CorridorCreation
          setCreateNewSelected={setCreateNewSelected}
          selectedCustomData={selectedCustomData}
          setSelectedCustomData={setSelectedCustomData}
          fetchData={fetchData}
        />
      </div>
    </>
  );
}

export default CorridorCreationContainer;
