import { useEffect } from 'react';

import { ROADWAY_CLASSIFICATIONS } from '../../../Utils/Constants';
import { useGlobalContext } from '../../../Context/GlobalContext';
import { useCorridorSelectionContext } from './Context/CorridorSelectionContext';
import { useSegmentEvalutionStore } from '../../../store/segment-evaluation/store';
import { localStorageGetWithExpiry } from '../../../Utils/LocalStorage';

import MapView from './MapView';
import ActionButtons from './ActionButtons';
import CorridorTreeList from './CorridorTreeList';

function CorridorSelection({ setCreateNewSelected }) {
  const { setFilters, setSelectedCorridors, setSelectedSegments, setSelectAllCorridors } =
    useCorridorSelectionContext();
  const { fetchMapData, resetGlobalObject, updateGlobalObject } = useGlobalContext();
  const { selectedMode, selectedDistrict, selectedMPO } = useSegmentEvalutionStore(state => state);

  const onReset = async () => {
    const filters = {
      search: '',
      routeTypes: ROADWAY_CLASSIFICATIONS,
      custom: 0,
    };
    setFilters(filters);
    setSelectedCorridors([]);
    setSelectedSegments([]);
    setSelectAllCorridors(false);
    localStorage.removeItem('globalObject');
    localStorage.removeItem('customWeights');
    localStorage.removeItem('customFilters');
    localStorage.removeItem('selectedSegments');
    localStorage.removeItem('selectViewData');
    localStorage.removeItem('selectedDdOptionsIds');
    localStorage.removeItem('selectViewFilters');
    resetGlobalObject();
    await updateGlobalObject(selectedMode, selectedDistrict, selectedMPO);
    await fetchMapData(selectedMode, selectedDistrict, selectedMPO);
  };

  useEffect(() => {
    const localStorageSelectViewFilters = localStorageGetWithExpiry('selectViewFilters');
    const localStorageSelectedSegments = localStorageGetWithExpiry('selectedSegments');

    if (localStorageSelectViewFilters || localStorageSelectedSegments) {
      if (localStorageSelectViewFilters) {
        let routeTypes = [];
        ROADWAY_CLASSIFICATIONS.forEach(rec => {
          if (localStorageSelectViewFilters?.[rec.value]) routeTypes = [...routeTypes, { ...rec }];
        });
        setFilters(prev => ({
          ...prev,
          routeTypes,
          custom: localStorageSelectViewFilters?.custom || null,
          search: localStorageSelectViewFilters?.search || '',
        }));
      } else {
        const filters = {
          search: '',
          routeTypes: ROADWAY_CLASSIFICATIONS,
        };
        setFilters(filters);
      }

      if (localStorageSelectedSegments) {
        const corridors = [];

        localStorageSelectedSegments?.forEach(segment_id => {
          const corridorKey = segment_id.substring(0, segment_id.indexOf('_'));
          if (corridorKey) corridors.push(corridorKey);
        });

        setSelectedCorridors(Array.from(new Set(corridors)));
        setSelectedSegments(localStorageSelectedSegments);
      }
    } else {
      onReset();
    }
  }, [selectedMode, selectedDistrict, selectedMPO]);

  return (
    <>
      <div className="col-lg-6 p-0">
        <MapView />
      </div>
      <div className="col-lg-6 p-0">
        <div className="container header-row">
          <div>
            <div className="d-flex">
              <div className=" my-1 ">
                <button className="btn btn-sm btn-create-new" onClick={() => setCreateNewSelected(true)}>
                  <i className="fa-solid fa-plus"></i>
                  <span>Create New</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-3">
          <div className="row">
            <div className="container-title">Select</div>
          </div>
          <hr className="divider" />
          <CorridorTreeList />
          <ActionButtons onReset={onReset} />
        </div>
      </div>
    </>
  );
}

export default CorridorSelection;
