import { useEffect, useMemo, useState } from 'react';

import TableWithPagination from '../../../../Components/TableWithPagination/TableWithPagination';
import { getCorridorTierColors, getOverallScore } from '../../../../Utils/CorridorRankingHelper';
import { localStorageGetWithExpiry } from '../../../../Utils/LocalStorage';
import { ROADWAY_CLASSIFICATIONS } from '../../../../Utils/Constants';
import { filterBySearchTerm } from '../../../../Utils/SelectViewHelper';
import { useGlobalContext } from '../../../../Context/GlobalContext';

import TableFilter from './TableFilters/TableFilters';

const MapOverview = ({ setHighlightCorridorId, refreshMap, filterSegment }) => {
  const { globalObject, districtFilterSW } = useGlobalContext();

  const [extractedData, setExtractedData] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [filters, setFilters] = useState({});
  const [customWeights, setCustomWeights] = useState({});

  const scoresArray = useMemo(() => globalObject?.map(go => getOverallScore(go)), [globalObject, customWeights]);

  const handleTableFilter = (type, search) => {
    if (type === 'search') setSearchString(search);
    if (type === 'filters') {
      setFilters(search);
      refreshMap();
      filterSegment();
    }
    if (type === 'weights') {
      setCustomWeights(search);
      refreshMap();
      filterSegment();
    }
  };

  useEffect(() => {
    setFilters({ ...filters, district: districtFilterSW });
  }, [districtFilterSW]);

  useEffect(() => {
    if (globalObject && globalObject?.length > 0) {
      const extractedValues = globalObject.map((item, index) => {
        const {
          HWY,
          Description,
          Begin_Milepost,
          End_Milepost,
          Corridor_ID,
          Districts,
          MPO,
          Scr_1,
          Scr_2,
          Scr_3,
          Scr_4,
          Scr_5,
          Scr_6,
          Custom,
        } = item;

        return {
          index: {
            __hide: true,
            __value: index + 1,
          },
          Corridor_ID: {
            __hide: true,
            __value: Corridor_ID,
          },
          Route: HWY,
          Description: Description,
          'Begin Marker': { __value: Begin_Milepost },
          'End Marker': { __value: End_Milepost },
          'Overall Score': {
            __value: getOverallScore(item).toFixed(2),
          },
          Districts: { __hide: true, __value: Districts },
          MPO: { __hide: true, __value: MPO },
          Scr_1: { __hide: true, __value: Scr_1 },
          Scr_2: { __hide: true, __value: Scr_2 },
          Scr_3: { __hide: true, __value: Scr_3 },
          Scr_4: { __hide: true, __value: Scr_4 },
          Scr_5: { __hide: true, __value: Scr_5 },
          Scr_6: { __hide: true, __value: Scr_6 },
          Custom: Custom,
        };
      });
      setExtractedData(extractedValues);
    }
  }, [globalObject, scoresArray, customWeights]);

  const onRowSelected = (event, rowData) => {
    setHighlightCorridorId(rowData?.Corridor_ID?.__value);
  };

  const filteredData = useMemo(() => {
    const roadwayClassifications = filters?.roadwayClassifications?.map(roadway => roadway?.value) || [];
    const includeOthers = roadwayClassifications.includes('OTHERS');

    const updatedData = customWeights
      ? extractedData.sort((a, b) => {
          return parseFloat(a['Overall Score'].__value) < parseFloat(b['Overall Score'].__value) ? 1 : -1;
        })
      : extractedData;
    const filteredArr = updatedData
      .map((corridor, index) => ({
        ...corridor,
        index: {
          __hide: true,
          __value: index + 1,
        },
      }))
      .filter(rec => filterBySearchTerm({ HWY: rec.Route }, searchString))
      .filter(
        rec => roadwayClassifications.length === 0 || roadwayClassifications?.indexOf(rec?.Route.substring(0, 2)) > -1,
      )
      .filter(rec => {
        if (filters?.district && filters?.district !== '') {
          return rec?.Districts?.__value?.includes(filters?.district);
        } else {
          return rec;
        }
      })
      .filter(rec => {
        if (filters?.urbanRural && filters?.urbanRural !== '') {
          return rec?.MPO?.__value?.includes(filters?.urbanRural);
        } else {
          return rec;
        }
      });

    if (includeOthers) {
      const corridorTypes = ROADWAY_CLASSIFICATIONS.map(r => r.value);
      const othersCorridors = extractedData.filter(
        rec =>
          !roadwayClassifications.includes(rec.Route.slice(0, 2)) && !corridorTypes.includes(rec.Route.slice(0, 2)),
      );
      filteredArr.push(...othersCorridors);
    }

    return filteredArr.map(corridor => {
      let colorCode = getCorridorTierColors(scoresArray, corridor['Overall Score'].__value);

      return {
        ...corridor,
        Route: {
          __value: (
            <div>
              {corridor.Route}
              {corridor.Custom === 1 && (
                <img
                  src="/assets/svg/SpecialPlus_segments.svg"
                  alt="custom segment icon"
                  className="custom-segment-icon-alone"
                />
              )}
            </div>
          ),
          HWY: corridor.Route,
        },
        'Overall Score': {
          ...corridor['Overall Score'],
          __style: {
            color: colorCode.color,
            backgroundColor: colorCode.backgroundColor,
          },
        },
        Custom: {
          __hide: true,
        },
      };
    });
  }, [customWeights, extractedData, filters, scoresArray, searchString]);

  useEffect(() => {
    const localStoragefilters = localStorageGetWithExpiry('customFilters');
    if (localStoragefilters) {
      let roadwayClassifications = [];
      ROADWAY_CLASSIFICATIONS.forEach(rec => {
        if (localStoragefilters[rec.value]) roadwayClassifications = [...roadwayClassifications, { ...rec }];
      });
      setFilters(prev => ({
        ...prev,
        roadwayClassifications,
        district: localStoragefilters?.district || '',
        urbanRural: localStoragefilters?.urbanRural || '',
      }));
    }
    const localStorageCustomWeight = localStorageGetWithExpiry('customWeights');
    if (localStorageCustomWeight) {
      setCustomWeights(localStorageCustomWeight);
    }
  }, []);

  return (
    <>
      <TableFilter handleTableFilter={handleTableFilter} filters={filters} customWeights={customWeights} mapview />
      {filteredData && (
        <TableWithPagination
          onRowSelected={onRowSelected}
          data={filteredData}
          itemsPerPage={15}
          indexColumn={{ name: 'Rank', __style: {} }}
          globalObject={globalObject}
          customWeights={customWeights}
          isPaginationAvail={true}
        />
      )}
    </>
  );
};

export default MapOverview;
