import React, { useMemo } from 'react';

import { useSegmentEvalutionStore } from '../../../../store/segment-evaluation/store';

import SectionHeader from './SectionHeader';
import SegmentDropdown from './SegmentDropdown';
import SegmentIndexes from './SegmentIndexes';

const SegmentDetailView = ({
  setIsTableView,
  setIsSegmentDetailViewOpen,
  allCorridorData,
  highlightSegment,
  setHighlightSegment,
}) => {
  const { selectedCorridorId } = useSegmentEvalutionStore(state => state);

  const filteredCorridorData = useMemo(() => {
    return selectedCorridorId
      ? allCorridorData.filter(item => item.attributes.HWY === selectedCorridorId)
      : allCorridorData;
  }, [allCorridorData, selectedCorridorId]);

  const segmentData = useMemo(() => {
    return filteredCorridorData.find(item => String(item.attributes.Segment) === String(highlightSegment));
  }, [filteredCorridorData, highlightSegment]);

  return (
    <div className="evalution-container">
      <SectionHeader
        setIsTableView={setIsTableView}
        setIsSegmentDetailViewOpen={setIsSegmentDetailViewOpen}
        setHighlightSegment={setHighlightSegment}
      />
      <SegmentDropdown
        allCorridorData={allCorridorData}
        setHighlightSegment={setHighlightSegment}
        highlightSegment={highlightSegment}
      />
      <SegmentIndexes segmentData={segmentData} />
    </div>
  );
};

export default SegmentDetailView;
