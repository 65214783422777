function SectionHeader({ setIsSegmentDetailViewOpen, setIsTableView, setHighlightSegment }) {
  return (
    <div className="d-flex justify-content-between px-3" style={{ height: 'fit-content' }}>
      <div className="evalution-title">Segment Key Performance Indicator</div>
      <div className="d-flex justify-content-center align-items-center gap-2">
        <div
          className="toggle-btn"
          onClick={() => {
            setIsSegmentDetailViewOpen(false);
            setIsTableView(true);
            setHighlightSegment(null);
          }}
        >
          <div className={`segment-toggle-btn`}>
            <i class="fa-solid fa-table"></i>
            <span>Table</span>
          </div>
        </div>
        <div
          className="toggle-btn"
          onClick={() => {
            setIsSegmentDetailViewOpen(false);
            setIsTableView(false);
            setHighlightSegment(null);
          }}
        >
          <div className="segment-toggle-btn active">
            <i class="fa-solid fa-map"></i>
            <span>Map</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHeader;
