import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { isTokenValid } from '../Utils/Functions';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [authState, setAuthState] = useState({ isAuthenticated: false });
  const [isInitialized, setIsInitialized] = useState(false); // Add this state
  const [userName, setUserName] = useState(''); // Add this state
  
  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('jwt');

      if (token) {
        const isValidToken = await isTokenValid(token); // Modify this based on your validation logic
        if (isValidToken) {
          setUserName(localStorage.getItem('Username'));
          setAuthState({ isAuthenticated: true });
        } else {
          localStorage.clear();
        }
      }

      setIsInitialized(true); // Mark initialization as complete
    };

    checkAuth();
  }, []);

  const setToken = token => {
    if (token) {
      localStorage.setItem('jwt', token);
      setAuthState({ isAuthenticated: true });
    }
  };

  const setusername = username => {
    localStorage.setItem('Username', username);
    setUserName(username);
  };
  const logout = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('selectedDdOptionsIds');
    localStorage.removeItem('Username');
    localStorage.clear();
    setAuthState({ isAuthenticated: false });
  };

  const authenticatedAxios = axios.create();
  authenticatedAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('jwt');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  // Provide the initialized state as well
  return (
    <AuthContext.Provider
      value={{
        authState,
        setToken,
        setusername,
        logout,
        authenticatedAxios,
        isInitialized,
        userName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
