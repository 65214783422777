import { useCallback } from "react";

import { useSegmentEvalutionStore } from "../../../../store/segment-evaluation/store";

function SectionHeader({ refreshMap }) {
  const { setSelectedCorridorId, selectedCorridorId } =
    useSegmentEvalutionStore((state) => state);

  const handleResetCorridor = useCallback(() => {
    setSelectedCorridorId(null);
    refreshMap();
  }, [setSelectedCorridorId, refreshMap]);

  return (
    <div
      className={`d-flex justify-content-between pb-4`}
      style={{ height: "fit-content" }}
    >
      <div className="import-title fw-bold">Import Corridor</div>
      {selectedCorridorId && (
        <div className="">
          <i class="fa-solid fa-xmark" onClick={handleResetCorridor}></i>
        </div>
      )}
    </div>
  );
}

export default SectionHeader;
