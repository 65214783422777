import { useEffect, useCallback, useState } from 'react';

import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

import { SEGMENT_EVALUTION_KEY_MARKERS_SERVICE_URL } from '../../../../../Utils/Constants';

import { getRendererConfigOne } from './helper';

function useCorridorKeyMarkerLayer() {
  const [corridorKeyMarkersLayer, setCorridorKeyMarkersLayer] = useState(null);

  const generateLayer = useCallback(() => {
    const rendererConfig = getRendererConfigOne();
    const layer = new FeatureLayer({
      url: SEGMENT_EVALUTION_KEY_MARKERS_SERVICE_URL,
      outFields: ['*'],
      title: 'Segment Breakpoints',
      renderer: rendererConfig,
    });
    setCorridorKeyMarkersLayer(layer);
  }, []);

  useEffect(() => {
    generateLayer();
  }, [generateLayer]);

  return {
    corridorKeyMarkersLayer,
  };
}

export default useCorridorKeyMarkerLayer;
