import { getColorCode, colorCodeMapper, numberFormatter } from '../../../../helper';

import BoxElement from '../BoxElement';

const GOOD = '#92d050';

function FreightIndex({ segmentData }) {
  const Facility_T = segmentData?.attributes?.Facility_T;
  const I_U = segmentData?.attributes?.I_U;
  const Bound_1_B1 = segmentData?.attributes?.Bound_1_B1;
  const Bound_2_B2 = segmentData?.attributes?.Bound_2_B2;

  const attributesToMap = {
    Freight_I: {
      value: numberFormatter(segmentData?.attributes?.Freight_I),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Freight_I),
        colorCodeMapper['Freight'][Facility_T]?.Freight_I[I_U],
      ),
    },
    TTI_trk_B1: {
      value: numberFormatter(segmentData?.attributes?.TTI_trk_B1),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.TTI_trk_B1),
        colorCodeMapper['Freight'][Facility_T]?.TTI_trk_B1[I_U],
      ),
    },
    TTI_trk_B2: {
      value: numberFormatter(segmentData?.attributes?.TTI_trk_B2),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.TTI_trk_B2),
        colorCodeMapper['Freight'][Facility_T]?.TTI_trk_B2[I_U],
      ),
    },
    PTI_trk_B1: {
      value: numberFormatter(segmentData?.attributes?.PTI_trk_B1),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.PTI_trk_B1),
        colorCodeMapper['Freight'][Facility_T]?.PTI_trk_B1[I_U],
      ),
    },
    PTI_trk_B2: {
      value: numberFormatter(segmentData?.attributes?.PTI_trk_B2),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.PTI_trk_B2),
        colorCodeMapper['Freight'][Facility_T]?.PTI_trk_B2[I_U],
      ),
    },
    Bridge_Loa: {
      value: numberFormatter(segmentData?.attributes?.Bridge_Loa),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Bridge_Loa),
        colorCodeMapper['Freight'].Bridge_Loa,
      ),
    },
    Bridge_Ver: {
      value: numberFormatter(segmentData?.attributes?.Bridge_Ver),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Bridge_Ver),
        colorCodeMapper['Freight'].Bridge_Ver,
      ),
    },
  };

  return (
    <div className="indicator-rectengle-section mx-3 d-flex justify-content-start align-items-center pb-1 position-relative">
      <div className="indicator-box d-flex justify-content-center align-items-center flex-column gap-2">
        <div className="indicator-img">
          <img className="card-img-top" src="/assets/svg/Economic.svg" alt="Pavement" />
        </div>
        <div className="indicator-type fw-bold">Freight Index</div>
        <div className="indicator-value" style={{ color: attributesToMap['Freight_I'].colorCode }}>
          {attributesToMap['Freight_I'].value}
        </div>
      </div>
      <div className="indicator-side-detail d-flex justify-content-start align-items-center position-absolute">
        <BoxElement
          description="Directional Travel Time Index (trucks only)"
          value1={attributesToMap['TTI_trk_B1'].value}
          valueTitle1={Bound_1_B1}
          colorCode1={attributesToMap['TTI_trk_B1'].colorCode}
          value2={attributesToMap['TTI_trk_B2'].value}
          valueTitle2={Bound_2_B2}
          colorCode2={attributesToMap['TTI_trk_B2'].colorCode}
        />
        <BoxElement
          description="Directional Planning Time Index (trucks only)"
          value1={attributesToMap['PTI_trk_B1'].value}
          valueTitle1={Bound_1_B1}
          colorCode1={attributesToMap['PTI_trk_B1'].colorCode}
          value2={attributesToMap['PTI_trk_B2'].value}
          valueTitle2={Bound_2_B2}
          colorCode2={attributesToMap['PTI_trk_B2'].colorCode}
        />
        <BoxElement
          description="Bridge Load Ratings"
          value1={attributesToMap['Bridge_Loa'].value}
          colorCode1={attributesToMap['Bridge_Loa'].colorCode}
        />
        <BoxElement
          description="Bridge Vertical Clearance"
          value1={attributesToMap['Bridge_Ver'].value}
          colorCode1={attributesToMap['Bridge_Ver'].colorCode || GOOD}
        />
      </div>
    </div>
  );
}

export default FreightIndex;
