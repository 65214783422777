import LabelClass from '@arcgis/core/layers/support/LabelClass';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';

export const getlineLabelClass = () => {
  return new LabelClass({
    labelExpressionInfo: {
      expression: '$feature.Segment',
    }, // Example to hint at using an expression for uniqueness
    symbol: new TextSymbol({
      color: 'black',
      font: {
        size: 10,
        // family: "Arial",
        weight: 'bold',
      },
      verticalAlignment: 'middle', // Attempt to control vertical placement
      haloColor: 'white',
      haloSize: '1px',
    }),
    labelPlacement: 'above-along', // This is limited for line labels
    // No direct support for 'deconflictionStrategy' here; used for illustrative purposes
    deconflictionStrategy: 'none', // This property doesn't exist; showing intent
  });
};

export const getRendererConfig = () => {
  return {
    type: 'simple',
    symbol: {
      type: 'simple-line',
      outline: {
        color: '#6c757d',
        width: '3px',
      },
    },
  };
};

export const queryFeatureLayer = async layer => {
  const query = await layer.createQuery();
  query.outFields = ['*'];
  return await layer.queryFeatures(query).then(result => {
    return result.features;
  });
};
