import { Container } from 'reactstrap';
import { useMemo, useEffect } from 'react';
import { Col, Row } from 'reactstrap';

import { useGlobalContext } from '../../Context/GlobalContext';
import { getOverallScore, getCorridorTierColors } from '../../Utils/CorridorRankingHelper';
import { convertMultiLineStringToGraphic } from '../../Utils/MapHelpers';

import useCustomSegments from './Hooks/useCustomSegments';
import MapTableOverview from './MapOverview/MapTableView';
import PerformanceTrackingHeader from './PerformanceTrackingHeader';
import useMapView from './Hooks/useMapView';
import { localStorageGetWithExpiry } from '../../Utils/LocalStorage';

const PerformanceTracking = () => {
  const { globalObject, performanceTrackingData, districtFilterSW } = useGlobalContext();
  const { mapContainerRef, graphicsLayerRef, refreshMap } = useMapView();
  const { filteredCustomSegments = [], filterSegment } = useCustomSegments();
  const localStorageCustomWeight = localStorageGetWithExpiry('customWeights');

  const filteredCorridorList = useMemo(() => {
    return performanceTrackingData?.map(corridor => {
      return {
        Id: corridor['ID'],
        Route: corridor['HWY'],
        BMP: corridor['BMP'],
        EMP: corridor['EMP'],
        Rank: 0,
        Score_Change: corridor['Score_Change'],
        Trend: corridor['Trend'],
        Overall_Score: corridor['Overall_Score'],
        Pavement_Score: corridor['Pavement_Score'],
        Bridge_Score: corridor['Bridge_Score'],
        Safety_Score: corridor['Safety_Score'],
        Congestion_Score: corridor['Congestion_Score'],
        Economic_Score: corridor['Economic_Score'],
        Connectivity_Score: corridor['Connectivity_Score'],
      };
    });
  }, [performanceTrackingData]);

  const filteredGlobalObject = useMemo(() => {
    return globalObject
      ?.map(item => {
        return { ...item, 'Overall Score': getOverallScore(item).toFixed(2) };
      })
      ?.sort((a, b) => {
        return parseFloat(a?.['Overall Score']) < parseFloat(b?.['Overall Score']) ? 1 : -1;
      })
      ?.slice(0, 20);
  }, [globalObject, localStorageCustomWeight]);

  useEffect(() => {
    graphicsLayerRef.current.removeAll();
    if (filteredCustomSegments?.length > 0) {
      const graphics = [];
      filteredCustomSegments.forEach(segment => {
        const geometry = segment?.Geometry;
        const ob = JSON.parse(geometry);
        const segmentGraphics =
          ob.features?.map(feature => {
            if (feature.geometry.type === 'MultiLineString') {
              const scoresArray = filteredGlobalObject?.map(go => getOverallScore(go));
              const overallScore = getOverallScore(segment).toFixed(2);
              const colorCode = getCorridorTierColors(scoresArray, overallScore);
              const color = colorCode.backgroundColor === '#FFFFFF' ? 'gray' : colorCode.backgroundColor;
              return convertMultiLineStringToGraphic(feature, { ...segment, Scr_Tot: getOverallScore(segment) }, color);
            }
          }) ?? [];
        graphics.push(...segmentGraphics);
      });
      graphicsLayerRef.current.addMany(graphics);
    }
  }, [filteredCustomSegments, filteredGlobalObject]);

  return (
    <Container fluid className="performanceTracking">
      <PerformanceTrackingHeader />
      <div className="row">
        {filteredCorridorList && (
          <Row>
            <Col md="4" style={{ marginTop: '64px' }}>
              <div
                ref={mapContainerRef}
                className="map-container"
                style={{ height: '575px', overflow: 'hidden' }}
              ></div>
            </Col>
            <Col md="8">
              <MapTableOverview
                globalObject={filteredGlobalObject}
                refreshMap={refreshMap}
                districtFilterSW={districtFilterSW}
                filteredCorridorList={filteredCorridorList}
                filterSegment={filterSegment}
              />
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
};

export default PerformanceTracking;
