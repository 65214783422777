import { useRef, useEffect, useCallback, useState } from 'react';

import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import esriConfig from '@arcgis/core/config';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';

import { createPopupTemplate, getCorridorLayerUrl } from '../../../../Utils/Functions';
import { useSegmentEvalutionStore } from '../../../../store/segment-evaluation/store';

import {
  DEFAULT_MAP_CENTRAL_POINT,
  DEFAULT_SPATIAL_REF,
  MAP_API_KEY,
  RENDERER_BACKGROUND,
  RENDERER_HIGHLIGHT,
} from '../../../../Utils/Constants';

function useMapView() {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const { selectedMode } = useSegmentEvalutionStore(state => state);
  const mapContainerRef = useRef(null);
  const mapViewRef = useRef(null);
  const viewRef = useRef(null);
  const corridorLayerRef = useRef();
  const graphicsLayerRef = useRef();

  const generateMap = useCallback(() => {
    esriConfig.apiKey = MAP_API_KEY;
    setIsMapLoaded(false);

    // Create the corridor layer
    const corridorLayer = new FeatureLayer({
      url: getCorridorLayerUrl(selectedMode),
      popupTemplate: createPopupTemplate(),
      title: 'Selected Corridors',
      id: 'selected-corridors',
      dockEnabled: false,
      renderer: RENDERER_HIGHLIGHT,
    });

    // A copy of the corridor layer in the background
    const corridorLayerBackground = new FeatureLayer({
      url: getCorridorLayerUrl(selectedMode),
      title: 'Candidate Corridors',
      id: 'candidate-corridors',
      dockEnabled: false,
      renderer: RENDERER_BACKGROUND,
    });

    // Initialize a GraphicsLayer
    const graphicsLayer = new GraphicsLayer({
      title: 'User-defined Corridors',
    });

    // Create the map
    const map = new Map({
      basemap: 'arcgis-light-gray',
      layers: [corridorLayerBackground, corridorLayer, graphicsLayer],
      spatialReference: DEFAULT_SPATIAL_REF,
    });

    // Create the map view
    const view = new MapView({
      map: map,
      center: DEFAULT_MAP_CENTRAL_POINT,
      zoom: 5.75,
      container: mapContainerRef.current,
      constraints: {
        snapToZoom: false,
      },
    });

    // Initialize Refs values
    corridorLayerRef.current = corridorLayer;
    mapViewRef.current = map;
    viewRef.current = view;
    graphicsLayerRef.current = graphicsLayer;
    setIsMapLoaded(true);

    // Clean up
    return () => {
      view.destroy();
    };
  }, []);

  const refreshMap = useCallback(() => {
    if (viewRef.current) {
      viewRef.current.container = null;
      viewRef.current = null;
    }
    generateMap();
  }, [generateMap]);

  useEffect(() => {
    generateMap();
  }, [generateMap]);

  return {
    mapContainerRef,
    mapViewRef,
    viewRef,
    corridorLayerRef,
    graphicsLayerRef,
    refreshMap,
    isMapLoaded,
  };
}

export default useMapView;
