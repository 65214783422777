import { useMemo, useCallback } from 'react';

import { useGlobalContext } from '../../Context/GlobalContext';

const measuringUnitList = [
  {
    title: 'Pavement',
    titletext: 'Pavement Condition Rating',
    svg: 'assets/svg/Performance_Tracking/Pavement.svg',
  },
  {
    title: 'Bridge',
    titletext: 'Bridge Sufficiency Rating',
    svg: 'assets/svg/Performance_Tracking/Bridge.svg',
  },
  {
    title: 'Safety',
    titletext: 'Rate of Fatal and Incapacitating Crashes and Rate of Total Crashes',
    svg: 'assets/svg/Performance_Tracking/Safety.svg',
  },
  {
    title: 'Congestion',
    titletext: 'Existing & Future Volume to Capacity Ratios and if corridor is on list of Top 100 Congested Corridors',
    svg: 'assets/svg/Performance_Tracking/Congestion_Mobility.svg',
  },
  {
    title: 'Economic',
    titletext: 'Population & Employment Densities, Truck Volumes, and Commodity Flow',
    svg: 'assets/svg/Performance_Tracking/Economic_Freight.svg',
  },
  {
    title: 'Connectivity',
    titletext: 'Freight Network, Trunk System, Hurricane Evacuation Route, Energy Route, and Trade Corridors',
    svg: 'assets/svg/Performance_Tracking/Connectivity.svg',
  },
];

const PerformanceTrackingHeader = () => {
  const { globalObject } = useGlobalContext();

  const hwyCounts = useMemo(() => {
    return globalObject?.reduce((acc, curr) => {
      const category = curr.HWY.substring(0, 2);
      acc[category] = (acc[category] || 0) + 1;
      return acc;
    }, {});
  }, [globalObject]);

  const getTotalOtherCorridors = useCallback(
    corridors => {
      let totalOtherCorridors = 0;
      for (let corridor in corridors) {
        if (corridors.hasOwnProperty(corridor)) {
          if (corridor !== 'IH' && corridor !== 'US' && corridor !== 'SH') {
            totalOtherCorridors += corridors[corridor];
          }
        }
      }

      return totalOtherCorridors;
    },
    [hwyCounts],
  );

  if (!hwyCounts) {
    return null;
  }

  return (
    <>
      <div className="pt-header-row row">
        <div className="bg-dark-blue text-light">
          <div className="container">Top 20 Corridor Needs Assessment Summary</div>
        </div>
      </div>
      <div className="row first-section">
        <div className="first-section-wrapper">
          <div className="col-7 measuring-units">
            <div className="measuring-units-wrapper">
              {measuringUnitList.map((item, index) => (
                <div key={index} className="measuring-unit-box">
                  <div className="measuring-unit-title">{item.title}</div>
                  <div className="measuring-unit-content text-center">{item.titletext}</div>
                  <div className="measuring-unit-icon">
                    <img src={item.svg} alt="Icon" className="svg-icon" />
                  </div>
                </div>
              ))}
            </div>
            <div className="measuring-units-header">What is being measured? </div>
          </div>
          <div className="col-5 study-corridors-wrapper">
            <div className="row flex-grow-1 ">
              <div className="quadrant">
                <img className="quadrant-img" src="assets/svg/Performance_Tracking/Corridor_IH.svg" alt="Corridor_IH" />

                <div className="quadrant-text">
                  <div>{hwyCounts.IH || 0}</div>
                  <div className="quadrant-text-title"> TOTAL IH CORRIDORS</div>
                </div>
              </div>
              <div className="quadrant">
                <img className="quadrant-img" src="assets/svg/Performance_Tracking/Corridor_SH.svg" alt="Corridor_SH" />

                <div className="quadrant-text">
                  <div>{hwyCounts.SH || 0}</div>
                  <div className="quadrant-text-title"> TOTAL SH CORRIDORS</div>
                </div>
              </div>
            </div>
            <div className="row flex-grow-1">
              <div className="quadrant">
                <img className="quadrant-img" src="assets/svg/Performance_Tracking/Corridor_US.svg" alt="Corridor_US" />

                <div className="quadrant-text">
                  <div>{hwyCounts.US || 0}</div>
                  <div className="quadrant-text-title"> TOTAL US CORRIDORS</div>
                </div>
              </div>
              <div className="quadrant">
                <img
                  className="quadrant-img"
                  src="assets/svg/Performance_Tracking/Corridor_Other.svg"
                  alt="Corridor_Other"
                />

                <div className="quadrant-text">
                  <div>{getTotalOtherCorridors(hwyCounts)}</div>
                  <div className="quadrant-text-title">Other Corridors</div>
                </div>
              </div>
            </div>
            <div className="study-corridors-header">Study Corridors</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceTrackingHeader;
