export const imagedata_to_image = (imagedata) => {
  if (!imagedata) return;
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  canvas.width = imagedata.width;
  canvas.height = imagedata.height;
  ctx.putImageData(imagedata, 0, 0);

  let image = new Image();
  image.src = canvas.toDataURL();
  return image;
};
