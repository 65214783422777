const headerColor = {
  textAlign: "center",
  font: "normal normal normal 14px/16px Franklin Gothic",
  letterSpacing: "0px",
  color: "#000000",
  opacity: "1",
  backgroundColor: "white",
  border: "none",
  fontWeight: "bold",
};

export const getFilteredData = (selectedCorridorData) => {
  return selectedCorridorData.map((corridor) => ({
    "Segment ID": {
      __value: corridor.attributes.Segment,
      __style: {
        background: "#ECEDED 0% 0% no-repeat padding-box",
        opacity: 1,
        textAlign: "center",
        font: "bold normal normal 24px/28px Franklin Gothic",
        letterSpacing: "0px",
        color: "#000000",
        width: "60px",
      },
      __headerStyle: { ...headerColor, textAlign: "left" },
    },
    BMP: {
      __value: corridor.attributes.BMP,
      __headerStyle: headerColor,
      __style: {
        font: "normal normal normal 16px/28px Franklin Gothic",
        textAlign: "center",
      },
    },
    EMP: {
      __value: corridor.attributes.EMP,
      __headerStyle: headerColor,
      __style: {
        font: "normal normal normal 16px/28px Franklin Gothic",
        textAlign: "center",
      },
    },
    "Length (mi)": {
      ...corridor["Overall Score"],
      __style: {
        textAlign: "center",
        font: "normal normal normal 16px/28px Franklin Gothic",
      },
      __headerStyle: headerColor,
      __value: corridor.attributes.Length,
    },
  }));
};

export const getDefinitionExpression = (allCorridorData = []) => {
  const expressionParts = allCorridorData.map(item => `HWY = '${item.attributes.HWY}'`);
  return expressionParts.join(' OR  ');
};