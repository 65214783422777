import { useState } from 'react';

import { useCorridorSelectionContext } from '../Context/CorridorSelectionContext';

function FitlerChecker({ filteredSelectViewData }) {
  const [showSelectOnly, setShowSelectOnly] = useState(false);
  const {
    selectedSegments,
    selectAllCorridors,
    selectViewData,

    setSelectAllCorridors,
    setSelectedCorridors,
    setSelectedSegments,
    setSelectViewDataTemp,
  } = useCorridorSelectionContext();

  const onSelectAllCorridors = event => {
    const checked = event.target.checked;
    setSelectAllCorridors(checked);
    if (!checked) {
      setSelectedCorridors([]);
      setSelectedSegments([]);
      return;
    }
    const corridors = [];
    const segments = [];
    Object.values(filteredSelectViewData).forEach(sc => {
      sc.forEach(s => {
        segments.push(s.Corridor_ID);
      });

      if (sc.length > 1) {
        const corridorKey = sc[0].Corridor_ID.substring(0, sc[0].Corridor_ID.indexOf('_'));
        corridors.push(corridorKey);
      }
    });
    setSelectedCorridors(prev => [...new Set([...prev, ...corridors])]);
    setSelectedSegments(prev => [...new Set([...prev, ...segments])]);
  };

  const onSelectedOnlyCorridors = event => {
    const checked = event.target.checked;
    setShowSelectOnly(checked);
    if (!checked) {
      setSelectViewDataTemp(selectViewData);
      return;
    }
    const selected = [];
    selectedSegments.forEach(sc => {
      selected.push(sc);

      const corridorKey = sc.substring(0, sc.indexOf('_'));
      const isSegment = selectViewData?.[corridorKey]?.length > 1;
      if (!selected.includes(corridorKey) && isSegment) selected.push(corridorKey);
    });

    const temp = {};
    for (const [corridorKey, segments] of Object.entries(selectViewData)) {
      segments.forEach((s, index) => {
        if (selected.includes(s.Corridor_ID)) temp[corridorKey] = [...(temp?.[corridorKey] || []), s];
      });
    }
    setSelectViewDataTemp(temp);
  };

  return (
    <div id="sticky-header" className="row">
      <div className="col-lg-4">
        <input id="select-all-corridors" type="checkbox" checked={selectAllCorridors} onChange={onSelectAllCorridors} />
        <label htmlFor="select-all-corridors">Select all</label>
      </div>
      <div className="col-lg-4 text-nowrap">
        <span id="selected-corridors-counter">{selectedSegments.length}</span>
        &nbsp;selected
      </div>
      <div className="col-lg-4 text-nowrap w-auto">
        <input id="show-selected-only" type="checkbox" checked={showSelectOnly} onChange={onSelectedOnlyCorridors} />
        <label htmlFor="show-selected-only">Show selected only</label>
      </div>
    </div>
  );
}

export default FitlerChecker;
