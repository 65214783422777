import Legend from "@arcgis/core/widgets/Legend";
import LayerList from "@arcgis/core/widgets/LayerList";
import Expand from "@arcgis/core/widgets/Expand";

export const getLabelingInfo = () => {
  return [
    {
      symbol: {
        type: "text",
        color: "black",
        font: {
          family: "Playfair Display",
        },
      },
    },
  ];
};

export const addAdditionalSections = (
  view,
  corridorLayer,
  districtLayer,
  countyLayer
) => {
  const legend = new Legend({
    view: view,
    layerInfos: [
      {
        layer: corridorLayer,
      },
      {
        layer: districtLayer,
      },
      {
        layer: countyLayer,
        title: "County Boundaries",
      },
    ],
  });
  const expandLegend = new Expand({
    view: view,
    content: legend,
  });
  const layerList = new LayerList({
    view: view,
  });
  const expandLayerList = new Expand({
    view: view,
    content: layerList,
  });

  view.ui.add(expandLegend, "bottom-left");
  view.ui.add(expandLayerList, "bottom-left");
  view.on("click", (_) => {
    if (expandLegend) {
      expandLegend.expanded = false;
    }
  });
};
