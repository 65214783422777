import jwt_decode from 'jwt-decode';
import { round } from 'lodash';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import {
  CORRIDOR_FEATURE_SERVICE_URL,
  CORRIDOR_FEATURE_SERVICE_URL_HOUSTON,
  PERFORMANCE_TRACKING_SEGMENTS_URL,
  PERFORMANCE_TRACKING_SEGMENTS_URL_DISTRICTS,
} from './Constants';

export const createPopupTemplate = () => {
  return {
    title: '{HWY}',
    content: [
      {
        type: 'fields',
        fieldInfos: [
          // {
          //   fieldName: "OBJECTID", //ObjectId
          //   label: "ObjectId",
          // },
          {
            fieldName: 'ID',
            label: 'ID',
          },
          {
            fieldName: 'HWY',
            label: 'Route',
          },
          {
            fieldName: 'BMP',
            label: 'Begin Marker',
            format: {
              places: 0,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'EMP',
            label: 'End Marker',
            format: {
              places: 0,
              digitSeparator: true,
            },
          },
          // {
          //   fieldName: 'Overall_Score',
          //   label: 'Overall Score (0-100)',
          //   format: {
          //     places: 2,
          //     digitSeparator: true,
          //   },
          // },
          {
            fieldName: 'Pavement_Score',
            label: 'Pavement Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Bridge Score',
            label: 'Bridge Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Safety_Score',
            label: 'Safety Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Congestion_Score',
            label: 'Congestion Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Economic_Score',
            label: 'Economic Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Connectivity_Score',
            label: 'Connectivity Score (0-10)',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
        ],
      },
    ],
  };
};

export const createPopupTemplate_Segment = () => {
  return {
    title: '{HWY}',
    content: [
      {
        type: 'fields',
        fieldInfos: [
          {
            fieldName: 'HWY',
            label: 'Route',
          },
          {
            fieldName: 'BMP',
            label: 'Begin Marker',
          },
          {
            fieldName: 'EMP',
            label: 'End Marker',
          },
          {
            fieldName: 'Segment',
            label: 'Segment',
          },
          {
            fieldName: 'Weighted',
            label: 'Overall Score',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Pavement_N',
            label: 'Pavement Score',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Bridge_N',
            label: 'Bridge Score',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Mobility_N',
            label: 'Mobility Score',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Safety_N',
            label: 'Safety Score',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
          {
            fieldName: 'Freight_N',
            label: 'Freight Score',
            format: {
              places: 2,
              digitSeparator: true,
            },
          },
        ],
      },
    ],
  };
};

export const toPercentage = (value, toFixed = 2) => {
  return (value * 100).toFixed(toFixed) + '%';
};

export const isTokenValid = token => {
  try {
    const decodedToken = jwt_decode(token);
    if (!decodedToken) return false;

    const now = Date.now() / 1000;
    return decodedToken.exp > now;
  } catch (error) {
    return false;
  }
};

export const queryFeatureLayer = (layerRefs, setAllCorridorData) => {
  Promise.all(
    layerRefs.map(layerRef => {
      const query = layerRef.current.createQuery();
      query.outFields = ['*'];
      return layerRef.current.queryFeatures(query);
    }),
  ).then(results => {
    console.log('__results', results);
    results.forEach(result => {
      const features = result.features;
      setAllCorridorData(features);
    });
  });
};

export const getNeedValue = (needValue, selectedSwitchValue) => {
  const isNeedValue = selectedSwitchValue === 'Need';
  switch (needValue) {
    //overall
    case 'Overall':
      return 'Weighted';
    case 'Pavement':
      return isNeedValue ? 'Pavement_N' : 'Pavement_I';
    case 'Bridge':
      return isNeedValue ? 'Bridge_N' : 'Bridge_I';
    case 'Mobility':
      return isNeedValue ? 'Mobility_N' : 'Mobility_I';
    case 'Safety':
      return isNeedValue ? 'Safety_N' : 'Safety_I';
    case 'Freight':
      return isNeedValue ? 'Freight_N' : 'Freight_I';

    //pavement
    case 'Pavement Index':
      return isNeedValue ? 'Pavement_L' : 'Pavement_I';
    // case "Pavement Condition":
    //   return isNeedValue ? "Pavement_N" : "Score_PvmtCondition";
    case 'Pavement Distress Score 1':
      return isNeedValue ? 'Distress_N1' : 'Distress_1';
    case 'Pavement Distress Score 2':
      return isNeedValue ? 'Distress_N2' : 'Distress_2';
    case 'Pavement Ride Score 1':
      return isNeedValue ? 'Ride_Sc_N1' : 'Ride_Sc_1';
    case 'Pavement Ride Score 2':
      return isNeedValue ? 'Ride_Sc_N2' : 'Ride_Sc_2';
    case 'Pavement Condition Frontage 1':
      return isNeedValue ? 'Frontage_N1' : 'Frontage_1';
    case 'Pavement Condition Frontage 2':
      return isNeedValue ? 'Frontage_N2' : 'Frontage_2';
    case 'Pavement Substandard':
      return isNeedValue ? 'Sub_Standa_N' : 'Sub_Standa';

    //bridge
    case 'Bridge Index':
      return isNeedValue ? 'Bridge_L' : 'Bridge_I';
    case 'Bridge Sufficiency':
      return isNeedValue ? 'Bridge_Suf_N' : 'Bridge_Suf';
    case 'Bridge Rating':
      return isNeedValue ? 'Bridge_Rat_N' : 'Bridge_Rat';
    case 'Culvert Rating':
      return isNeedValue ? 'Culvert_Ra_N' : 'Culvert_Ra';
    case 'Functional Obsolete Bridges':
      return isNeedValue ? 'Obsolete_B_N' : 'Obsolete_B';

    //mobility
    case 'Mainlane Future V/C':
      return isNeedValue ? 'Future_VC_N' : 'Future_VC';
    case 'Existing Mainlane Peak Hour V/C 1':
      return isNeedValue ? 'Peak_VC_N1' : 'Peak_VC_1';
    case 'Existing Mainlane Peak Hour V/C 2':
      return isNeedValue ? 'Peak_VC_N2' : 'Peak_VC_2';
    case 'Frontage Road V/C Existing':
      return isNeedValue ? 'FrontVC_21_N' : 'FrontVC_21';
    case 'Frontage Road V/C Future':
      return isNeedValue ? 'FrontVC_41_N' : 'FrontVC_41';
    case 'Interchange V/C Existing':
      return isNeedValue ? 'Inte_VC_21_N' : 'Inte_VC_21';
    case 'Interchange V/C Future':
      return isNeedValue ? 'Inte_VC_41_N' : 'Inte_VC_41';
    case 'TTI 1':
      return isNeedValue ? 'TTI_all_N1' : 'TTI_all_B1';
    case 'TTI 2':
      return isNeedValue ? 'TTI_all_N2' : 'TTI_all_B2';
    case 'PTI 1':
      return isNeedValue ? 'PTI_all_N1' : 'PTI_all_B1';
    case 'PTI 2':
      return isNeedValue ? 'PTI_all_N2' : 'PTI_all_B2';

    // safety
    case 'Safety Index':
      return isNeedValue ? 'Safety_L' : 'Safety_I';
    case 'Safety Mainlane Rates 1':
      return isNeedValue ? 'ML_Rate_N1' : 'ML_Rate_B1';
    case 'Safety Mainlane Rates 2':
      return isNeedValue ? 'ML_Rate_N2' : 'ML_Rate_B2';
    case 'Safety Frontage Rate':
      return isNeedValue ? 'Front_Rate_N' : 'Front_Rate';

    // freight
    case 'Freight Index':
      return isNeedValue ? 'Freight_L' : 'Freight_I';
    case 'Truck TTI 1':
      return isNeedValue ? 'TTI_trk_N1' : 'TTI_trk_B1';
    case 'Truck TTI 2':
      return isNeedValue ? 'TTI_trk_N2' : 'TTI_trk_B2';
    case 'Truck PTI 1':
      return isNeedValue ? 'PTI_trk_N1' : 'PTI_trk_B1';
    case 'Truck PTI 2':
      return isNeedValue ? 'PTI_trk_N2' : 'PTI_trk_B2';
    case 'Bridge Load Rating':
      return isNeedValue ? 'Bridge_Loa_N' : 'Bridge_Loa';
    case 'Bridge Vertical Clearance':
      return isNeedValue ? 'Bridge_Ver_N' : 'Bridge_Ver';

    default:
      return 'Weighted';
  }
};

export const formattedNumber = value => {
  if (value === 'N/A') {
    return 'None';
  } else if (
    value === 'High' ||
    value === 'Medium' ||
    value === 'Low' ||
    value === 'Good' ||
    value === 'Fair' ||
    value === 'Poor' ||
    value === 'None'
  ) {
    return value;
  } else {
    const roundedNumber = round(value, 2);
    const formattedNumber = roundedNumber.toFixed(2);
    return formattedNumber;
  }
};

export const getUniqueValueRenderer = (selectedSwitchValue, selectedDropDownValue, fieldValue, segmentData, highlightSegment) => {
  console.log('__selectedSwitchValue:', selectedSwitchValue);
  console.log('__selectedDropDownValue:', selectedDropDownValue);
  console.log('__fieldValue:', fieldValue);
  console.log('__segmentData:', segmentData);
  // assuming that all segments have the same Facility_T, I_U, and HWY
  const Facility_T = segmentData?.attributes?.Facility_T;
  const HWY = segmentData?.attributes?.HWY?.slice(0, 2);

  if(highlightSegment) {
    return new ClassBreaksRenderer({
      field: fieldValue,
      valueExpressionTitle: selectedDropDownValue,
      classBreakInfos: [
        {
          minValue: 0,
          maxValue: 1000,
          symbol: new SimpleLineSymbol({ color: '#6C757D', width: 3 }),
          label: 'No need',
        },
      ],
    });
  }

  if (selectedSwitchValue === 'Need') {
    return new ClassBreaksRenderer({
      field: fieldValue,
      valueExpressionTitle: selectedDropDownValue,
      defaultSymbol: new SimpleLineSymbol({ color: '#6C757D', width: 3 }),
      classBreakInfos: [
        {
          minValue: 0,
          maxValue: 0.5,
          symbol: new SimpleLineSymbol({ color: 'gray', width: 3 }),
          label: 'No need',
        },
        {
          minValue: 0.5,
          maxValue: 1.5,
          symbol: new SimpleLineSymbol({ color: '#D9F1FD', width: 3 }),
          label: 'Low Need Segments',
        },
        {
          minValue: 1.5,
          maxValue: 2.5,
          symbol: new SimpleLineSymbol({ color: '#81B4E1', width: 3 }),
          label: 'Medium Need Segments',
        },
        {
          minValue: 2.5,
          maxValue: 1000000,
          symbol: new SimpleLineSymbol({ color: '#25306E', width: 3 }),
          label: 'High Need Segments',
        },
      ],
    });
  } else {
    if (selectedDropDownValue === 'Overall') {
      return new ClassBreaksRenderer({
        field: fieldValue,
        valueExpressionTitle: 'Overall',
        defaultSymbol: new SimpleLineSymbol({ color: '#6C757D', width: 3 }),
        classBreakInfos: [
          {
            minValue: 0,
            maxValue: 0.5,
            symbol: new SimpleLineSymbol({ color: '#f8f9fa', width: 3 }),
            label: 'No need',
          },
          {
            minValue: 0.5,
            maxValue: 1.5,
            symbol: new SimpleLineSymbol({ color: '#D9F1FD', width: 3 }),
            label: 'Low Need Segments', //"1 - <3"
          },
          {
            minValue: 1.5,
            maxValue: 2.5,
            symbol: new SimpleLineSymbol({ color: '#81B4E1', width: 3 }),
            label: 'Medium Need Segments',
          },
          {
            minValue: 2.5,
            maxValue: 1000000,
            symbol: new SimpleLineSymbol({ color: '#25306E', width: 3 }),
            label: 'High Need Segments',
          },
        ],
      });
    } else if (selectedDropDownValue === 'Pavement') {
      return new ClassBreaksRenderer({
        field: fieldValue,
        valueExpressionTitle: 'Pavement',
        defaultSymbol: new SimpleLineSymbol({ color: '#6C757D', width: 3 }),
        classBreakInfos: [
          {
            minValue: 0,
            maxValue: 60,
            symbol: new SimpleLineSymbol({ color: '#FF0000', width: 3 }),
            label: 'Poor Performance',
          },
          {
            minValue: 60,
            maxValue: 80,
            symbol: new SimpleLineSymbol({ color: '#FEC000', width: 3 }),
            label: 'Fair Performance', //"1 - <3"
          },
          {
            minValue: 80,
            maxValue: 1000000,
            symbol: new SimpleLineSymbol({ color: '#92D050', width: 3 }),
            label: 'Good Performance',
          },
        ],
      });
    } else if (selectedDropDownValue === 'Bridge') {
      return new ClassBreaksRenderer({
        field: fieldValue,
        valueExpressionTitle: 'Bridge',
        defaultSymbol: new SimpleLineSymbol({ color: '#6C757D', width: 3 }),
        classBreakInfos: [
          {
            minValue: 0,
            maxValue: 5,
            symbol: new SimpleLineSymbol({ color: '#FF0000', width: 3 }),
            label: 'Poor Performance',
          },
          {
            minValue: 5,
            maxValue: 6.5,
            symbol: new SimpleLineSymbol({ color: '#FEC000', width: 3 }),
            label: 'Fair Performance', //"1 - <3"
          },
          {
            minValue: 6.5,
            maxValue: 1000000,
            symbol: new SimpleLineSymbol({ color: '#92D050', width: 3 }),
            label: 'Good Performance',
          },
        ],
      });
    } else if (selectedDropDownValue === 'Mobility') {
      return new ClassBreaksRenderer({
        field: fieldValue,
        valueExpressionTitle: 'Mobility',
        defaultSymbol: new SimpleLineSymbol({ color: '#6C757D', width: 3 }),
        classBreakInfos: [
          {
            minValue: 0,
            maxValue: Facility_T === 'Rural' ? 0.56 : 0.71,
            symbol: new SimpleLineSymbol({ color: '#92D050', width: 3 }),
            label: 'Poor Performance',
          },
          {
            minValue: Facility_T === 'Rural' ? 0.56 : 0.71,
            maxValue: Facility_T === 'Rural' ? 0.76 : 0.89,
            symbol: new SimpleLineSymbol({ color: '#FEC000', width: 3 }),
            label: 'Fair Performance', //"1 - <3"
          },
          {
            minValue: Facility_T === 'Rural' ? 0.76 : 0.89,
            maxValue: 1000000,
            symbol: new SimpleLineSymbol({ color: '#FF0000', width: 3 }),
            label: 'Good Performance',
          },
        ],
      });
    } else if (selectedDropDownValue === 'Safety') {
      const isUrban = Facility_T === 'Urban';
      const isRural = Facility_T === 'Rural';
      const isSuburban = Facility_T === 'Suburban';

      const highwayValues_1 = {
        IH: { rural: 2.65, urban: 2.56, suburban: 3.27 },
        US: { rural: 5.48, urban: 4.52, suburban: 5.94 },
        SH: { rural: 7.11, urban: 5.08, suburban: 9.12 },
        FM: { rural: 9.87, urban: 7.06, suburban: 8.34 },
        RM: { rural: 9.87, urban: 7.06, suburban: 8.34 },
      };
      const highwayValues_2 = {
        IH: { rural: 3.98, urban: 3.84, suburban: 4.9 },
        US: { rural: 8.21, urban: 6.78, suburban: 8.92 },
        SH: { rural: 10.66, urban: 7.62, suburban: 13.69 },
        FM: { rural: 14.08, urban: 11.39, suburban: 12.51 },
        RM: { rural: 14.08, urban: 11.39, suburban: 12.51 },
      };
      const max_1 = isUrban
        ? highwayValues_1[HWY]?.urban ?? 2.56
        : isRural
        ? highwayValues_1[HWY]?.rural ?? 2.56
        : isSuburban
        ? highwayValues_1[HWY]?.suburban ?? 2.56
        : 3.98;
      const max_2 = isUrban
        ? highwayValues_2[HWY]?.urban ?? 2.56
        : isRural
        ? highwayValues_2[HWY]?.rural ?? 2.56
        : isSuburban
        ? highwayValues_2[HWY]?.suburban ?? 2.56
        : 3.98;

      return new ClassBreaksRenderer({
        field: fieldValue,
        valueExpressionTitle: 'Safety',
        defaultSymbol: new SimpleLineSymbol({ color: '#6C757D', width: 3 }),
        classBreakInfos: [
          {
            minValue: 0,
            maxValue: max_1,
            symbol: new SimpleLineSymbol({ color: '#92D050', width: 3 }),
            label: 'Poor Performance',
          },
          {
            minValue: max_1,
            maxValue: max_2,
            symbol: new SimpleLineSymbol({ color: '#FEC000', width: 3 }),
            label: 'Fair Performance', //"1 - <3"
          },
          {
            minValue: max_2,
            maxValue: 1000000,
            symbol: new SimpleLineSymbol({ color: '#FF0000', width: 3 }),
            label: 'Good Performance',
          },
        ],
      });
    } else if (selectedDropDownValue === 'Freight') {
      const I_U = segmentData?.attributes?.I_U;
      const isUrban = Facility_T === 'Urban';
      const isRural = Facility_T === 'Rural';

      const I_U_1 = {
        I: { rural: 0.04, urban: 0.03 },
        UI: { rural: 0.1, urban: 0.14 },
      };
      const I_U_2 = {
        I: { rural: 0.88, urban: 1.27 },
        UI: { rural: 0.52, urban: 1.27 },
      };

      const max_1 = isUrban ? I_U_1[I_U]?.urban ?? 0.03 : isRural ? I_U_1[I_U]?.rural ?? 0.04 : 0.03;
      const max_2 = isUrban ? I_U_2[I_U]?.urban ?? 1.27 : isRural ? I_U_2[I_U]?.rural ?? 0.88 : 0.14;

      return new ClassBreaksRenderer({
        field: fieldValue,
        valueExpressionTitle: 'Freight',
        defaultSymbol: new SimpleLineSymbol({ color: '#6C757D', width: 3 }),
        classBreakInfos: [
          {
            minValue: 0,
            maxValue: max_1,
            symbol: new SimpleLineSymbol({ color: '#92D050', width: 3 }),
            label: 'Poor Performance',
          },
          {
            minValue: max_1,
            maxValue: max_2,
            symbol: new SimpleLineSymbol({ color: '#FEC000', width: 3 }),
            label: 'Fair Performance', //"1 - <3"
          },
          {
            minValue: max_2,
            maxValue: 1000000,
            symbol: new SimpleLineSymbol({ color: '#FF0000', width: 3 }),
            label: 'Good Performance',
          },
        ],
      });
    } else {
      return new ClassBreaksRenderer({
        field: fieldValue,
        valueExpressionTitle: 'Overall',
        defaultSymbol: new SimpleLineSymbol({ color: '#6C757D', width: 3 }),
        classBreakInfos: [
          {
            minValue: 0,
            maxValue: 0.5,
            symbol: new SimpleLineSymbol({ color: '#f8f9fa', width: 3 }),
            label: 'No need',
          },
          {
            minValue: 0.5,
            maxValue: 1.5,
            symbol: new SimpleLineSymbol({ color: '#D9F1FD', width: 3 }),
            label: 'Low Need Segments', //"1 - <3"
          },
          {
            minValue: 1.5,
            maxValue: 2,
            symbol: new SimpleLineSymbol({ color: '#81B4E1', width: 3 }),
            label: 'Medium Need Segments',
          },
          {
            minValue: 2,
            maxValue: 1000000,
            symbol: new SimpleLineSymbol({ color: '#25306E', width: 3 }),
            label: 'High Need Segments',
          },
        ],
      });
    }
  }
};

export const getCorridorLayerUrl = selectedMode => {
  console.log('selectedMode= ', selectedMode);
  if (selectedMode === 'Statewide') {
    return CORRIDOR_FEATURE_SERVICE_URL;
  } else {
    return CORRIDOR_FEATURE_SERVICE_URL_HOUSTON;
  }
};

export const getTrackingLayerUrl = selectedMode => {
  if (selectedMode === 'District') {
    return PERFORMANCE_TRACKING_SEGMENTS_URL_DISTRICTS;
  } else {
    return PERFORMANCE_TRACKING_SEGMENTS_URL;
  }
};

export const  flattenObject =(obj) => {
    const flattened = {};

    function flatten(obj, path = '') {
        Object.entries(obj).forEach(([key, value]) => {
            const newPath = path ? `${path}.${key}` : key;
            if (typeof value === 'object' && value !== null) {
                flatten(value, newPath);
            } else {
                flattened[newPath] = value;
            }
        });
    }

    flatten(obj);
    return flattened;
}

export const findMinMaxMileposts = (dataArray) => {
  if (dataArray.length === 0) {
    return { minBegin: null, maxEnd: null };
  }

  let minBegin = dataArray[0].Begin_Milepost;
  let maxEnd = dataArray[0].End_Milepost;

  for (const item of dataArray) {
    minBegin = Math.min(minBegin, item.Begin_Milepost);
    maxEnd = Math.max(maxEnd, item.End_Milepost);
  }

  return { minBegin, maxEnd };
}