import { useMemo, useCallback } from 'react';

import {
  filterBySearchTerm,
  routeOrder,
  filterRecordsByCustomTrue,
  filterRecordsByCustomFalse,
} from '../../../../Utils/SelectViewHelper';
import { useSegmentEvalutionStore } from '../../../../store/segment-evaluation/store';
import { useCorridorSelectionContext } from '../Context/CorridorSelectionContext';
import { ROADWAY_CLASSIFICATIONS } from '../../../../Utils/Constants';

import FitlerChecker from './FilterChecker';
import FilterSelector from './FilterSelector';
import ListComponent from './ListComponent';

function CorridorTreeList() {
  const {
    setSelectedCorridors,
    selectedCorridors,
    selectedSegments,
    filters,
    selectViewDataTemp,
    setSelectedSegments,
    setSelectAllCorridors,
  } = useCorridorSelectionContext();
  const { selectedMode, selectedDistrict, selectedMPO } = useSegmentEvalutionStore(state => state);

  const filteredSelectViewData = useMemo(() => {
    const selectedRouteTypes = filters.routeTypes.map(r => r.value);
    const includeOthers = selectedRouteTypes.includes('OTHERS');

    let filteredSelectViewData = {};
    for (const [corridorKey, corridorValue] of Object.entries(selectViewDataTemp)) {
      const corridorType = corridorKey.slice(0, 2);

      // Skip corridors that are not in the selected types unless 'OTHERS' is selected
      if (!selectedRouteTypes.includes(corridorType)) {
        continue;
      }

      // Apply additional filtering if there's a search term
      if (filters?.search && !filterBySearchTerm({ HWY: corridorKey }, filters?.search)) {
        continue;
      }

      // Add the corridor to the filtered data
      filteredSelectViewData[corridorKey] = corridorValue;
    }

    if (includeOthers) {
      const corridorTypes = ROADWAY_CLASSIFICATIONS.map(r => r.value);
      const othersCorridors = Object.keys(selectViewDataTemp).filter(
        corridorKey =>
          !selectedRouteTypes.includes(corridorKey.slice(0, 2)) && !corridorTypes.includes(corridorKey.slice(0, 2)),
      );
      // Add 'OTHERS' corridors to the filtered data
      othersCorridors.forEach(corridorKey => {
        filteredSelectViewData[corridorKey] = selectViewDataTemp[corridorKey];
      });
    }

    // Apply custom filtering based on the 'custom' value
    if (filters?.custom?.value === 1) {
      return filterRecordsByCustomTrue(filteredSelectViewData);
    } else if (filters?.custom?.value === 0) {
      return filterRecordsByCustomFalse(filteredSelectViewData);
    }
    return filteredSelectViewData;
  }, [filters, selectViewDataTemp, selectedMode, selectedDistrict, selectedMPO]);

  const addOrRemoveSelectedSegment = useCallback(
    segmentId => {
      const tempArr = [...selectedSegments];
      const checked = tempArr.find(s => s === segmentId);
      if (!checked) {
        if (selectedSegments.indexOf(segmentId) === -1) {
          tempArr.push(segmentId);
        }
        setSelectedSegments(tempArr);
      } else {
        setSelectedSegments(tempArr.filter(s => s !== segmentId));
      }
    },
    [selectedSegments],
  );

  const addOrRemoveSelectedCorridor = useCallback(
    segmentId => {
      const tempArr = [...selectedCorridors];
      const checked = tempArr.find(s => s === segmentId);
      if (!checked) {
        if (selectedCorridors.indexOf(segmentId) === -1) {
          tempArr.push(segmentId);
        }
        setSelectedCorridors(tempArr);
      } else {
        setSelectedCorridors(tempArr.filter(s => s !== segmentId));
      }
    },
    [selectedCorridors],
  );

  const onCorridorChecked = useCallback(
    event => {
      // implement corridor check functionality here
      const corridor_id = event.target.id;
      const checked = event.target.checked;
      const HWY = event.target.name;
      const segmentsArr = [];
      const allCorridorsCheckboxes = [];
      Object.values(filteredSelectViewData || {}).forEach(segments => {
        if (segments.length === 1) allCorridorsCheckboxes.push(segments[0].Corridor_ID);

        segments?.forEach(sc => {
          if (sc?.Corridor_ID?.includes(event.target.id) && sc.HWY === HWY) {
            segmentsArr.push(sc.Corridor_ID);
          }
        });
      });
      // toggle 'select all corridors' checkbox
      let allCorridorsChecked = true;
      allCorridorsCheckboxes.forEach(sc => {
        if (!sc.checked) {
          allCorridorsChecked = false;
          return;
        }
      });
      setSelectAllCorridors(allCorridorsChecked);

      if (segmentsArr.length > 0) {
        if (checked) setSelectedSegments([...selectedSegments, ...segmentsArr]);
        else setSelectedSegments([...selectedSegments.filter(s => !segmentsArr.includes(s))]);
      }

      addOrRemoveSelectedCorridor(corridor_id);
    },
    [addOrRemoveSelectedCorridor, filteredSelectViewData, selectedSegments],
  );

  const onSegmentChecked = useCallback(
    event => {
      // implement corridor check functionality here
      const segment_id = event.target.id;
      const checked = event.target.checked;
      const corridorKey = segment_id.substring(0, segment_id.indexOf('_'));
      if (!corridorKey) return;

      // toggle parent checkbox
      let allSiblingsChecked = true;
      const siblingsCheckboxes = [];

      Object.values(filteredSelectViewData || {}).forEach(segments => {
        segments?.forEach(sc => {
          if (sc.Corridor_ID.includes(`${corridorKey}_`) > 0) siblingsCheckboxes.push(sc.Corridor_ID);
        });
      });

      const selected = [...selectedSegments];
      if (checked) selected.push(segment_id);
      else selected.splice(selected.indexOf(segment_id), 1);

      siblingsCheckboxes.forEach(sc => {
        if (!selected.includes(sc)) {
          allSiblingsChecked = false;
          return;
        }
      });

      if (allSiblingsChecked) {
        setSelectedCorridors([...selectedCorridors, corridorKey]);
      } else {
        setSelectedCorridors([...selectedCorridors.filter(sc => sc !== corridorKey)]);
      }

      addOrRemoveSelectedSegment(segment_id);
    },
    [addOrRemoveSelectedSegment, filteredSelectViewData, selectedCorridors, selectedSegments],
  );

  const corridors = useMemo(() => {
    const tempArr = [];
    function customSort(arr) {
      return arr.sort((a, b) => {
        const orderA = routeOrder[a.corridorKey.slice(0, 2)] || Infinity;
        const orderB = routeOrder[b.corridorKey.slice(0, 2)] || Infinity;
        return orderA - orderB;
      });
    }
    if (filteredSelectViewData) {
      for (const [corridorKey, corridorSegments] of Object.entries(filteredSelectViewData)) {
        corridorSegments?.sort((a, b) => (a.Begin_Milepost < b.Begin_Milepost ? -1 : 1));

        tempArr.push({
          corridorKey,
          component: (
            <ListComponent
              corridorKey={corridorKey}
              corridorSegments={corridorSegments}
              actions={{
                onCorridorChecked,
                onSegmentChecked,
              }}
              selectedCorridors={selectedCorridors}
              selectedSegments={selectedSegments}
              isCustom={filteredSelectViewData[corridorKey][0]?.Custom === 1}
            />
          ),
        });
      }
    }
    return customSort(tempArr).map(c => c.component);
  }, [filteredSelectViewData, onCorridorChecked, onSegmentChecked, selectedCorridors, selectedSegments]);

  return (
    <div className="container mt-3">
      <FilterSelector />
      <div id="corridors-selection-container">
        <FitlerChecker filteredSelectViewData={filteredSelectViewData} />
        <span id="dynamic-corridors-container">{corridors}</span>
      </div>
    </div>
  );
}

export default CorridorTreeList;
