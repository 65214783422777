import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TableExport.css';
import { generateCSV } from '../../Utils/TableExportHelper';
import { useGlobalContext } from '../../Context/GlobalContext';

const TableExport = ({ data }) => {
  const navigate = useNavigate();
  const opts = {
    CSV: 1,
    SUMMARY_REPORT: 2,
  };
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const { globalObject, setSummaryReportData } = useGlobalContext();

  const handleShowDropdown = () => setDropdownVisible(true);
  const handleCloseDropdown = () => setDropdownVisible(false);
  const handleGenerateExport = () => {
    switch (true) {
      case selectedOption == opts.CSV:
        generateCSV(globalObject);
        break;
      case selectedOption == opts.SUMMARY_REPORT: {
        const reformattedData = data.map(item => {
          return {
            ...item,
            Route: item.Route.HWY,
          };
        });
        setSummaryReportData(reformattedData);
        navigate('/summary-report-preview');
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <button className="export-table-button" onClick={handleShowDropdown}>
        <i className="fa-solid fa-download"></i>
        Export
      </button>
      <div id="export-table-dropdown" className={dropdownVisible ? 'show' : ''}>
        <div className="title">
          EXPORT
          <i id="close-export-table-dropdown" onClick={handleCloseDropdown} className="fa-solid fa-x"></i>
        </div>
        <div id="select-export-format-container">
          <div id="select-export-format-label">FORMAT:</div>
          <select id="select-export-format" onChange={e => setSelectedOption(e.target.value)}>
            <option value="">Select Format</option>
            <option value={opts.CSV}>CSV</option>
            <option value={opts.SUMMARY_REPORT}>Summary Report</option>
          </select>
        </div>
        <button id="generate-export-button" onClick={handleGenerateExport} disabled={selectedOption == 0}>
          Export
        </button>
      </div>
    </>
  );
};

export default TableExport;
