import { getColorCode, colorCodeMapper, numberFormatter, convertToPercentage } from '../../../../helper';

import BoxElement from '../BoxElement';

function PaymentIndex({ segmentData }) {
  const Bound_1_B1 = segmentData?.attributes?.Bound_1_B1;
  const Bound_2_B2 = segmentData?.attributes?.Bound_2_B2;

  const attributesToMap = {
    Pavement_I: {
      value: numberFormatter(segmentData?.attributes?.Pavement_I),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Pavement_I),
        colorCodeMapper['Pavement']['Pavement_I'],
      ),
    },
    Distress_1: {
      value: numberFormatter(segmentData?.attributes?.Distress_1),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Distress_1),
        colorCodeMapper['Pavement']['Distress_1'],
      ),
    },
    Distress_2: {
      value: numberFormatter(segmentData?.attributes?.Distress_2),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Distress_2),
        colorCodeMapper['Pavement']['Distress_2'],
      ),
    },
    Ride_Sc_1: {
      value: numberFormatter(segmentData?.attributes?.Ride_Sc_1),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Ride_Sc_1),
        colorCodeMapper['Pavement']['Ride_Sc_1'],
      ),
    },
    Ride_Sc_2: {
      value: numberFormatter(segmentData?.attributes?.Ride_Sc_2),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Ride_Sc_2),
        colorCodeMapper['Pavement']['Ride_Sc_2'],
      ),
    },
    Frontage_1: {
      value: numberFormatter(segmentData?.attributes?.Frontage_1),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Frontage_1),
        colorCodeMapper['Pavement']['Frontage_1'],
      ),
    },
    Frontage_2: {
      value: numberFormatter(segmentData?.attributes?.Frontage_2),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Frontage_2),
        colorCodeMapper['Pavement']['Frontage_2'],
      ),
    },
    Sub_Standa: {
      value: convertToPercentage(segmentData?.attributes?.Sub_Standa),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Sub_Standa) * 100,
        colorCodeMapper['Pavement']['Sub_Standa'],
      ),
    },
  };

  return (
    <div className="indicator-rectengle-section mx-3 d-flex justify-content-start align-items-center pb-1 position-relative">
      <div className="indicator-box d-flex justify-content-center align-items-center flex-column gap-2">
        <div className="indicator-img">
          <img className="card-img-top" src="/assets/svg/Pavement.svg" alt="Pavement" />
        </div>
        <div className="indicator-type fw-bold">Pavement Index</div>
        <div className="indicator-value" style={{ color: attributesToMap['Pavement_I'].colorCode }}>
          {attributesToMap['Pavement_I'].value}
        </div>
      </div>
      <div className="indicator-side-detail d-flex justify-content-start align-items-center position-absolute">
        <BoxElement
          description="Existing Mainlane Peak-hour V/C"
          value1={attributesToMap['Distress_1'].value}
          valueTitle1={Bound_1_B1}
          colorCode1={attributesToMap['Distress_1'].colorCode}
          value2={attributesToMap['Distress_2'].value}
          valueTitle2={Bound_2_B2}
          colorCode2={attributesToMap['Distress_2'].colorCode}
        />
        <BoxElement
          description="Mainlane Directional Ride Score"
          value1={attributesToMap['Ride_Sc_1'].value}
          valueTitle1={Bound_1_B1}
          colorCode1={attributesToMap['Ride_Sc_1'].colorCode}
          value2={attributesToMap['Ride_Sc_2'].value}
          valueTitle2={Bound_2_B2}
          colorCode2={attributesToMap['Ride_Sc_2'].colorCode}
        />
        <BoxElement
          description="Frontage Road Condition Score"
          value1={attributesToMap['Frontage_1'].value}
          colorCode1={attributesToMap['Frontage_1'].colorCode}
          valueTitle1={Bound_1_B1}
          value2={attributesToMap['Frontage_2'].value}
          valueTitle2={Bound_2_B2}
          colorCode2={attributesToMap['Frontage_2'].colorCode}
        />
        <BoxElement
          description="Substandard Pavement"
          value1={attributesToMap['Sub_Standa'].value}
          colorCode1={attributesToMap['Sub_Standa'].colorCode}
        />
      </div>
    </div>
  );
}

export default PaymentIndex;
