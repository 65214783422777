import { useNavigate } from 'react-router-dom';

import { useGlobalContext } from '../../../Context/GlobalContext';
import { localStorageSetWithExpiry } from '../../../Utils/LocalStorage';
import { useSegmentEvalutionStore } from '../../../store/segment-evaluation/store';
import { useCorridorSelectionContext } from './Context/CorridorSelectionContext';

function ActionButtons({ onReset }) {
  const { fetchMapData, resetGlobalObject } = useGlobalContext();
  const { selectedMode, selectedDistrict, selectedMPO } = useSegmentEvalutionStore(state => state);
  const { selectedSegments } = useCorridorSelectionContext();
  const navigate = useNavigate();

  const shouldDisable = selectedSegments.length < 1;

  const onCompare = async () => {
    await resetGlobalObject();
    localStorage.removeItem('globalObject');
    localStorage.removeItem('customWeights');
    localStorage.removeItem('customFilters');
    localStorage.removeItem('selectedDdOptionsIds');
    localStorageSetWithExpiry('selectedSegments', selectedSegments, 60);
    await fetchMapData(selectedMode, selectedDistrict, selectedMPO);
    navigate('/map-view');
  };

  const onPerformanceTracking = async () => {
    await resetGlobalObject();
    localStorage.removeItem('globalObject');
    localStorage.removeItem('customWeights');
    localStorage.removeItem('customFilters');
    localStorageSetWithExpiry('selectedSegments', selectedSegments, 60);
    await fetchMapData(selectedMode, selectedDistrict, selectedMPO);
    navigate('/performance-tracking');
  };

  return (
    <div className="buttons-container fixed-container">
      <button className="reset-button" onClick={onReset}>
        Reset
      </button>
      <button id="compare-btn" className="compare-button" onClick={onCompare} disabled={shouldDisable}>
        Corridor Assessment Results
      </button>
      {!shouldDisable && (
        <button
          id="performance-tracking-btn"
          className="compare-button view-performance-tracking"
          onClick={onPerformanceTracking}
          disabled={shouldDisable}
        >
          View Trend
        </button>
      )}
    </div>
  );
}

export default ActionButtons;
