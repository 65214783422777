/* eslint-disable react-hooks/exhaustive-deps */
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { getTrackingLayerUrl } from "../Utils/Functions";
import { getOverallScore } from "../Utils/CorridorRankingHelper";
import {
  localStorageGetWithExpiry,
  localStorageSetWithExpiry,
} from "../Utils/LocalStorage";
import { useAuth } from "./AuthContext";
import { ROADWAY_CLASSIFICATIONS } from "../Utils/Constants";

export const GlobalContext = createContext();

export function GlobalProvider({ children }) {
  const [summaryReportData, setSummaryReportData] = useState(null);
  const [globalObject, setGlobalObject] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [performanceTrackingData, setPerformanceTrackingData] = useState(null);
  const [districtFilterSW, setDistrictFilterSW] = useState("");
  const { authState, authenticatedAxios, userName } = useAuth();
  const API_URL = process.env.REACT_APP_API_URL;

  const updateGlobalObject = async (
    selectedMode,
    selectedDistrict,
    selectedMPO
  ) => {
    try {
      console.log("updating global object.../n");
      console.log("  >> selectedMode", selectedMode);
      console.log("  >> selectedDistrict", selectedDistrict);
      console.log("  >> selectedMPO", selectedMPO);

      if (!authState.isAuthenticated) {
        return;
      }

      const selectedSegments = localStorageGetWithExpiry("selectedSegments");
      const response = await authenticatedAxios.post(
        API_URL + `/cptresultbyuser`,
        {
          selectedSegments: null,
          selectedMode: selectedMode,
          selectedDistrict: selectedDistrict,
          selectedMPO: selectedMPO,
          UserName: userName,
        }
      );

      // localStorageSetWithExpiry("globalObject", response.data, 60);
      const data = response.data.filter((sc) => {
        if (selectedSegments?.length > 0) {
          return selectedSegments.includes(sc.Corridor_ID);
        } else {
          return sc;
        }
      });

      setGlobalObject(data);
      setOriginalData(response.data);

      const performanceTrackerLayer = new FeatureLayer({
        url: getTrackingLayerUrl(selectedMode),
        outFields: ["*"],
        title: "Performance Layer",
      });
  
      const parcelQuery = {
        where: (selectedMode === "District") ? `Districts = '${selectedDistrict}'` : `NOT ID = ''`,
        outFields: ["*"],
        returnGeometry: true,
      };

      const performanceTrackingResult = await performanceTrackerLayer
        ?.queryFeatures(parcelQuery)
        .then((results) => {
          return results.features.map((corridor) => {
            return { ...corridor.attributes };
          });
        });

      // console.log("performanceTrackingResult",performanceTrackingResult)
      // localStorageSetWithExpiry("performanceTrackingData", performanceTrackingResult, 60);
      setPerformanceTrackingData(
        performanceTrackingResult
          .filter((item) => {
            return data.map((g) => g["Corridor_ID"]).includes(item["ID"]);
          })
          .map((item) => {
            return {
              ...item,
              "Overall Score": { __value: getOverallScore(item).toFixed(2) },
            };
          })
          .sort((a, b) => {
            return parseFloat(a?.["Overall Score"]?.__value) <
              parseFloat(b?.["Overall Score"]?.__value)
              ? 1
              : -1;
          })
      );
    } catch (error) {
      throw new Error(error);
    }
  };

  const fetchMapData = useCallback(
    (selectedMode, selectedDistrict, selectedMPO) => {
      // const cachedGlobalObject = localStorageGetWithExpiry("globalObject");
      // const cachedPerformanceTrackingData = localStorageGetWithExpiry(
      //   "performanceTrackingData"
      // );

      // if (cachedGlobalObject && cachedPerformanceTrackingData) {
      //   const selectedSegments = localStorageGetWithExpiry("selectedSegments");

      //   const data = cachedGlobalObject.filter((sc) => {
      //     if (selectedSegments?.length > 0) {
      //       return selectedSegments.includes(sc.Corridor_ID);
      //     } else {
      //       return sc;
      //     }
      //   });

      //   setGlobalObject(data);

      //   setPerformanceTrackingData(
      //     cachedPerformanceTrackingData.filter((item) => {
      //       return data.map((g) => g["Corridor_ID"]).includes(item["ID"]);
      //     })
      //   );
      // } else {
      //   updateGlobalObject(selectedMode, selectedDistrict, selectedMPO);
      // }
      updateGlobalObject(selectedMode, selectedDistrict, selectedMPO);
    },
    [authState.isAuthenticated]
  );

  const resetGlobalObject = () => {
    setGlobalObject(null);
  };

  const onReset = async (selectedMode, selectedDistrict, selectedMPO) => {
    console.log("### CALLING ON RESET from Global Context ###");
    // implement reset functionality here
    const filters = {
      search: "",
      routeTypes: ROADWAY_CLASSIFICATIONS,
    };
    localStorage.removeItem("globalObject");
    localStorage.removeItem("customWeights");
    localStorage.removeItem("customFilters");
    localStorage.removeItem("selectedSegments");
    localStorage.removeItem("selectViewData");
    resetGlobalObject();
    await updateGlobalObject(selectedMode, selectedDistrict, selectedMPO);
    fetchMapData(selectedMode, selectedDistrict, selectedMPO);
  };

  useEffect(() => {
    fetchMapData();
  }, [fetchMapData]);

  return (
    <GlobalContext.Provider
      value={{
        globalObject,
        updateGlobalObject,
        resetGlobalObject,
        fetchMapData,
        districtFilterSW,
        setDistrictFilterSW,
        performanceTrackingData,
        onReset,
        originalData,
        summaryReportData,
        setSummaryReportData,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobalContext() {
  return useContext(GlobalContext);
}
