import { useSegmentEvalutionStore } from '../../../../../store/segment-evaluation/store';

import NeedSummary from './NeedSummary';
import PerformanceSummary from './PerformanceSummary';

function Summary({ allCorridorData }) {
  const { selectedSwitchValue } = useSegmentEvalutionStore(state => state);
  const showNeed = selectedSwitchValue === 'Need';

  return (
    <>
      {showNeed ? (
        <NeedSummary allCorridorData={allCorridorData} />
      ) : (
        <PerformanceSummary allCorridorData={allCorridorData} />
      )}
    </>
  );
}

export default Summary;
