import { getColorCode, colorCodeMapper, numberFormatter, getNeedColorCode } from '../../../helper';

const headerColor = {
  textAlign: 'center',
  font: 'normal normal normal 14px Franklin Gothic',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: '1',
  backgroundColor: 'white',
  border: 'none',
  fontWeight: 'bold',
  background: '#ADB5BD 0% 0% no-repeat padding-box',
};

export const safetyColumnDetails = (segmentData, selectedSwitchValue) => {
  const isNeed = selectedSwitchValue === 'Need';
  const Facility_T = segmentData?.attributes?.Facility_T;
  const HWY = segmentData?.attributes?.HWY?.slice(0, 2);
  const Bound_1_B1 = segmentData?.attributes?.Bound_1_B1;
  const Bound_2_B2 = segmentData?.attributes?.Bound_2_B2;

  return {
    'Safety Index': {
      __label: `Safety Index`,
      __value: isNeed
        ? getNeedColorCode(segmentData?.attributes?.Safety_N).valueWord
        : numberFormatter(segmentData?.attributes?.Safety_I),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Safety_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Safety_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Safety_I),
              colorCodeMapper['Safety'][Facility_T]?.Safety_I[HWY],
            ),
      },
      __headerStyle: headerColor,
    },
    'Safety Mainlane Rates 1': {
      __label: `<div class="mainlane-heading-right">Mainlane Rates</div><br /> ${Bound_1_B1} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.ML_Rate_N1)
        : numberFormatter(segmentData?.attributes?.ML_Rate_B1),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.ML_Rate_N1).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.ML_Rate_N1).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.ML_Rate_B1),
              colorCodeMapper['Safety'][Facility_T]?.ML_Rate_B1[HWY],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'Safety Mainlane Rates 2': {
      __label: `<div class="mainlane-heading-right">Mainlane Rates</div><br /> ${Bound_2_B2} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.ML_Rate_N2)
        : numberFormatter(segmentData?.attributes?.ML_Rate_B2),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.ML_Rate_N2).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.ML_Rate_N2).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.ML_Rate_B2),
              colorCodeMapper['Safety'][Facility_T]?.ML_Rate_B2[HWY],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'Safety Frontage Rates': {
      __label: `Frontage Road Rate `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Front_Rate_N)
        : numberFormatter(segmentData?.attributes?.Front_Rate),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Front_Rate_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Front_Rate_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Front_Rate),
              colorCodeMapper['Safety'][Facility_T]?.Front_Rate,
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
  };
};
