import React from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

const PrivateRoute = ({ path, element }) => {
  const { authState, isInitialized } = useAuth(); // Get isInitialized from context

  if (!isInitialized) {
    return null; // You can return a loader or something else while initializing
  }

  if (!authState.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
