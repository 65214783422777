import React, { useState, useMemo } from 'react';

import { DROPDOWN_LIST } from '../../../../../Utils/Constants';
import { useSegmentEvalutionStore } from '../../../../../store/segment-evaluation/store';

function DropDown() {
  const [showDropDown, setShowDropDown] = useState(false);
  const { selectedDropDownValue, setSelectedDropDownValue, selectedSwitchValue } = useSegmentEvalutionStore(
    state => state,
  );

  const shouldShowOverall = selectedSwitchValue === 'Need';

  const handleClickDropOption = value => {
    setSelectedDropDownValue(value);
    setShowDropDown(false);
  };

  const memorizedList = useMemo(() => {
    return shouldShowOverall ? DROPDOWN_LIST : DROPDOWN_LIST.slice(1);
  }, [shouldShowOverall]);

  return (
    <div className="dropdown">
      <button className="dropdown-btn" onClick={() => setShowDropDown(!showDropDown)}>
        {selectedDropDownValue}
        {showDropDown ? <i className="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
      </button>
      <div className={`dropdown-content ${showDropDown ? 'dropdown-show' : ''}`}>
        {memorizedList.map((item, index) => (
          <div className="list" key={index} onClick={() => handleClickDropOption(item?.label)}>
            <span className="img">
              <img className="card-img-top" src={item?.img_url} alt={item?.value} />
            </span>
            <span>{item?.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DropDown;
