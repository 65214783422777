import React, { useState } from 'react';
import './Home.css';
import { Link, NavLink } from 'react-router-dom';
import TabContainer from '../../Components/Tabs/TabContainer';
import { useAuth } from '../../Context/AuthContext';

const Home = () => {
  const { authState } = useAuth();

  return (
    <div className="container-fluid">
      <div className="banner">
        <div className="panel-container">
          <div className="panel">
            <div className="h1">About Corridor Planning Tools</div>
            <p>
              The Corridor Planning Tools utilize a performance-based, data-driven analysis that conducts a consistent,
              transparent, and impartial on-system roadway evaluation to assess corridor needs for TxDOT. It employs a
              custom application developed to support TXDOT’s long-range planning process. This GIS web-based system
              utilizes performance metrics based upon GIS data to conduct evaluations on existing corridors, as well as
              detailed segment analysis on roadway segments. The Corridor Planning Tools evaluate existing corridors,
              not specific projects.
            </p>
            <NavLink className="btn btn-lg" to={authState.isAuthenticated ? '/select-corridors' : '/login'}>
              Get Started
            </NavLink>
          </div>
        </div>
      </div>

      <div className="banner-description">
        <p>
          <span className="orange">Corridor Planning Tools</span> help identify the{' '}
          <span className="orange">greatest needs</span> on our transportation corridors, as well as collaborate with
          districts, building those essential partnerships to fulfill our mission of{' '}
          <span className="orange">Connecting You with Texas</span>.
        </p>
      </div>

      <div className="details">
        <div className="usage">
          <div className="border-left-title h1">How do you use Corridor Planning Tools?</div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 mt-3">
              <div className="card mx-auto">
                <img src="assets/img/MapViewScreenshot.png" alt="Corridor Assessment" />
                <div className="title">Corridor Assessment</div>
                <div className="description">
                  Assess corridors that are generally <span className="orange">100 miles</span> in length based on
                  overall corridor performance
                </div>
                <Link to={authState.isAuthenticated ? '/select-corridors' : '/login'}>
                  <button>Begin Assessment</button>
                </Link>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mt-3">
              <div className="card mx-auto">
                <img src="assets/img/SegmentEvaluation.jpg" alt="Segment Evaluation" />
                <div className="title">Segment Evaluation</div>
                <div className="description">
                  Evaluate corridor segments that are <span className="orange">10 miles</span> long to identify specific
                  segment needs
                </div>
                <Link to={authState.isAuthenticated ? '/segment-evaluation' : '/login'}>
                  <button>Begin Evaluation</button>
                </Link>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mt-3">
              <div className="card mx-auto">
                <img src="assets/img/PerformanceTracking.png" alt="Performance Tracking" />
                <div className="title">Performance Tracking</div>
                <div className="description">
                  Visualization tool to measure and track project analytics and results to help make informed decisions
                </div>
                <Link to={authState.isAuthenticated ? '/select-corridors' : '/login'}>
                  <button>Begin Tracking</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="principles">
          <div className="border-left-title h1">Principles</div>
          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="header-image">
                  <img src="assets/svg/Transparency.svg" alt="Safety" />
                </div>
                <div className="card-body p-0">
                  <p className="image-subtitle">Transparency</p>
                  <hr />
                  <p className="details">
                    A toolkit that helps implement a transparent methodology and transferable process by leveraging
                    existing data
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="header-image">
                  <img src="assets/svg/Consistency.svg" alt="Safety" />
                </div>
                <div className="card-body p-0">
                  <p className="image-subtitle">Consistency</p>
                  <hr />
                  <p className="details">A standardized process ensures we identify the greatest areas of need</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="header-image">
                  <img src="assets/svg/Flexibility.svg" alt="Safety" />
                </div>
                <div className="card-body p-0">
                  <p className="image-subtitle">Flexibility</p>
                  <hr />
                  <p className="details">
                    Reduces data collection efforts while allowing Districts to adjust 'weights' to reflect regional
                    priorities
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="header-image">
                  <img src="assets/svg/User-Friendly.svg" alt="Safety" />
                </div>
                <div className="card-body p-0">
                  <p className="image-subtitle">User-Friendly</p>
                  <hr />
                  <p className="details">
                    A simple graphical user interface helps to communicate results and needs and supports decision
                    making
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="header-image">
                  <img src="assets/svg/Collaboration.svg" alt="Safety" />
                </div>
                <div className="card-body p-0">
                  <p className="image-subtitle">Collaboration</p>
                  <hr />
                  <p className="details">
                    A toolkit that TPP Division developed to help better understand District needs and foster a stronger
                    partnership
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="benefits">
          <div className="border-left-title h1">Benefits</div>
          <div className="subtitle">Statewide Benefits</div>
          <ul>
            <li>Data-driven, performance-based approach on selecting and evaluating corridors</li>
            <li>Consistency in identifying the greatest statewide needs for better investment decisions</li>
            <li>
              Enhance planning and programming by making better decisions earlier in the process before going into the
              annual cycle of the Unified Transportation Program (UTP), TxDOT's 10-year plan that guides the development
              of transportation projects across the state
            </li>
          </ul>
          <div className="subtitle">District Benefits</div>
          <ul>
            <li>Better define regional needs and conceptual improvements outside UTP</li>
            <li>Better scoping for future corridor studies</li>
            <li>Customize performance measures that reflect district priorities</li>
            <li>Leverage existing TxDOT datasets for decision making</li>
            <li>Support Metropolitan Planning Organization (MPO) call for projects</li>
            <li>Coordinate with local transportation partners to help refine results and identify overlooked needs</li>
          </ul>
        </div>

        <div className="schema">
          <div className="description">
            The <span className="orange">Corridor Planning Tools</span> supplement the long-term planning phase so are
            used for evaluations outside of the 10-year UTP. The tools are interconnected to provide consistent results
            and repeatable process that support performance-based planning and are used to inform strategic mobility and
            infrastructure investments across the State. <span className="orange">Corridor Assessment</span> looks at
            the on-system network and tells us which facilities are of key concern.
            <span className="orange"> Segment Evaluation</span> informs the identification of high need segments that
            may warrant further study and can potentially move into the UTP-Develop Authority to perform schematic and
            environmental study. <span className="orange">Performance Tracking</span> shows trends in Corridor
            Assessment results from past years.
          </div>
          <img src="assets/img/TxDOT ProjectDevelopProcess.png" alt="Corridor Planning Schema" />
        </div>

        <div className="measures">
          <div className="border-left-title h1 mb-5">Performance Measures</div>
          <TabContainer>
            <div label="Corridor Assessment">
              <div className="description mt-3">
                Corridor Assessment uses 23 performance metrics to identify statewide corridors of greatest needs.
              </div>
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="header-image">
                      <img src="assets/svg/Pavement.svg" alt="Pavement" />
                    </div>
                    <div className="card-body p-0">
                      <p className="image-subtitle">Pavement</p>
                      <hr />
                      <p className="details">Pavement condition</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="header-image">
                      <img src="assets/svg/Bridge.svg" alt="Bridge" />
                    </div>
                    <div className="card-body p-0">
                      <p className="image-subtitle">Bridge</p>
                      <hr />
                      <p className="details">Bridge sufficiency and percent deck area</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="header-image">
                      <img src="assets/svg/Safety.svg" alt="Safety" />
                    </div>
                    <div className="card-body p-0">
                      <p className="image-subtitle">Safety</p>
                      <hr />
                      <p className="details">Rate of fatal and incapacitating crashes and total crashes</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="header-image">
                      <img src="assets/svg/Congestion.svg" alt="Congestion" />
                    </div>
                    <div className="card-body p-0">
                      <p className="image-subtitle">Congestion</p>
                      <hr />
                      <p className="details">Volume to capacity ratios and congested corridors</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="header-image">
                      <img src="assets/svg/Economic.svg" alt="Economic" />
                    </div>
                    <div className="card-body p-0">
                      <p className="image-subtitle">Economic</p>
                      <hr />
                      <p className="details">Population & employment densities, truck volumes, and commodity flow</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="header-image">
                      <img src="assets/svg/Connectivity.svg" alt="Connectivity" />
                    </div>
                    <div className="card-body p-0">
                      <p className="image-subtitle">Connectivity</p>
                      <hr />
                      <p className="details">
                        Freight network, trunk system hurricane evacuation route, energy route, and trade corridor
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div label="Segment Evaluation">
              <div className="description mt-3">
                Segment Evaluation uses 25 performance metrics to conduct a detailed segment analysis of these
                corridors.
              </div>
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="header-image">
                      <img src="assets/svg/Pavement.svg" alt="Pavement" />
                    </div>
                    <div className="card-body p-0">
                      <p className="image-subtitle">Pavement</p>
                      <hr />
                      <p className="details">
                        Pavement index, mainlane directional and frontage road scores, substandard pavement
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="header-image">
                      <img src="assets/svg/Bridge.svg" alt="Bridge" />
                    </div>
                    <div className="card-body p-0">
                      <p className="image-subtitle">Bridge</p>
                      <hr />
                      <p className="details">Bridge index, sufficiency, and rating</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="header-image">
                      <img src="assets/svg/Congestion.svg" alt="Mobility" />
                    </div>
                    <div className="card-body p-0">
                      <p className="image-subtitle">Mobility</p>
                      <hr />
                      <p className="details">Mobility index, volume to capacity ratios, directional TTI and PTI</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="header-image">
                      <img src="assets/svg/Safety.svg" alt="Safety" />
                    </div>
                    <div className="card-body p-0">
                      <p className="image-subtitle">Safety</p>
                      <hr />
                      <p className="details">Rate of fatal and incapacitating crashes and total crashes</p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card">
                    <div className="header-image">
                      <img src="assets/svg/Economic.svg" alt="Freight" />
                    </div>
                    <div className="card-body p-0">
                      <p className="image-subtitle">Freight</p>
                      <hr />
                      <p className="details">Freight index, directional TTI and PTI, bridge load and clearance</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabContainer>
        </div>
        <div className="d-flex justify-content-center">
          <a
            class="faq-button"
            href="https://aecomarl.maps.arcgis.com/sharing/rest/content/items/17648e0bad5a49fd946ee79f70b7d10d/data"
            target="_blank"
            rel="noreferrer"
          >
            Frequently Asked Questions
          </a>
        </div>
      </div>

      <div className="footer">
        <div className="footer-body">
          <div className="logo">
            <img src="assets/svg/TXDOTlogo_blue.svg" alt="TXDOTlogo" width="100" />
          </div>
          <div className="social-icons">
            <a href="https://www.txdot.gov/about/newsroom/txdot-twitter-feeds.html" target="_blank">
              <img src="assets/svg/footer_twitter.svg" alt="footer_twitter" width="42" />
            </a>
            <a href="https://www.youtube.com/user/TxDOTpio" target="_blank">
              <img src="assets/svg/footer_youtube.svg" alt="footer_youtube" width="42" />
            </a>
            <a href="https://www.linkedin.com/company/texas-department-of-transportation" target="_blank">
              <img src="assets/svg/footer_linkedin.svg" alt="footer_linkedin" width="42" />
            </a>
            <a href="https://www.facebook.com/TxDOT" target="_blank">
              <img src="assets/svg/footer_facebook.svg" alt="footer_facebook" width="42" />
            </a>
          </div>
        </div>
        <div className="copyrights">
          Copyright {new Date().getFullYear()}, Texas Department of Transportation
          <a href="https://www.txdot.gov/about/disclaimer.html" target="_blank">
            Disclaimer
          </a>
          <a href="https://www.txdot.gov/about/accessibility-policy.html" target="_blank">
            Accessibility Policy
          </a>
          <a href="https://www.txdot.gov/about/privacy-policy.html" target="_blank">
            Website Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
