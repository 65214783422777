/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './Accordion.css';
import { findMinMaxMileposts } from '../../Utils/Functions';
import { useCorridorSelectionContext } from '../../Features/CorridorAssessment/CorridorSelection/Context/CorridorSelectionContext';
import { filterBySearchTerm } from '../../Utils/SelectViewHelper';

function CustomAccordion({
  id,
  index,
  description,
  begin,
  end,
  corridors,
  selectedOption,
  onContentChange,
  onDeleteAccordion,
  validationErrors,
  clearValidationError,
  updateCreteNewButtonState,
}) {
  const [isActive, setIsActive] = useState(true);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLabel, setselectedLabel] = useState('Select a corridor');
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const { selectViewData } = useCorridorSelectionContext();

  useEffect(() => {
    if (corridors) {
      return populateDropdown(corridors);
    }
  }, [corridors]);

  const populateDropdown = data => {
    const optionss = Object.keys(data).map(key => {
      return { ...data[key][0], label: key, value: key };
    });
    setOptions(optionss);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const filteredOptions = options.filter(
    option => filterBySearchTerm({ HWY: option.label }, searchTerm.toLowerCase()) && option,
  );

  const handleToggleAccordion = () => {
    setIsActive(!isActive);
  };
  const handleInputChange = (id, field, value) => {
    clearValidationError(id, field);
    onContentChange(id, field, value);

    if (field === 'begin') {
      updateCreteNewButtonState(isWithinRange(value, 'begin') && isWithinRange(end, 'end'));
    } else if (field === 'end') {
      updateCreteNewButtonState(isWithinRange(begin, 'begin') && isWithinRange(value, 'end'));
    } else if (field === 'description') {
      updateCreteNewButtonState(isWithinCharRange(description));
    } else {
      return;
    }
  };

  const handleSelectChange = (id, field, selectedoption) => {
    const originalData = selectViewData[selectedoption.value] ?? [];
    const { maxEnd, minBegin } = findMinMaxMileposts(originalData);
    setMax(Number(maxEnd));
    setMin(Number(minBegin));
    setselectedLabel(selectedoption.label);
    const descriptionstr = selectedoption.HWY;
    onContentChange(id, field, selectedoption.value);
    onContentChange(id, 'description', descriptionstr);
    clearValidationError(id, field);
    clearValidationError(id, 'description');
    if (isActive) toggleDropdown();
  };

  const hasError = field => {
    return validationErrors.some(error => error.fields.includes(field));
  };

  const isWithinRange = (value, field) => {
    if (value !== '' && min !== null && min !== undefined && max !== null && max !== undefined) {
      if (field === 'begin') {
        return parseFloat(value) >= parseFloat(min) && parseFloat(value) <= parseFloat(max);
      } else if (field === 'end') {
        return (
          parseFloat(value) >= parseFloat(min) &&
          parseFloat(value) <= parseFloat(max) &&
          parseFloat(value) > parseFloat(begin)
        );
      }
    }
    return true; // Default to true if value, min, or max is not set
  };
  const isWithinCharRange = value => {
    return value.length > 50 ? false : true;
  };
  const errorMessage = (value, field) => {
    if (field === 'begin') {
      if (parseFloat(value) > parseFloat(max)) {
        return "Value can't exceed maximum value.";
      } else if (parseFloat(value) < parseFloat(min)) {
        return 'Value should be greater than minimum value.';
      } else {
        return '';
      }
    } else if (field === 'end') {
      if (parseFloat(value) > parseFloat(max)) {
        return "Value can't exceed maximum value.";
      } else if (parseFloat(value) < parseFloat(begin)) {
        return 'Value should be greater than the begin value.';
      } else if (parseFloat(value) < parseFloat(min)) {
        return 'Value should be greater than minimum value.';
      } else if (parseFloat(value) === parseFloat(min)) {
        return 'Value should not be equal to minimum value.';
      } else {
        return '';
      }
    } else if (field === 'description') {
      if (value.length > 50) {
        return 'You cannot add more than 50 characters';
      }
    } else {
      return;
    }
  };

  return (
    <div className="custom-accordion mb-2">
      <div className="d-flex">
        <div
          className={`custom-accordion-header flex-fill ${isActive ? 'expanded-header' : 'collapsed-header'}`}
          onClick={handleToggleAccordion}
        >
          <div className="d-flex justify-content-between">
            <div>{'New Corridor #' + (+index + 1)}</div>
            <i className={isActive ? 'fa fa-minus' : 'fa fa-plus'} aria-hidden="true"></i>
          </div>
        </div>
        <i className="fa fa-trash-can   delete-icon" aria-hidden="true" onClick={() => onDeleteAccordion(id)}></i>
      </div>

      <div className="d-flex flex-column custom-accordion-body">
        <div className={`collapse ${isActive ? 'show' : ''}`}>
          <div className="body-form-group">
            <div className={`d-flex form-group ${hasError('selectedOption') && 'has-error'}`}>
              <label className=" corridorname body-label" htmlFor={`dropdown${id}`}>
                Corridor Name
              </label>

              <div className="dropdown">
                <button
                  className={`form-select btn single-select-dropdown ${hasError('selectedOption') && 'is-invalid'}`}
                  type="button"
                  onClick={toggleDropdown}
                >
                  <span className={selectedOption ? 'selected-option' : 'place-holder'}>
                    {selectedOption ? selectedLabel : 'Select a corridor'}
                  </span>
                </button>
                <div className={`dropdown-menu single-select-dropdown-items ${dropdownOpen ? 'show' : ''}`}>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control shadow-none single-select-dropdown-input"
                      placeholder="Search (Example: IH0010, IH10, or I-10)"
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                    <span className="input-group-text single-select-dropdown-search-icon">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="single-select-dropdown-list">
                    {filteredOptions.length > 0 ? (
                      filteredOptions.map(option => (
                        <li
                          className="single-select-dropdown-list-item"
                          key={option.value}
                          onClick={e => handleSelectChange(id, 'selectedOption', option)}
                        >
                          {option.label}
                        </li>
                      ))
                    ) : (
                      <div className="dropdown-header">No options found</div>
                    )}
                  </div>
                </div>
                {hasError('selectedOption') && (
                  <div className="invalid-feedback adjusted-invalid-feedback">Corridor Name is required.</div>
                )}
              </div>
            </div>
            <div className={`d-flex form-group relative ${hasError('begin') && 'has-error'}`}>
              <label className="begin body-label" htmlFor={`begin${id}`}>
                Begin Mile Marker
              </label>

              <input
                type="number"
                className={`form-control begin-control  ${hasError('begin') && 'is-invalid'}`}
                id={`begin${id}`}
                value={begin}
                onChange={e => handleInputChange(id, 'begin', e.target.value)}
                required
              />
              <span className="body-sidetext"> Minimum:{min === null || min === undefined ? '-' : min}</span>

              {hasError('begin') && <div className="invalid-feedback">Begin Mile Marker is required.</div>}
              {!isWithinRange(begin, 'begin') && <div className="error-message">{errorMessage(begin, 'begin')}</div>}
            </div>
            <div className={`d-flex form-group  relative ${hasError('end') && 'has-error'}`}>
              <label className=" end body-label" htmlFor={`end${id}`}>
                End Mile Marker
              </label>
              <input
                type="number"
                className={`form-control end-control ${hasError('end') && 'is-invalid'}`}
                id={`end${id}`}
                value={end}
                onChange={e => handleInputChange(id, 'end', e.target.value)}
                required
              />
              <span className="body-sidetext">Maximum: {max === null || max === undefined ? '-' : max}</span>
              {hasError('end') && <div className="invalid-feedback">End Mile Marker is required.</div>}
              {!isWithinRange(end, 'end') && <div className="error-message">{errorMessage(end, 'end')}</div>}
            </div>
            <div className={`d-flex form-group relative ${hasError('description') && 'has-error'}`}>
              <label className="description body-label" htmlFor={`description${id}`}>
                Description
              </label>
              <input
                type="text"
                className={`form-control ${hasError('description') && 'is-invalid'}`}
                id={`description${id}`}
                placeholder="Add a name"
                value={description}
                onChange={e => handleInputChange(id, 'description', e.target.value)}
                required
              />
              {hasError('description') && <div className="invalid-feedback">Description is required.</div>}
              {!isWithinCharRange(description) && (
                <div className="error-message">{errorMessage(description, 'description')}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomAccordion;
