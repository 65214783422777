import PaymentIndex from "./PaymentIndex";
import BridgeIndex from "./BridgeIndex";
import MobilityIndex from "./MobilityIndex";
import SafetyIndex from "./SafetyIndex";
import FreightIndex from "./FreightIndex";

function SegmentIndexes({ segmentData }) {
  return (
    <>
      <PaymentIndex segmentData={segmentData} />
      <BridgeIndex segmentData={segmentData} />
      <MobilityIndex segmentData={segmentData} />
      <SafetyIndex segmentData={segmentData} />
      <FreightIndex segmentData={segmentData} />
    </>
  );
}

export default SegmentIndexes;
