import { numberFormatter, getNeedColorCode } from '../../../helper';

const headerColor = {
  textAlign: 'center',
  font: 'normal normal normal 14px Franklin Gothic',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: '1',
  backgroundColor: 'white',
  border: 'none',
  fontWeight: 'bold',
  background: '#ADB5BD 0% 0% no-repeat padding-box',
};

export const overallColumnDetails = segmentData => {
  return {
    'Overall Score': {
      __label: `Overall Need`,
      __value: numberFormatter(segmentData?.attributes?.Weighted),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: getNeedColorCode(segmentData?.attributes?.Weighted).color,
        backgroundColor: getNeedColorCode(segmentData?.attributes?.Weighted).backgroundColor,
        fontWeight: 'bolder',
      },
      __headerStyle: headerColor,
    },
    'Pavement Need': {
      __label: `Pavement Need `,
      __value: numberFormatter(segmentData?.attributes?.Pavement_N),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: getNeedColorCode(segmentData?.attributes?.Pavement_N).color,
        backgroundColor: getNeedColorCode(segmentData?.attributes?.Pavement_N).backgroundColor,
      },
      __headerStyle: headerColor,
    },
    'Bridge Need': {
      __label: `Bridge Need `,
      __value: numberFormatter(segmentData?.attributes?.Bridge_N),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: getNeedColorCode(segmentData?.attributes?.Bridge_N).color,
        backgroundColor: getNeedColorCode(segmentData?.attributes?.Bridge_N).backgroundColor,
      },
      __headerStyle: headerColor,
    },
    'Mobility Need': {
      __label: `Mobility Need `,
      __value: numberFormatter(segmentData?.attributes?.Mobility_N),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: getNeedColorCode(segmentData?.attributes?.Mobility_N).color,
        backgroundColor: getNeedColorCode(segmentData?.attributes?.Mobility_N).backgroundColor,
      },
      __headerStyle: headerColor,
    },
    'Safety Need': {
      __label: `Safety Need `,
      __value: numberFormatter(segmentData?.attributes?.Safety_N),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: getNeedColorCode(segmentData?.attributes?.Safety_N).color,
        backgroundColor: getNeedColorCode(segmentData?.attributes?.Safety_N).backgroundColor,
      },
      __headerStyle: headerColor,
    },
    'Freight Need': {
      __label: `Freight Need `,
      __value: numberFormatter(segmentData?.attributes?.Freight_N),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: getNeedColorCode(segmentData?.attributes?.Freight_N).color,
        backgroundColor: getNeedColorCode(segmentData?.attributes?.Freight_N).backgroundColor,
      },
      __headerStyle: headerColor,
    },
  };
};
