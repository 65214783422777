import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isEqual } from 'lodash';
import Select from 'react-select';

import { localStorageGetWithExpiry, localStorageSetWithExpiry } from '../../../../../Utils/LocalStorage';
import { DISTRICTS, MPOs, ROADWAY_CLASSIFICATIONS, WEIGHTS_DEFAULT_VALUES } from '../../../../../Utils/Constants';

import './TableFilters.css';

const initFilters = {
  district: '',
  urbanRural: '',
  roadwayClassifications: ROADWAY_CLASSIFICATIONS,
};

const TableFilter = ({
  handleTableFilter,
  filters: filtersProp,
  customWeights: customWeightsProp,
  mapview,
  tableview,
  activeMetric,
  showValueSwitch,
  showValuesInstedOfScores,
}) => {
  const [toggleFiltersDropdown, setToggleFiltersDropDown] = useState(false);
  const [toggleWeightsDropdown, setToggleWeightsDropDown] = useState(false);
  const [filters, setFilters] = useState({ ...filtersProp });
  const [customWeights, setCustomWeights] = useState({
    ...customWeightsProp,
  });

  const [filterActive, setFilterActive] = useState(false);
  const [weightActive, setWeightActive] = useState(false);

  const [applyWeightDisabled, setApplyWeightDisabled] = useState(false);

  const onDropdownIconClick = dropdownId => {
    if (dropdownId === 'filters-dropdown') {
      setToggleFiltersDropDown(!toggleFiltersDropdown);
      setFilters(filtersProp);
    }

    if (dropdownId === 'weights-dropdown') {
      setToggleWeightsDropDown(!toggleWeightsDropdown);
      setCustomWeights(customWeightsProp);
    }
  };

  const validateClassificationFilters = selected => {
    setFilters(prev => ({ ...prev, roadwayClassifications: selected }));
  };

  const validateDistrictFilters = event => {
    const district = event.target.value;
    setFilters(prev => ({ ...prev, district }));
  };

  const validateUrbanRuralFilters = event => {
    const urbanRural = event.target.value;
    setFilters(prev => ({ ...prev, urbanRural }));
  };

  const resetFilters = () => {
    // Implement the logic for resetting filters
    setFilters(initFilters);
  };

  const checkFilters = () => {
    const localStoragefilters = localStorageGetWithExpiry('customFilters');

    let roadwayClassifications = [];
    ROADWAY_CLASSIFICATIONS.forEach(rec => {
      if (localStoragefilters?.[rec.value]) roadwayClassifications = [...roadwayClassifications, { ...rec }];
    });

    const filters = {
      district: localStoragefilters?.district || '',
      urbanRural: localStoragefilters?.urbanRural || '',
      roadwayClassifications,
    };

    const isInitial = isEqual(filters, initFilters);
    setFilterActive(!isInitial);
  };

  const applyFilters = () => {
    // Implement the logic for applying filters
    let customFilters = {};
    ROADWAY_CLASSIFICATIONS.forEach(roadway => {
      customFilters[roadway.value] = Boolean(filters.roadwayClassifications.find(rec => rec.value === roadway.value));
    });
    customFilters.district = filters?.district || '';
    customFilters.urbanRural = filters?.urbanRural || '';

    localStorageSetWithExpiry('customFilters', customFilters, 60);
    handleTableFilter('filters', filters);
    setToggleFiltersDropDown(!toggleFiltersDropdown);
    checkFilters();
  };

  useEffect(() => {
    const sum = Object.values(customWeights).reduce(
      (accumulator, currentValue) => parseFloat(accumulator + currentValue),
      0,
    );
    setApplyWeightDisabled(sum !== 100);
  }, [customWeights]);

  const validateCustomWeights = e => {
    // Implement the logic for validating custom weights
    let key = e.target.id.split('-')[0];
    setCustomWeights(p => {
      return { ...p, [key]: parseFloat(e.target.value) };
    });
  };

  const resetWeights = () => {
    // Implement the logic for resetting weights
    setCustomWeights(WEIGHTS_DEFAULT_VALUES);
  };

  const checkWeights = () => {
    const localStorageCustomWeight = localStorageGetWithExpiry('customWeights');

    const isInitial = isEqual(localStorageCustomWeight, WEIGHTS_DEFAULT_VALUES);
    setWeightActive(!isInitial);
  };

  const applyWeights = () => {
    // Implement the logic for applying weights
    handleTableFilter('weights', customWeights);
    localStorageSetWithExpiry('customWeights', customWeights, 60);
    setToggleWeightsDropDown(!toggleWeightsDropdown);
    checkWeights();
  };

  useEffect(() => {
    const localStoragefilters = localStorageGetWithExpiry('customFilters');
    const localStorageCustomWeight = localStorageGetWithExpiry('customWeights');
    if (localStoragefilters || localStorageCustomWeight) {
      if (localStoragefilters) {
        let roadwayClassifications = [];
        ROADWAY_CLASSIFICATIONS.forEach(rec => {
          if (localStoragefilters?.[rec.value]) roadwayClassifications = [...roadwayClassifications, { ...rec }];
        });
        setFilters(prev => ({ ...prev, roadwayClassifications }));
      }
      if (localStorageCustomWeight) {
        setCustomWeights(localStorageCustomWeight);
        handleTableFilter('weights', localStorageCustomWeight);
      }
    } else {
      resetFilters();
      resetWeights();
      let customFilters = {};
      ROADWAY_CLASSIFICATIONS.forEach(roadway => {
        customFilters[roadway.value] = true;
      });
      customFilters.district = '';
      customFilters.urbanRural = '';

      localStorageSetWithExpiry('customFilters', customFilters, 60);
      localStorageSetWithExpiry('customWeights', WEIGHTS_DEFAULT_VALUES, 60);
      handleTableFilter('filters', filters);
      handleTableFilter('weights', WEIGHTS_DEFAULT_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkFilters();
  }, [filters]);

  useEffect(() => {
    checkWeights();
  }, [customWeights]);

  return (
    <div>
      <div className="container bg-light-blue pt-2">
        <div>
          <div className="row">
            <div className="col-sm-6 my-1">
              <div className="d-flex">
                <div className="filters-dropdown">
                  {filterActive && (
                    <span className="position-absolute top-0 end-0 translate-middle p-1 bg-orange rounded-circle" />
                  )}
                  <div className="btn-square mx-2" onClick={() => onDropdownIconClick('filters-dropdown')}>
                    <i className="fa-solid fa-filter"></i>
                  </div>
                  <div id="filters-dropdown" className={`dropdown-content ${toggleFiltersDropdown ? 'show' : ''}`}>
                    <div className="title">
                      FILTERS
                      <i
                        id="close-filters-dropdown"
                        onClick={() => onDropdownIconClick('filters-dropdown')}
                        className="fa-solid fa-x"
                      ></i>
                    </div>
                    <div className="header">ROADWAY CLASSIFICATION:</div>
                    <div className="roadway-classification-checkboxes">
                      <div className="row">
                        <Select
                          isMulti
                          value={filters?.roadwayClassifications}
                          options={ROADWAY_CLASSIFICATIONS}
                          onChange={validateClassificationFilters}
                        />
                      </div>
                    </div>
                    <div className="header">DISTRICT:</div>
                    <select
                      id="districts-select"
                      className="filter-select"
                      value={filters?.district}
                      onChange={validateDistrictFilters}
                    >
                      <option value="">No filter</option>
                      {DISTRICTS.map(district => (
                        <option key={district} value={district}>
                          {district}
                        </option>
                      ))}
                    </select>
                    <div className="header">URBAN/RURAL:</div>
                    <select
                      id="urban-rural-select"
                      className="filter-select"
                      value={filters?.urbanRural}
                      onChange={validateUrbanRuralFilters}
                    >
                      <option value="">No filter</option>
                      {MPOs?.map(mpo => (
                        <option key={mpo} value={mpo}>
                          {mpo}
                        </option>
                      ))}
                    </select>
                    <div className="row action-buttons">
                      <div className="col-6">
                        <button className="btn-reset" onClick={resetFilters}>
                          RESET
                        </button>
                      </div>
                      <div className="col-6">
                        <button className="btn-done" onClick={applyFilters}>
                          DONE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filters-dropdown">
                  {weightActive && (
                    <span className="position-absolute top-0 end-0 translate-middle p-1 bg-orange rounded-circle" />
                  )}
                  <div className="btn-square mx-2" onClick={() => onDropdownIconClick('weights-dropdown')}>
                    <i className="fa-solid fa-scale-balanced"></i>
                  </div>
                  <div id="weights-dropdown" className={`dropdown-content ${toggleWeightsDropdown ? 'show' : ''}`}>
                    <div className="title">
                      WEIGHTS
                      <i
                        id="close-weights-dropdown"
                        onClick={() => onDropdownIconClick('weights-dropdown')}
                        className="fa-solid fa-x"
                      ></i>
                    </div>
                    <div className="row">
                      <div className="col-6 text-left">Pavement</div>
                      <div className="col-6">
                        <span className="percentage-input">
                          <input
                            type="number"
                            id="pavement-weight-input"
                            onInput={validateCustomWeights}
                            value={customWeights?.pavement}
                          />
                          <span className="percentage-sign">%</span>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-left">Bridge</div>
                      <div className="col-6">
                        <span className="percentage-input">
                          <input
                            type="number"
                            id="bridge-weight-input"
                            onInput={validateCustomWeights}
                            value={customWeights?.bridge}
                          />
                          <span className="percentage-sign">%</span>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-left">Safety</div>
                      <div className="col-6">
                        <span className="percentage-input">
                          <input
                            type="number"
                            id="safety-weight-input"
                            onInput={validateCustomWeights}
                            value={customWeights?.safety}
                          />
                          <span className="percentage-sign">%</span>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-left">Congestion</div>
                      <div className="col-6">
                        <span className="percentage-input">
                          <input
                            type="number"
                            id="congestion-weight-input"
                            onInput={validateCustomWeights}
                            value={customWeights?.congestion}
                          />
                          <span className="percentage-sign">%</span>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-left">Economic</div>
                      <div className="col-6">
                        <span className="percentage-input">
                          <input
                            type="number"
                            id="economic-weight-input"
                            onInput={validateCustomWeights}
                            value={customWeights?.economic}
                          />
                          <span className="percentage-sign">%</span>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-left">Connectivity</div>
                      <div className="col-6">
                        <span className="percentage-input">
                          <input
                            type="number"
                            id="connectivity-weight-input"
                            onInput={validateCustomWeights}
                            value={customWeights?.connectivity}
                          />
                          <span className="percentage-sign">%</span>
                        </span>
                      </div>
                    </div>
                    <div
                      id="weights-validation-error"
                      style={{
                        display: applyWeightDisabled ? 'block' : 'none',
                      }}
                    >
                      * Percentages should sum up to 100%
                    </div>
                    <div className="row action-buttons">
                      <div className="col-6">
                        <button className="btn-reset" onClick={resetWeights}>
                          RESET
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          id="weights-apply-btn"
                          className="btn-done"
                          onClick={applyWeights}
                          disabled={applyWeightDisabled}
                        >
                          DONE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="row">
          {mapview && (
            <div className="col-lg-6">
              <form className="d-flex">
                <input
                  id="search-box"
                  className="form-control me-2"
                  type="search"
                  placeholder="Search Route"
                  aria-label="Search"
                  style={{ maxWidth: '300px', borderRadius: '0' }}
                  onInput={e => handleTableFilter('search', e.target.value)}
                />
              </form>
            </div>
          )}
          {tableview && (
            <>
              <div className="col-lg-4 pt-2">
                <form className="d-flex">
                  <div className="col-12">
                    <select
                      className="form-select"
                      value={activeMetric}
                      onChange={e => handleTableFilter('metric', e.target.value)}
                    >
                      <option value="overall" selected>
                        Overall Score
                      </option>
                      <option value="pavement">Pavement Score</option>
                      <option value="bridge">Bridge Score</option>
                      <option value="safety">Safety Score</option>
                      <option value="congestion">Congestion Score</option>
                      <option value="economic">Economic Score</option>
                      <option value="connectivity">Connectivity Score</option>
                    </select>
                  </div>
                </form>
              </div>
              <div className="col-lg-3 pt-2 vertical-align-center">
                <input
                  id="switchToValues"
                  type="checkbox"
                  onChange={e => handleTableFilter('switchValueScore', e.target.checked)}
                  checked={showValuesInstedOfScores}
                  hidden={showValueSwitch}
                />
                <label htmlFor="switchToValues" hidden={showValueSwitch}>
                  Switch to values
                </label>
              </div>
            </>
          )}
          <div
            className={`col-lg-${tableview ? '5' : '6'} d-flex justify-content-end`}
            style={{ height: 'fit-content' }}
          >
            <div className="d-flex btn-toggle-container vertical-align-center">
              <Link to={'/map-view'}>
                <div className={`btn-toggle ${mapview && 'active'}`}>Map</div>
              </Link>
              <Link to="/table-view">
                <div className={`btn-toggle ${tableview && 'active'}`}>Table</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFilter;
