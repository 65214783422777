import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { DISTRICTS, ROADWAY_CLASSIFICATIONS, WEIGHTS_DEFAULT_VALUES } from '../../../../Utils/Constants';
import { localStorageGetWithExpiry, localStorageSetWithExpiry } from '../../../../Utils/LocalStorage';
import './TableFilters.css';

const initFilters = {
  district: '',
  urbanRural: '',
  roadwayClassifications: ROADWAY_CLASSIFICATIONS,
};

const TableFilter = ({
  handleTableFilter,
  filters: filtersProp,
  customWeights: customWeightsProp,
  mapview,
  tableview,
  activeMetric,
  showValueSwitch,
  showValuesInstedOfScores,
}) => {
  const [toggleFiltersDropdown, setToggleFiltersDropDown] = useState(false);
  const [toggleWeightsDropdown, setToggleWeightsDropDown] = useState(false);
  const [filters, setFilters] = useState({ ...filtersProp });
  const [customWeights, setCustomWeights] = useState({
    ...customWeightsProp,
  });

  const [filterActive, setFilterActive] = useState(false);
  const [weightActive, setWeightActive] = useState(false);
  const [applyWeightDisabled, setApplyWeightDisabled] = useState(false);

  const onDropdownIconClick = dropdownId => {
    if (dropdownId === 'filters-dropdown') {
      setToggleFiltersDropDown(!toggleFiltersDropdown);
      setFilters(filtersProp);
    }

    if (dropdownId === 'weights-dropdown') {
      setToggleWeightsDropDown(!toggleWeightsDropdown);
      setCustomWeights(customWeightsProp);
    }
  };

  const validateClassificationFilters = selected => {
    setFilters(prev => ({ ...prev, roadwayClassifications: selected }));
  };

  const validateDistrictFilters = event => {
    const district = event.target.value;
    setFilters(prev => ({ ...prev, district }));
  };

  const validateUrbanRuralFilters = event => {
    const urbanRural = event.target.value;
    setFilters(prev => ({ ...prev, urbanRural }));
  };

  // const validateCorridorFilters = (event) => {
  //   const corridor = event.target.value;
  //   setFilters((prev) => ({ ...prev, corridor }));
  // };

  const resetFilters = () => {
    // Implement the logic for resetting filters
    setFilters(initFilters);
  };

  const checkFilters = () => {
    const localStoragefilters = localStorageGetWithExpiry('customFilters');

    let roadwayClassifications = [];
    ROADWAY_CLASSIFICATIONS.forEach(rec => {
      if (localStoragefilters?.[rec.value]) roadwayClassifications = [...roadwayClassifications, { ...rec }];
    });

    const filters = {
      district: localStoragefilters?.district || '',
      urbanRural: localStoragefilters?.urbanRural || '',
      roadwayClassifications,
    };

    const isInitial = isEqual(filters, initFilters);
    setFilterActive(!isInitial);
  };

  const applyFilters = () => {
    // Implement the logic for applying filters
    let customFilters = {};
    ROADWAY_CLASSIFICATIONS.forEach(roadway => {
      customFilters[roadway.value] = Boolean(filters.roadwayClassifications.find(rec => rec.value === roadway.value));
    });
    customFilters.district = filters?.district || '';
    customFilters.urbanRural = filters?.urbanRural || '';

    localStorageSetWithExpiry('customFilters', customFilters, 60);
    handleTableFilter('filters', filters);
    setToggleFiltersDropDown(!toggleFiltersDropdown);
    checkFilters();
  };

  useEffect(() => {
    const sum = Object.values(customWeights).reduce(
      (accumulator, currentValue) => parseFloat(accumulator + currentValue),
      0,
    );
    setApplyWeightDisabled(sum !== 100);
  }, [customWeights]);

  const validateCustomWeights = e => {
    // Implement the logic for validating custom weights
    let key = e.target.id.split('-')[0];
    setCustomWeights(p => {
      return { ...p, [key]: parseFloat(e.target.value) };
    });
  };

  const resetWeights = () => {
    // Implement the logic for resetting weights
    setCustomWeights(WEIGHTS_DEFAULT_VALUES);
  };

  const checkWeights = () => {
    const localStorageCustomWeight = localStorageGetWithExpiry('customWeights');

    const isInitial = isEqual(localStorageCustomWeight, WEIGHTS_DEFAULT_VALUES);
    setWeightActive(!isInitial);
  };

  const applyWeights = () => {
    // Implement the logic for applying weights
    handleTableFilter('weights', customWeights);
    localStorageSetWithExpiry('customWeights', customWeights, 60);
    setToggleWeightsDropDown(!toggleWeightsDropdown);
    checkWeights();
  };

  useEffect(() => {
    const localStoragefilters = localStorageGetWithExpiry('customFilters');
    const localStorageCustomWeight = localStorageGetWithExpiry('customWeights');
    if (localStoragefilters || localStorageCustomWeight) {
      if (localStoragefilters) {
        let roadwayClassifications = [];
        ROADWAY_CLASSIFICATIONS.forEach(rec => {
          if (localStoragefilters?.[rec.value]) roadwayClassifications = [...roadwayClassifications, { ...rec }];
        });
        setFilters(prev => ({ ...prev, roadwayClassifications }));
      }
      if (localStorageCustomWeight) {
        setCustomWeights(localStorageCustomWeight);
        handleTableFilter('weights', localStorageCustomWeight);
      }
    } else {
      resetFilters();
      resetWeights();
      let customFilters = {};
      ROADWAY_CLASSIFICATIONS.forEach(roadway => {
        customFilters[roadway.value] = true;
      });
      customFilters.district = '';
      customFilters.urbanRural = '';
      // customFilters.corridors = corridors;

      localStorageSetWithExpiry('customFilters', customFilters, 60);
      localStorageSetWithExpiry('customWeights', WEIGHTS_DEFAULT_VALUES, 60);
      handleTableFilter('filters', filters);
      handleTableFilter('weights', WEIGHTS_DEFAULT_VALUES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkFilters();
  }, [filters]);

  useEffect(() => {
    checkWeights();
  }, [customWeights]);

  return (
    <div className="container container-table-filters">
      <div>
        <div className="row">
          <div className="col-sm-6 mt-3">
            <div className="d-flex">
              <div className="filters-dropdown">
                {filterActive && (
                  <span className="position-absolute top-0 end-0 translate-middle p-1 bg-orange rounded-circle" />
                )}
                <div className="btn-square mx-2" onClick={() => onDropdownIconClick('filters-dropdown')}>
                  <i className="fa-solid fa-filter"></i>
                </div>

                <div id="filters-dropdown" className={`dropdown-content ${toggleFiltersDropdown ? 'show' : ''}`}>
                  <div className="title">
                    FILTERS
                    <i
                      id="close-filters-dropdown"
                      onClick={() => onDropdownIconClick('filters-dropdown')}
                      className="fa-solid fa-x"
                    ></i>
                  </div>

                  <div className="header">ROADWAY CLASSIFICATION:</div>
                  <div className="roadway-classification-checkboxes">
                    <div className="row">
                      <Select
                        isMulti
                        value={filters?.roadwayClassifications}
                        options={ROADWAY_CLASSIFICATIONS}
                        onChange={validateClassificationFilters}
                      />
                    </div>
                  </div>

                  {/* <div className="header">DISTRICT:</div>
                  <select
                    id="districts-select"
                    className="filter-select"
                    value={filters?.district}
                    onChange={validateDistrictFilters}
                  >
                    <option value="">No filter</option>
                    {DISTRICTS.map((district) => (
                      <option key={district} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>

                  <div className="header">URBAN/RURAL:</div>
                  <select
                    id="urban-rural-select"
                    className="filter-select"
                    value={filters?.urbanRural}
                    onChange={validateUrbanRuralFilters}
                  >
                    <option value="">No filter</option>
                    <option value="nonMpo">Non MPO</option>
                    {MPOs?.map((mpo) => (
                      <option key={mpo} value={mpo}>
                        {mpo}
                      </option>
                    ))}
                  </select> */}

                  {/* <div className="header">ROUTE:</div>
                  <select
                    id="districts-select"
                    className="filter-select"
                    value={filters?.corridor}
                    onChange={validateCorridorFilters}
                  >
                    <option value="">Selected Corridors</option>
                    {corridors.map((corridor) => (
                      <option key={corridor} value={corridor}>
                        {corridor}
                      </option>
                    ))}
                  </select> */}

                  <div className="row action-buttons">
                    <div className="col-6">
                      <button className="btn-reset" onClick={resetFilters}>
                        RESET
                      </button>
                    </div>

                    <div className="col-6">
                      <button className="btn-done" onClick={applyFilters}>
                        DONE
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="filters-dropdown">
                {weightActive && (
                  <span className="position-absolute top-0 end-0 translate-middle p-1 bg-orange rounded-circle" />
                )}
                <div className="btn-square mx-2" onClick={() => onDropdownIconClick('weights-dropdown')}>
                  <i className="fa-solid fa-scale-balanced"></i>
                </div>

                <div id="weights-dropdown" className={`dropdown-content ${toggleWeightsDropdown ? 'show' : ''}`}>
                  <div className="title">
                    WEIGHTS
                    <i
                      id="close-weights-dropdown"
                      onClick={() => onDropdownIconClick('weights-dropdown')}
                      className="fa-solid fa-x"
                    ></i>
                  </div>

                  <div className="row">
                    <div className="col-6 text-left">Pavement</div>
                    <div className="col-6">
                      <span className="percentage-input">
                        <input
                          type="number"
                          id="pavement-weight-input"
                          onInput={validateCustomWeights}
                          value={customWeights?.pavement}
                        />
                        <span className="percentage-sign">%</span>
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 text-left">Bridge</div>
                    <div className="col-6">
                      <span className="percentage-input">
                        <input
                          type="number"
                          id="bridge-weight-input"
                          onInput={validateCustomWeights}
                          value={customWeights?.bridge}
                        />
                        <span className="percentage-sign">%</span>
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 text-left">Safety</div>
                    <div className="col-6">
                      <span className="percentage-input">
                        <input
                          type="number"
                          id="safety-weight-input"
                          onInput={validateCustomWeights}
                          value={customWeights?.safety}
                        />
                        <span className="percentage-sign">%</span>
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 text-left">Congestion</div>
                    <div className="col-6">
                      <span className="percentage-input">
                        <input
                          type="number"
                          id="congestion-weight-input"
                          onInput={validateCustomWeights}
                          value={customWeights?.congestion}
                        />
                        <span className="percentage-sign">%</span>
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 text-left">Economic</div>
                    <div className="col-6">
                      <span className="percentage-input">
                        <input
                          type="number"
                          id="economic-weight-input"
                          onInput={validateCustomWeights}
                          value={customWeights?.economic}
                        />
                        <span className="percentage-sign">%</span>
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 text-left">Connectivity</div>
                    <div className="col-6">
                      <span className="percentage-input">
                        <input
                          type="number"
                          id="connectivity-weight-input"
                          onInput={validateCustomWeights}
                          value={customWeights?.connectivity}
                        />
                        <span className="percentage-sign">%</span>
                      </span>
                    </div>
                  </div>

                  <div
                    id="weights-validation-error"
                    style={{
                      display: applyWeightDisabled ? 'block' : 'none',
                    }}
                  >
                    * Percentages should sum up to 100%
                  </div>
                  <div className="row action-buttons">
                    <div className="col-6">
                      <button className="btn-reset" onClick={resetWeights}>
                        RESET
                      </button>
                    </div>

                    <div className="col-6">
                      <button
                        id="weights-apply-btn"
                        className="btn-done"
                        onClick={applyWeights}
                        disabled={applyWeightDisabled}
                      >
                        DONE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 mt-3">
            <div className="view-trend-table-button-wrapper">
              <NavLink className="view-trend-table-button" to="/select-corridors" style={{ marginRight: '15px' }}>
                Select Corridors
              </NavLink>

              <NavLink className="view-trend-table-button" to="view-trend">
                <i className="fa-solid fa-arrow-trend-up"></i>
                View Trend
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFilter;
