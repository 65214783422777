import { useSegmentEvalutionStore } from "../../../../store/segment-evaluation/store";

function CorridorSummary({ selectedCorridorData }) {
  const { selectedCorridorId } = useSegmentEvalutionStore((state) => state);

  return (
    <div className="selected-route-detail d-flex justify-content-between align-items-center">
      <div className="selected-route-box d-flex justify-content-center align-items-left flex-column py-1 px-3 w-75">
        <span className="selected-route-name">Selected Route</span>
        <span className="selected-route-value">{selectedCorridorId}</span>
      </div>
      <div className="d-flex justify-content-center align-items-center flex-column w-50">
        <div className="selected-route-name text-center">Total Segments</div>
        <div className="selected-route-value">
          {selectedCorridorData.length}
        </div>
      </div>
    </div>
  );
}

export default CorridorSummary;
