import React, { useState } from "react";
import Tab from "./Tab";

const TabContainer = ({ children }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const handleTabClick = (tabLabel) => {
    setActiveTab(tabLabel);
  }

  return (
    <div className="tab-container">
      <div className="tab-buttons">
        {children.map((child) => (
          <Tab
            key={child.props.label}
            label={child.props.label}
            isActive={activeTab === child.props.label}
            onClick={handleTabClick}
          />
        ))}
      </div>
      <div className="tab-content">
        {children.map((child) =>
          activeTab === child.props.label ? child.props.children : null
        )}
      </div>
    </div>
  );
};

export default TabContainer;