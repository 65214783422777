import { useEffect } from 'react';

import { updateSelectViewDefinitionQuery, highlightCorridor } from '../../../Utils/MapHelpers';
import { useSegmentEvalutionStore } from '../../../store/segment-evaluation/store';
import { useCorridorSelectionContext } from './Context/CorridorSelectionContext';
import { convertMultiLineStringToGraphic } from '../../../Utils/MapHelpers';
import { useGlobalContext } from '../../../Context/GlobalContext';
import useMapView from './Hooks/useMapView';

function MapView() {
  const { mapContainerRef, corridorLayerRef, viewRef, graphicsLayerRef } = useMapView();
  const { selectedSegments } = useCorridorSelectionContext();
  const { selectedMode, selectedDistrict, selectedMPO } = useSegmentEvalutionStore(state => state);
  const { originalData } = useGlobalContext();

  const selectedCustomSegments =
    originalData?.filter(item => selectedSegments.includes(item?.Corridor_ID) && item.Custom === 1) ?? [];
  const selectedNormalSegments =
    originalData?.filter(item => selectedSegments.includes(item?.Corridor_ID) && item.Custom === 0) ?? [];

  useEffect(() => {
    async function setHighlightCorridor() {
      const selectedSegments = selectedNormalSegments.map(item => item.Corridor_ID);
      await updateSelectViewDefinitionQuery(
        selectedMode,
        selectedDistrict,
        selectedMPO,
        false,
        selectedSegments,
        corridorLayerRef.current,
      );

      if (corridorLayerRef?.current && highlightCorridor) {
        await highlightCorridor(
          corridorLayerRef?.current.definitionExpression,
          corridorLayerRef?.current,
          viewRef.current,
        );
      }
    }
    setHighlightCorridor();
  }, [selectedNormalSegments]);

  useEffect(() => {
    graphicsLayerRef.current.removeAll();
    if (selectedCustomSegments?.length > 0) {
      const graphics = [];
      selectedCustomSegments.forEach(segment => {
        const geometry = segment?.Geometry;
        const ob = JSON.parse(geometry);
        const segmentGraphics =
          ob?.features?.map(feature => {
            if (feature.geometry.type === 'MultiLineString') {
              return convertMultiLineStringToGraphic(feature, segment);
            }
          }) ?? [];
        graphics.push(...segmentGraphics);
      });

      graphicsLayerRef.current.addMany(graphics);
      setTimeout(() => {
        viewRef.current.goTo({
          target: graphicsLayerRef.current?.graphics,
          zoom: 7,
        });
      }, 1000);
    }
  }, [selectedCustomSegments]);

  return <div ref={mapContainerRef} className="map-container" style={{ height: '83vh' }}></div>;
}

export default MapView;
