import { getColorCode, colorCodeMapper, numberFormatter } from '../../../../helper';

import BoxElement from '../BoxElement';

const NEUTRAL = '#adb5bd';

function SafetyIndex({ segmentData }) {
  const Facility_T = segmentData?.attributes?.Facility_T;
  const HWY = segmentData?.attributes?.HWY?.slice(0, 2);
  const NoIC_NoFC = segmentData?.attributes?.NoIC + segmentData?.attributes?.NoFC;
  const Bound_1_B1 = segmentData?.attributes?.Bound_1_B1;
  const Bound_2_B2 = segmentData?.attributes?.Bound_2_B2;

  const attributesToMap = {
    Safety_I: {
      value: numberFormatter(segmentData?.attributes?.Safety_I),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Safety_I),
        colorCodeMapper['Safety'][Facility_T]?.Safety_I[HWY],
      ),
    },
    ML_Rate_B1: {
      value: numberFormatter(segmentData?.attributes?.ML_Rate_B1),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.ML_Rate_B1),
        colorCodeMapper['Safety'][Facility_T]?.ML_Rate_B1[HWY],
      ),
    },
    ML_Rate_B2: {
      value: numberFormatter(segmentData?.attributes?.ML_Rate_B2),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.ML_Rate_B2),
        colorCodeMapper['Safety'][Facility_T]?.ML_Rate_B2[HWY],
      ),
    },
    Front_Rate: {
      value: numberFormatter(segmentData?.attributes?.Front_Rate),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Front_Rate),
        colorCodeMapper['Safety'][Facility_T]?.Front_Rate,
      ),
    },
    NoTC: {
      value: String(numberFormatter(segmentData?.attributes?.NoTC, 1)),
      colorCode: getColorCode(numberFormatter(segmentData?.attributes?.NoTC, 0), colorCodeMapper['Safety'].NoTC),
    },
    NoIC_NoFC: {
      value: String(numberFormatter(NoIC_NoFC, 1)),
      colorCode: getColorCode(numberFormatter(NoIC_NoFC, 0), colorCodeMapper['Safety'].NoIC_NoFC),
    },
  };

  return (
    <div className="indicator-rectengle-section mx-3 d-flex justify-content-start align-items-center pb-1 position-relative">
      <div className="indicator-box d-flex justify-content-center align-items-center flex-column gap-2">
        <div className="indicator-img">
          <img className="card-img-top" src="/assets/svg/Safety.svg" alt="Pavement" />
        </div>
        <div className="indicator-type fw-bold">Safety Index</div>
        <div className="indicator-value" style={{ color: attributesToMap['Safety_I'].colorCode }}>
          {attributesToMap['Safety_I'].value}
        </div>
      </div>
      <div className="indicator-side-detail d-flex justify-content-start align-items-center position-absolute">
        <BoxElement
          description="Directional Mainlane Rates"
          value1={attributesToMap['ML_Rate_B1'].value}
          valueTitle1={Bound_1_B1}
          colorCode1={attributesToMap['ML_Rate_B1'].colorCode}
          value2={attributesToMap['ML_Rate_B2'].value}
          valueTitle2={Bound_2_B2}
          colorCode2={attributesToMap['ML_Rate_B2'].colorCode}
        />
        <BoxElement
          description="Frontage Road Rate"
          value1={attributesToMap['Front_Rate'].value}
          colorCode1={attributesToMap['Front_Rate'].colorCode}
        />
        <BoxElement description="Total Crashes" value1={attributesToMap['NoTC'].value} colorCode1={NEUTRAL} />
        <BoxElement description="K&A Crashes" value1={attributesToMap['NoIC_NoFC'].value} colorCode1={NEUTRAL} />
      </div>
    </div>
  );
}

export default SafetyIndex;
