import { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOverallScore } from '../../../Utils/CorridorRankingHelper';
import { useGlobalContext } from '../../../Context/GlobalContext';
import MultiSelectComponent from '../../../Components/MultiSelectDropDown/MultiSelectComponent';
import { localStorageGet, localStorageRemove, localStorageSet } from '../../../Utils/LocalStorage';

import LineChart from './ChartComponent';
import './ViewTrend.css';

const ViewTrend = () => {
  const { performanceTrackingData, globalObject } = useGlobalContext();

  const [legendItems, setlegendItems] = useState([]);
  const [chartItems, setChartItems] = useState([]);
  const navigate = useNavigate();

  const filteredGlobalObject = useMemo(() => {
    return globalObject
      ?.map(item => {
        return { ...item, 'Overall Score': getOverallScore(item).toFixed(2) };
      })
      ?.sort((a, b) => {
        return parseFloat(a?.['Overall Score']) < parseFloat(b?.['Overall Score']) ? 1 : -1;
      })
      ?.slice(0, 20);
  }, [globalObject]);

  const filteredPerformanceTrackingData = useMemo(() => {
    return performanceTrackingData?.filter(item => {
      return filteredGlobalObject?.map(g => g['Corridor_ID']).includes(item['ID']);
    });
  }, [filteredGlobalObject, performanceTrackingData]);

  const selectedOpts =
    filteredPerformanceTrackingData?.map(item => ({
      value: item.ID,
      label: `${item.HWY} (${item.Description})`,
      ...item,
    })) || [];

  const returnToSummary = () => {
    return navigate('/performance-tracking');
  };

  useEffect(() => {
    const options = localStorageGet('selectedDdOptionsIds');
    if (options && options !== undefined && options.length > 0) {
      const preSelectedOptions = selectedOpts.filter(item => options.includes(item.value));
      updateCharts(preSelectedOptions);
    }
  }, []);

  const getpreselectedOptions = () => {
    const options = localStorageGet('selectedDdOptionsIds');
    return options && options !== undefined && options.length > 0 ? options : [];
  };

  const getColorCode = iconstr => {
    switch (iconstr) {
      case 'blue-legend-icon':
        return '#a0cbe8';
      case 'pink-legend-icon':
        return '#d37295';
      case 'darkblue-legend-icon':
        return '#5a8ac6';
      case 'brown-legend-icon':
        return '#9d7660';
      case 'sand-legend-icon':
        return '#d7b5a6';
      default:
        return '#b1c0cb';
    }
  };

  const updateCharts = selectedOptions => {
    const iconcolorClassnames = [
      'blue-legend-icon',
      'pink-legend-icon',
      'darkblue-legend-icon',
      'brown-legend-icon',
      'sand-legend-icon',
    ];

    const legendItms = selectedOptions.map((obj, index) => ({
      ...obj,
      icon: iconcolorClassnames[index % iconcolorClassnames.length],
    }));

    setlegendItems(legendItms);
    const updatedCharts = [
      {
        id: 1,
        className: 'chart1',
        category: 'Overall',
      },
      {
        id: 2,
        className: 'chart2',
        category: 'Pavement',
      },
      {
        id: 3,
        className: 'chart3',
        category: 'Bridge',
      },
      {
        id: 4,
        className: 'chart4',
        category: 'Safety',
      },
      {
        id: 5,
        className: 'chart5',
        category: 'Congestion',
      },
      {
        id: 6,
        className: 'chart6',
        category: 'Economic',
      },
      {
        id: 7,
        className: 'chart7',
        category: 'Connectivity',
      },
    ].map(chart => {
      let extraItem = {};
      return {
        ...chart,
        data: {
          labels: ['2021', '2022', '2023'],
          datasets: legendItms.map(hwy => {
            extraItem = hwy;
            return {
              label: hwy['HWY'],
              data: ['2021', '2022', '2023'].map(label => {
                const hv1 = hwy[`HV_1_${chart.category}`];
                const hv2 = hwy[`HV_2_${chart.category}`];
                const score = hwy[`${chart.category}_Score`];
                switch (label) {
                  case '2021':
                    return hv1 != null ? (Number.isInteger(hv1) ? Math.floor(hv1) : parseFloat(hv1.toFixed(2))) : null;
                  case '2022':
                    return hv2 != null ? (Number.isInteger(hv2) ? Math.floor(hv2) : parseFloat(hv2.toFixed(2))) : null;
                  case '2023':
                    return score != null
                      ? Number.isInteger(score)
                        ? Math.floor(score)
                        : parseFloat(score.toFixed(2))
                      : null;
                  default:
                    return null;
                }
              }),
              borderColor: getColorCode(hwy.icon),
            };
          }),
        },
        extraItems: extraItem,
      };
    });
    setChartItems(updatedCharts);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSelect = useCallback(selectedOptions => {
    if (selectedOptions && selectedOptions !== undefined && selectedOptions.length > 0) {
      localStorageSet(
        'selectedDdOptionsIds',
        selectedOptions.map(i => i.value),
      );
      updateCharts(selectedOptions);
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleReset = useCallback(() => {
    setlegendItems([]);
    setChartItems([]);
    localStorageRemove('selectedDdOptionsIds');
  }, []);

  return (
    <>
      <div className="container-fluid d-flex flex-column">
        <div className="viewtrend-header-row">
          <div className="bg-dark-blue text-light">
            <div className="container">Top 20 Corridor Needs Score Trend by Performance Area</div>
          </div>
        </div>
        <div className="view-trend">
          <div className="d-flex  justify-content-between dd-row">
            <div className="d-flex justify-content-bewteen ">
              <div className="dd-row-text flex-fill">Select up to five corridors to view trends:</div>
              <div className="dd">
                <MultiSelectComponent
                  data={selectedOpts.sort((a, b) => (a.label > b.label ? 1 : -1))}
                  onSelect={handleSelect}
                  preselectedOptions={getpreselectedOptions}
                  onReset={handleReset}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <button type="button" className="btn btn-primary returntosummary-btn" onClick={returnToSummary}>
                Return to Summary
              </button>
            </div>
          </div>
          <div className="legend">
            <div className="col-2 d-flex">Selected Corridors:</div>
            <div className="col-10 d-flex">
              {legendItems.map((item, index) => (
                <div key={index}>
                  <div className="d-flex">
                    <div className={item.icon}></div>
                    <div className="legend-text">{item.label}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="row">
              {chartItems && chartItems.length > 0 ? (
                <>
                  {chartItems.map((chart, index) => (
                    <div className="col-3" key={`chart` + index}>
                      <LineChart
                        className={chart.className}
                        data={chart.data}
                        category={chart.category}
                        maxData={chart.className === 'chart1' ? 100 : 10}
                      />
                    </div>
                  ))}
                  <div style={{ fontWeight: 'lighter', fontSize: '16px' }}>
                    2021, 2022, and 2023 represent the release date for the Corridor Assessment tool and do not
                    represent the year of the data source.
                  </div>
                </>
              ) : (
                // Show text when charts array is empty
                <div className="col-12">Please select corridors to view charts.</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTrend;
