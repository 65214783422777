import "bootstrap/dist/css/bootstrap.min.css";
import "@arcgis/core/assets/esri/themes/light/main.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./Context/AuthContext";
import { GlobalProvider } from "./Context/GlobalContext";
import CommonLayout from "./Layout/CommonLayout";
import Home from "./Pages/HomePage/Home";
import Login from "./Pages/LoginPage/Login";
import ViewTrend from "./Features/PerformanceTracking/ViewTrend/ViewTrend";
import SummaryReportPreview from "./Pages/SummaryReportPreview/SummaryReportPreview";
import PrivateRoute from "./Utils/PrivateRoute";

import ImportCorridor from "./Features/SegmentEvaluation/ImportCorridor"
import EvaluationResult from "./Features/SegmentEvaluation/EvaluationResult"
import CorridorSelection from "./Features/CorridorAssessment/CorridorSelection/CorridorSelectionContainer"
import AssessmentResult from "./Features/CorridorAssessment/AssessmentResult";
import PerformanceTracking from "./Features/PerformanceTracking";

function App() {

  return (
    <AuthProvider>
      <GlobalProvider>
          <div className="App">
            <BrowserRouter>
              <Routes>
                {/* Public Routes without Header Footer Layout */}
                <Route path="login" element={<Login />} />

                <Route element={<CommonLayout />}>
                  {/* Home Page and Public Routes with Header Footer Layout */}
                  <Route index path="/" element={<Home />} />
                  <Route element={<PrivateRoute />}>
                    <Route path="/map-view" element={<AssessmentResult />} />
                    <Route path="/table-view" element={<AssessmentResult isTableView />} />
                    <Route path="/select-corridors" element={<CorridorSelection />} />
                    <Route path="/summary-report-preview" element={<SummaryReportPreview />} />
                    <Route path="/segment-evaluation" element={<ImportCorridor />} />
                    <Route path="/segment-evaluation-results" element={<EvaluationResult />} />
                    <Route path="/performance-tracking" element={<PerformanceTracking />} />
                    <Route path="/performance-tracking/view-trend" element={<ViewTrend />} />
                  </Route>
                </Route>
                <Route path="*" element={<>Not Found</>} />
              </Routes>
            </BrowserRouter>
          </div>

      </GlobalProvider>
    </AuthProvider>
  );
}

export default App;
