import { useState, useEffect, useCallback } from 'react';
import { useCorridorSelectionContext } from '../Context/CorridorSelectionContext';
import { useSegmentEvalutionStore } from '../../../../store/segment-evaluation/store';
import { useAuth } from '../../../../Context/AuthContext';

const API_URL = process.env.REACT_APP_API_URL;

function useFetchCorridorData() {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const { setSelectViewData, setSelectViewDataTemp } = useCorridorSelectionContext();
  const { selectedMode, selectedDistrict, selectedMPO } = useSegmentEvalutionStore(state => state);
  const { authenticatedAxios, userName } = useAuth();

  const fetchData = useCallback(async () => {
    setIsFetching(true);
    try {
      const response = await authenticatedAxios.post(`${API_URL}/selectviewdatabyuser`, {
        selectedMode: selectedMode,
        selectedDistrict: selectedDistrict,
        selectedMPO: selectedMPO,
        UserName: userName,
      });
      setSelectViewData(response.data);
      setSelectViewDataTemp(response.data);
      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      setError(error);
      throw new Error();
    }
  }, [
    selectedMode,
    selectedDistrict,
    selectedMPO,
    authenticatedAxios,
    userName,
    setSelectViewData,
    setSelectViewDataTemp,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { isFetching, fetchData, error };
}

export default useFetchCorridorData;
