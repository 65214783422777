import React, { Fragment, useCallback, useEffect, useState } from "react";
import "./Breadcrumb.css";
import ModeSelector from "../../Components/ModeSelector/ModeSelector";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";

const Breadcrumb = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const [splitRoutes, setSplitRoutes] = useState([]);

  useEffect(() => {
    if (loc?.pathname) {
      const currentRoute = loc?.pathname.substring(1);
      const splitRoute = currentRoute?.split("/");
      const _storeTempRoute = [];

      splitRoute.forEach((item, index) => {
        if (index === 0) {
          _storeTempRoute.push({
            label: "Home",
            path: `/`,
            homePath: `/`,
          });
          if (item !== "") {
            _storeTempRoute.push({
              label: _.startCase(item),
              path: `/${item}`,
              homePath: `/`,
            });
          }
        } else {
          const path = _storeTempRoute.map((i) => i?.path);
          _storeTempRoute.push({
            label: _.startCase(item),
            path: `${path[index]}/${item}`,
            homePath: `/`,
          });
        }
      });
      setSplitRoutes(_storeTempRoute);
    }
  }, [loc]);

  const navigationClick = useCallback(
    (item, index) => {
      if (index === 0) {
        navigate("/");
      } else {
        navigate(item?.path);
      }
    },
    [navigate]
  );
  return (
    <>
      <div className="breadcrumb-container">
        <div className="container d-flex justify-content-between align-items-center">
          <div>
            {splitRoutes?.map((item, index) => (
              <Fragment key={index}>
                <span
                  className={`cursor-pointer ${
                    index !== splitRoutes.length - 1 && "text-theme-alter-color"
                  }`}
                  onClick={() => navigationClick(item, index)}
                >
                  {item?.label}
                </span>
                {index !== splitRoutes.length - 1 && <>&nbsp;/&nbsp;</>}
              </Fragment>
            ))}
          </div>
          <ModeSelector />
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
