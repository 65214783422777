import { useCallback, useEffect } from 'react';

import { zoomCorridor } from '../../../../Utils/MapHelpers';
import useMapView from './Hooks/useMapView';
import { RENDERER_HIGHLIGHT, RENDERER_HIGHLIGHT_MARKER } from '../../../../Utils/Constants';

function MapView({ selectedCustomData }) {
  const { mapContainerRef, corridorLayerRef, viewRef, isMapLoaded, corridorKeyMarkerLayerRef } = useMapView();

  const getDefinitionExpression = useCallback((selectedData = []) => {
    const expressionParts = selectedData.map(
      item => `HWY = '${item?.HWY}' AND BMP >= ${item?.begin} AND EMP <= ${item?.end}`,
    );
    return expressionParts.join(' OR ');
  }, []);

  useEffect(() => {
    if (isMapLoaded) {
      if (selectedCustomData.length === 0) {
        corridorLayerRef.current.definitionExpression = '1=0';
        corridorKeyMarkerLayerRef.current.definitionExpression = '1=0';
        return;
      }
      const definitionExpression = getDefinitionExpression(selectedCustomData);

      corridorLayerRef.current.definitionExpression = definitionExpression;
      corridorLayerRef.current.renderer = RENDERER_HIGHLIGHT;
      corridorKeyMarkerLayerRef.current.definitionExpression = definitionExpression;
      corridorKeyMarkerLayerRef.current.renderer = RENDERER_HIGHLIGHT_MARKER;
      setTimeout(() => {
        zoomCorridor(corridorLayerRef?.current, viewRef.current);
      }, 500);
    }
  }, [isMapLoaded, selectedCustomData, getDefinitionExpression]);

  return <div ref={mapContainerRef} className="map-container-create" style={{ height: '75vh' }}></div>;
}

export default MapView;
