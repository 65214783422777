import { useRef, useEffect, useCallback, useState } from 'react';

import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import esriConfig from '@arcgis/core/config';
import Map from '@arcgis/core/Map';
import ArcMapView from '@arcgis/core/views/MapView';

import {
  DEFAULT_MAP_CENTRAL_POINT,
  DEFAULT_SPATIAL_REF,
  MAP_API_KEY,
  DISTRICT_FEATURE_SERVICE_URL,
  COUNTY_FEATURE_SERVICE_URL,
  URBANIZED_AREA_FEATURE_SERVICE_URL,
  MPO_FEATURE_SERVICE_URL,
  FUTURE_INTERSTATES_FEATURE_SERVICE_URL,
  HURRICANCE_EVACUATION_ROUTE,
  TEXAS_TRUNK_SYSTEM,
  ENERGY_SECTOR_CORRIDORS,
  ROADWAY_SHIELD_ONE,
  ROADWAY_SHIELD_TWO,
  ROADWAY_SHIELD_THREE,
  ROADWAY_SHIELD_FOUR,
} from '../../../../../Utils/Constants';

import { getLabelingInfo, addAdditionalSections } from './helper';

import { useSegmentEvalutionStore } from '../../../../../store/segment-evaluation/store';
import useCorridorLayer from '../useCorridorLayer';
import useCorridorKeyMarkerLayer from '../useCorridorKeyMarkerLayer';

function useMapView(customZoom) {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const { corridorLayer } = useCorridorLayer();
  const { corridorKeyMarkersLayer } = useCorridorKeyMarkerLayer();
  const { selectedCorridorId } = useSegmentEvalutionStore(state => state);

  const mapContainerRef = useRef(null);
  const mapViewRef = useRef(null);
  const viewRef = useRef(null);
  const corridorKeyMarkerLayerRef = useRef(null);
  const corridorLayerRef = useRef(null);

  const shouldGenerate = mapContainerRef.current && corridorLayer && corridorKeyMarkersLayer && !viewRef.current;

  const generateMap = useCallback(() => {
    esriConfig.apiKey = MAP_API_KEY;
    const labelingInfo = getLabelingInfo();
    // Start building the map
    const districtLayer = new FeatureLayer({
      url: DISTRICT_FEATURE_SERVICE_URL,
      outFields: ['*'],
      title: 'District Boundaries',
      labelingInfo,
      renderer: {
        type: 'simple',
        symbol: {
          type: 'simple-line',
          width: 1,
          color: 'lightGray',
        },
      },
    });
    const urbanizedAreaLayer = new FeatureLayer({
      url: URBANIZED_AREA_FEATURE_SERVICE_URL,
      outFields: ['*'],
      title: 'Urbanized Areas',
      visible: false,
    });
    const countyLayer = new FeatureLayer({
      url: COUNTY_FEATURE_SERVICE_URL,
      outFields: ['*'],
      title: 'County Boundaries',
      visible: true,
      labelingInfo,
    });
    const mpoLayer = new FeatureLayer({
      url: MPO_FEATURE_SERVICE_URL,
      outFields: ['*'],
      title: 'MPO Boundaries',
      visible: false,
    });
    const fiLayer = new FeatureLayer({
      url: FUTURE_INTERSTATES_FEATURE_SERVICE_URL,
      outFields: ['*'],
      title: 'Future Interstates',
      visible: false,
    });
    const ttsLayer = new FeatureLayer({
      url: TEXAS_TRUNK_SYSTEM,
      outFields: ['*'],
      title: 'Texas Trunk System',
      visible: false,
    });
    const herLayer = new FeatureLayer({
      url: HURRICANCE_EVACUATION_ROUTE,
      outFields: ['*'],
      title: 'Hurricane Evacuation Route',
      visible: false,
    });
    const escLayer = new FeatureLayer({
      url: ENERGY_SECTOR_CORRIDORS,
      outFields: ['*'],
      title: 'Energy Sector Corridors',
      visible: false,
    });
    const rw1 = new FeatureLayer({
      url: ROADWAY_SHIELD_ONE,
      outFields: ['*'],
      title: 'Roadway 1',
      visible: true,
      listMode: 'hide',
    });
    const rw2 = new FeatureLayer({
      url: ROADWAY_SHIELD_TWO,
      outFields: ['*'],
      title: 'Roadway 2',
      visible: true,
      listMode: 'hide',
    });
    const rw3 = new FeatureLayer({
      url: ROADWAY_SHIELD_THREE,
      outFields: ['*'],
      title: 'Roadway 3',
      visible: true,
      listMode: 'hide',
    });
    const rw4 = new FeatureLayer({
      url: ROADWAY_SHIELD_FOUR,
      outFields: ['*'],
      title: 'Roadway 4',
      visible: true,
      listMode: 'hide',
    });
    const map = new Map({
      basemap: 'arcgis-light-gray',
      layers: [
        ttsLayer,
        herLayer,
        escLayer,
        fiLayer,
        urbanizedAreaLayer,
        mpoLayer,
        countyLayer,
        districtLayer,
        corridorLayer,
        corridorKeyMarkersLayer,
        rw1,
        rw2,
        rw3,
        rw4,
      ],
      spatialReference: DEFAULT_SPATIAL_REF,
    });
    const view = new ArcMapView({
      map: map,
      center: DEFAULT_MAP_CENTRAL_POINT,
      zoom: customZoom ? customZoom : 5.75,
      container: mapContainerRef.current,
      constraints: {
        snapToZoom: false,
      },
    });
    addAdditionalSections(view, countyLayer, districtLayer, corridorLayer);

    // Initialize Refs values
    corridorLayerRef.current = corridorLayer;
    corridorKeyMarkerLayerRef.current = corridorKeyMarkersLayer;
    mapViewRef.current = map;
    viewRef.current = view;
    setIsMapLoaded(true);

    return () => {
      viewRef?.destroy();
    };
  }, [corridorKeyMarkersLayer, corridorLayer, setIsMapLoaded]);

  useEffect(() => {
    if (shouldGenerate) generateMap();
  }, [generateMap, shouldGenerate, selectedCorridorId]);

  return {
    mapContainerRef,
    mapViewRef,
    viewRef,
    corridorLayerRef,
    corridorKeyMarkerLayerRef,
    refreshMap: generateMap,
    isMapLoaded,
    corridorKeyMarkerLayerRef,
  };
}

export default useMapView;
