import './ColorCodeExplainer.css';

function ColorCodeExplainer({ colorCodeDefinition = [] }) {
  return (
    <div className="color-code-explainer-wrapper">
      {colorCodeDefinition.map((item, index) => (
        <div key={index} className="color-code-explainer">
          <div className="color-code" style={{ backgroundColor: item.color }}></div>
          <div className="color-code-text">{item.text}</div>
        </div>
      ))}
    </div>
  );
}

export default ColorCodeExplainer;
