import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useSegmentEvalutionStore } from '../../../../store/segment-evaluation/store';

function CorridorSummary({ refreshMap }) {
  const { setSelectedCorridorId, setSelectedSwitchValue, setSelectedDropDownValue } = useSegmentEvalutionStore(
    state => state,
  );

  const handleResetCorridor = useCallback(() => {
    setSelectedCorridorId(null);
    refreshMap();
  }, [setSelectedCorridorId, refreshMap]);

  return (
    <div className="d-flex justify-content-center align-items-center gap-2 flex-wrap">
      <button className="reset-button" onClick={handleResetCorridor}>
        Reset
      </button>
      <button
        className="import-button"
        onClick={() => {
          setSelectedSwitchValue('Performance');
          setSelectedDropDownValue('Pavement');
        }}
      >
        <Link to="/segment-evaluation-results" className="import-button-link">
          View Performance
        </Link>
      </button>
      <button
        className="import-button"
        onClick={() => {
          setSelectedSwitchValue('Need');
          setSelectedDropDownValue('Overall');
        }}
      >
        <Link to="/segment-evaluation-results" className="import-button-link">
          View Needs
        </Link>
      </button>
    </div>
  );
}

export default CorridorSummary;
