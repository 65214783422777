import { useMemo } from 'react';
import { orderBy } from 'lodash';

import TableWithPagination from '../../../../Components/TableWithPagination/TableWithPagination';
import { useSegmentEvalutionStore } from '../../../../store/segment-evaluation/store';
import { getNeedValue } from '../../../../Utils/Functions';

import { pavementColumnDetails } from './Columns/pavementColumnDetails';
import { bridgeColumnDetails } from './Columns/bridgeColumnDetails';
import { mobilityColumnDetails } from './Columns/mobilityColumnDetails';
import { safetyColumnDetails } from './Columns/safetyColumnDetails';
import { freightColumnDetails } from './Columns/freightColumnDetails';
import { overallColumnDetails } from './Columns/overallColumnDetails';

import DropDown from './DropDown';
import Summary from './Summary';

const headerColor = {
  textAlign: 'center',
  font: 'normal 14px Franklin Gothic',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: '1',
  backgroundColor: 'white',
  border: 'none',
  fontWeight: 'bold',
  background: '#ADB5BD 0% 0% no-repeat padding-box',
};

const SegmentMapTableOverview = ({
  setIsTableView,
  allCorridorData,
  setIsSegmentDetailViewOpen,
  setHighlightSegment,
}) => {
  const { selectedSwitchValue, selectedDropDownValue, setSelectedSwitchValue, setSelectedDropDownValue } =
    useSegmentEvalutionStore(state => state);
  const isNeedSelected = selectedSwitchValue === 'Need';

  const filteredData = useMemo(() => {
    const needValue = getNeedValue(selectedDropDownValue, selectedSwitchValue);
    const sortedData = isNeedSelected
      ? orderBy(allCorridorData, [`attributes.[${needValue}]`], ['desc'])
      : orderBy(allCorridorData, [`attributes.Segment`], ['asc']);

    const result = sortedData.map((corridor, i) => {
      const finalColumns =
        selectedDropDownValue === 'Overall'
          ? overallColumnDetails(corridor)
          : selectedDropDownValue === 'Pavement'
          ? pavementColumnDetails(corridor, selectedSwitchValue)
          : selectedDropDownValue === 'Bridge'
          ? bridgeColumnDetails(corridor, selectedSwitchValue)
          : selectedDropDownValue === 'Mobility'
          ? mobilityColumnDetails(corridor, selectedSwitchValue)
          : selectedDropDownValue === 'Safety'
          ? safetyColumnDetails(corridor, selectedSwitchValue)
          : freightColumnDetails(corridor, selectedSwitchValue);

      return {
        'Segment #': {
          __value: corridor.attributes.Segment,
          __style: {
            border: '1px solid #ADB5BD',
            opacity: 1,
            textAlign: 'center',
            letterSpacing: '0px',
            fontWeight: 'bold',
            fontFamily: 'Franklin Gothic',
            fontSize: '14px',
          },
          __headerStyle: headerColor,
        },
        Routes: {
          __value: corridor.attributes.HWY,
          __headerStyle: headerColor,
          __style: {
            font: 'normal normal normal 14px Franklin Gothic',
            letterSpacing: '0px',
            color: '#000000',
            fontWeight: 'bold',
            opacity: 1,
            textAlign: 'center',
            border: '1px solid #ADB5BD',
          },
        },
        'Begin Marker': {
          __value: corridor.attributes.BMP,
          __headerStyle: headerColor,
          __style: {
            font: 'normal normal normal 14px Franklin Gothic',
            textAlign: 'center',
            border: '1px solid #ADB5BD',
          },
        },
        'End Marker': {
          __value: corridor.attributes.EMP,
          __headerStyle: headerColor,
          __style: {
            font: 'normal normal normal 14px Franklin Gothic',
            textAlign: 'center',
            border: '1px solid #ADB5BD',
          },
        },
        ...finalColumns,
      };
    });
    return result;
  }, [allCorridorData, headerColor, selectedDropDownValue, selectedSwitchValue]);

  const onOpenThresholds = () => {
    window.open(
      'https://aecomarl.maps.arcgis.com/sharing/rest/content/items/56b05b2110b64b308ca5906e30846b83/data',
      '_blank',
    );
  };

  const onRowSelected = (_, rowData) => {
    setIsSegmentDetailViewOpen(true);
    setSelectedSwitchValue('Performance');
    setSelectedDropDownValue('Pavement');
    setHighlightSegment(rowData?.['Segment #']?.__value);
  };

  return (
    <div className="evalution-container">
      <div className={`d-flex justify-content-between px-3`} style={{ height: 'fit-content' }}>
        <div className="evalution-title">Segment Evaluation Results</div>
        <div className="toggle-btn" onClick={() => setIsTableView(false)}>
          <div className={`segment-toggle-btn ${'active'}`}>
            <i class="fa-solid fa-map"></i>
            <span>Map</span>
          </div>
        </div>
      </div>
      <div className="col-lg-6 pt-2 px-3">
        <DropDown />
      </div>
      <Summary allCorridorData={allCorridorData} />
      <div className="pt-2">
        {filteredData && (
          <TableWithPagination
            data={filteredData}
            itemsPerPage={9}
            isPaginationAvail={true}
            onRowSelected={onRowSelected}
          />
        )}
      </div>
      <button className="reset-button thresholds-button" onClick={onOpenThresholds}>
        Scoring Thresholds
      </button>
    </div>
  );
};

export default SegmentMapTableOverview;
