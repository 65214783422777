import { useCallback, useEffect, useMemo, useState } from 'react';
import { localStorageGetWithExpiry } from '../../../../Utils/LocalStorage';
import { useGlobalContext } from '../../../../Context/GlobalContext';
import { ROADWAY_CLASSIFICATIONS } from '../../../../Utils/Constants';

function useCustomSegments() {
  const [filteredCustomSegments, setFilteredCustomSegments] = useState([]);
  const { globalObject } = useGlobalContext();

  const filterSegment = useCallback(() => {
    const localStoragefilters = localStorageGetWithExpiry('customFilters');
    let roadwayClassifications = [];
    if (localStoragefilters) {
      ROADWAY_CLASSIFICATIONS.forEach(rec => {
        if (localStoragefilters[rec.value]) roadwayClassifications = [...roadwayClassifications, { ...rec }];
      });
    }
    roadwayClassifications = roadwayClassifications.map(r => r.value);
    const selectedSegments = localStorageGetWithExpiry('selectedSegments') ?? [];
    const includeOthers = roadwayClassifications.includes('OTHERS');
    const selectedCustomSegments =
      globalObject?.filter(item => selectedSegments?.includes(item?.Corridor_ID) && item.Custom === 1) ?? [];

    const { IH, US, SH, FM, RM, BS, district } = localStoragefilters;
    const hwyMap = { IH, US, SH, FM, RM, BS };
    const urbanRural = localStoragefilters?.urbanRural;

    const filteredSegment = selectedCustomSegments.filter(segment => {
      const SegmentDistricts = segment.Districts;
      const segnmentHWY = segment.HWY?.slice(0, 2);
      const segmentMPO = segment.MPO;

      return hwyMap[segnmentHWY] && SegmentDistricts?.includes(district) && segmentMPO.includes(urbanRural);
    });

    if (includeOthers) {
      const corridorTypes = ROADWAY_CLASSIFICATIONS.map(r => r.value);
      const othersCorridors =
        globalObject?.filter(
          rec =>
            !roadwayClassifications.includes(rec.HWY.slice(0, 2)) &&
            !corridorTypes.includes(rec.HWY.slice(0, 2)) &&
            rec.Custom === 1,
        ) ?? [];
      filteredSegment.push(...othersCorridors);
    }

    setFilteredCustomSegments(filteredSegment);
  }, [globalObject]);

  useEffect(() => {
    filterSegment();
  }, [filterSegment]);

  return { filteredCustomSegments, filterSegment, globalObject };
}

export default useCustomSegments;
