import { getColorCode, colorCodeMapper, numberFormatter } from '../../../../helper';

import BoxElement from '../BoxElement';

function MobilityIndex({ segmentData }) {
  const Facility_T = segmentData?.attributes?.Facility_T;
  const I_U = segmentData?.attributes?.I_U;
  const Bound_1_B1 = segmentData?.attributes?.Bound_1_B1;
  const Bound_2_B2 = segmentData?.attributes?.Bound_2_B2;

  const attributesToMap = {
    Mobility_I: {
      value: numberFormatter(segmentData?.attributes?.Mobility_I),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Mobility_I),
        colorCodeMapper['Mobility'][Facility_T]?.Mobility_I,
      ),
    },
    Peak_VC_1: {
      value: numberFormatter(segmentData?.attributes?.Peak_VC_1),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Peak_VC_1),
        colorCodeMapper['Mobility'][Facility_T]?.Peak_VC_1,
      ),
    },
    Peak_VC_2: {
      value: numberFormatter(segmentData?.attributes?.Peak_VC_2),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Peak_VC_2),
        colorCodeMapper['Mobility'][Facility_T]?.Peak_VC_2,
      ),
    },
    Future_VC: {
      value: numberFormatter(segmentData?.attributes?.Future_VC),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Future_VC),
        colorCodeMapper['Mobility'][Facility_T]?.Future_VC,
      ),
    },
    FrontVC_21: {
      value: numberFormatter(segmentData?.attributes?.FrontVC_21),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.FrontVC_21),
        colorCodeMapper['Mobility'][Facility_T]?.FrontVC_21,
      ),
    },
    FrontVC_41: {
      value: numberFormatter(segmentData?.attributes?.FrontVC_41),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.FrontVC_41),
        colorCodeMapper['Mobility'][Facility_T]?.FrontVC_41,
      ),
    },
    Inte_VC_21: {
      value: numberFormatter(segmentData?.attributes?.Inte_VC_21),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Inte_VC_21),
        colorCodeMapper['Mobility'][Facility_T]?.Inte_VC_21,
      ),
    },
    Inte_VC_41: {
      value: numberFormatter(segmentData?.attributes?.Inte_VC_41),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Inte_VC_41),
        colorCodeMapper['Mobility'][Facility_T]?.Inte_VC_41,
      ),
    },
    TTI_all_B1: {
      value: numberFormatter(segmentData?.attributes?.TTI_all_B1),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.TTI_all_B1),
        colorCodeMapper['Mobility'][Facility_T]?.TTI_all_B1[I_U],
      ),
    },
    TTI_all_B2: {
      value: numberFormatter(segmentData?.attributes?.TTI_all_B2),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.TTI_all_B2),
        colorCodeMapper['Mobility'][Facility_T]?.TTI_all_B2[I_U],
      ),
    },
    PTI_all_B1: {
      value: numberFormatter(segmentData?.attributes?.PTI_all_B1),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.PTI_all_B1),
        colorCodeMapper['Mobility'][Facility_T]?.PTI_all_B1[I_U],
      ),
    },
    PTI_all_B2: {
      value: numberFormatter(segmentData?.attributes?.PTI_all_B2),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.PTI_all_B2),
        colorCodeMapper['Mobility'][Facility_T]?.PTI_all_B2[I_U],
      ),
    },
  };

  return (
    <div className="indicator-rectengle-section mx-3 d-flex justify-content-start align-items-center pb-1 position-relative">
      <div className="indicator-box d-flex justify-content-center align-items-center flex-column gap-2">
        <div className="indicator-img">
          <img className="card-img-top" src="/assets/svg/Congestion.svg" alt="Pavement" />
        </div>
        <div className="indicator-type fw-bold">Mobility Index</div>
        <div className="indicator-value" style={{ color: attributesToMap['Mobility_I'].colorCode }}>
          {attributesToMap['Mobility_I'].value}
        </div>
      </div>
      <div className="indicator-side-detail d-flex justify-content-start align-items-center position-absolute">
        <BoxElement
          description="Existing Mainlane Peak-hour V/C"
          value1={attributesToMap['Peak_VC_1'].value}
          valueTitle1={Bound_1_B1}
          colorCode1={attributesToMap['Peak_VC_1'].colorCode}
          value2={attributesToMap['Peak_VC_2'].value}
          valueTitle2={Bound_2_B2}
          colorCode2={attributesToMap['Peak_VC_2'].colorCode}
        />
        <BoxElement
          description="Mainlane Future V/C"
          value1={attributesToMap['Future_VC'].value}
          colorCode1={attributesToMap['Future_VC'].colorCode}
        />
        <BoxElement
          description="Frontage Road V/C"
          value1={attributesToMap['FrontVC_21'].value}
          colorCode1={attributesToMap['FrontVC_21'].colorCode}
          valueTitle1="2021"
          value2={attributesToMap['FrontVC_41'].value}
          colorCode2={attributesToMap['FrontVC_41'].colorCode}
          valueTitle2="2041"
        />
        <BoxElement
          description="Interchange V/C"
          value1={attributesToMap['Inte_VC_21'].value}
          valueTitle1="2021"
          colorCode1={attributesToMap['Inte_VC_21'].colorCode}
          value2={attributesToMap['Inte_VC_41'].value}
          valueTitle2="2041"
          colorCode2={attributesToMap['Inte_VC_41'].colorCode}
        />
        <BoxElement
          description="Directional Travel Time Index"
          value1={attributesToMap['TTI_all_B1'].value}
          valueTitle1={Bound_1_B1}
          colorCode1={attributesToMap['TTI_all_B1'].colorCode}
          value2={attributesToMap['TTI_all_B2'].value}
          valueTitle2={Bound_2_B2}
          colorCode2={attributesToMap['TTI_all_B2'].colorCode}
        />
        <BoxElement
          description="Directional Planning Time Index"
          value1={attributesToMap['PTI_all_B1'].value}
          valueTitle1={Bound_1_B1}
          colorCode1={attributesToMap['PTI_all_B1'].colorCode}
          value2={attributesToMap['PTI_all_B2'].value}
          valueTitle2={Bound_2_B2}
          colorCode2={attributesToMap['PTI_all_B2'].colorCode}
        />
      </div>
    </div>
  );
}

export default MobilityIndex;
