import { useMemo, useCallback } from 'react';

import { useSegmentEvalutionStore } from '../../../../../store/segment-evaluation/store';
import { filterSegmentData } from '../../../helper';

function SegmentDropdown({ allCorridorData, setHighlightSegment, highlightSegment }) {
  const { selectedCorridorId, selectedMode, selectedDistrict } = useSegmentEvalutionStore(state => state);

  const handleSelectSegment = useCallback(value => setHighlightSegment(value), [setHighlightSegment]);

  const filteredDropdown = useMemo(
    () => filterSegmentData(allCorridorData, selectedCorridorId, selectedMode, selectedDistrict),
    [allCorridorData, selectedCorridorId, selectedMode, selectedDistrict],
  );

  return (
    <div className="col-lg-12 pt-2 px-3 pb-5 d-flex align-items-center gap-4">
      <div className="segment-selector-title">Select a segment to view details:</div>
      <div className="w-25">
        <form className="d-flex">
          <div className="col-12">
            <form className="">
              <div className="dropdown col-12 w-100">
                <button
                  className="form-select btn corridor-dropdown"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {highlightSegment ? `Segment ${highlightSegment}` : <span>Select a Segment</span>}
                </button>
                <ul className="dropdown-menu pt-0 corridor-dropdown-items" aria-labelledby="dropdownMenuButton1">
                  <div className="corridor-dropdown-list">
                    {filteredDropdown.map((word, i) => (
                      <li
                        key={i}
                        className="dropdown-item"
                        onClick={() => handleSelectSegment(word.attributes.Segment)}
                      >
                        {`Segment ${word.attributes.Segment}`}
                      </li>
                    ))}
                  </div>
                </ul>
              </div>
            </form>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SegmentDropdown;
