import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { setToken, setusername } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(API_URL + "/login", {
        username,
        password,
      });
      setToken(response?.data?.token);
      setusername(username);
      navigate("/");
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    }
  };

  return (
    <div className="login-container">
      <img
        src="assets/svg/TXDOTlogo_white_small.png"
        alt="TxDOT Logo"
        width="140px"
        height="110px"
      />
      <div className="login-inputs">
        <input
          id="username"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        <input
          id="password"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button id="login-button" onClick={handleLogin}>
        LOGIN
      </button>
      <div id="error-message">{errorMessage}</div>
    </div>
  );
};

export default Login;
