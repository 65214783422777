import { localStorageSetWithExpiry } from "./LocalStorage";

export const storeSelectViewFilters = ({ routeTypes, search, custom }) => {
  const isActive = (type) =>
    Boolean(routeTypes.find((r) => r?.value?.toUpperCase() === type));

  let filters = {};
  filters.IH = isActive("IH");
  filters.US = isActive("US");
  filters.SH = isActive("SH");
  filters.FM = isActive("FM");
  filters.RM = isActive("RM");
  filters.BS = isActive("BS");
  filters.OTHERS = isActive("OTHERS");
  filters.search = search || "";
  filters.custom = custom || "";
  localStorageSetWithExpiry("selectViewFilters", filters, 60);
};

export const filterBySearchTerm = (corridor, searchTerm) => {
  if (!searchTerm) {
    return true;
  }
  const trimmedSearchTerm = searchTerm.replace(/[._-\s]/g, "").toLowerCase();
  const hwy = corridor.HWY.toLowerCase();
  const hwyPreffix = hwy.slice(0, 2);
  const hwySuffix = hwy.slice(2);
  const hwyWithoutZeros = `${hwyPreffix}${hwySuffix.replace(/^0+/, "")}`;
  const hwyShortPrefix = `${hwyPreffix.slice(0, 1)}${hwySuffix}`;
  const hwyShortPrefixWithoutZeros = `${hwyPreffix.slice(
    0,
    1
  )}${hwySuffix.replace(/^0+/, "")}`;
  let hwyTx = null;
  let hwyTxWithoutZeros = null;
  if (hwy.includes("sh")) {
    hwyTx = `tx${hwySuffix}`;
    hwyTxWithoutZeros = `tx${hwySuffix.replace(/^0+/, "")}`;
  }

  return (
    hwy.includes(trimmedSearchTerm) ||
    hwyWithoutZeros.includes(trimmedSearchTerm) ||
    hwyShortPrefix.includes(trimmedSearchTerm) ||
    hwyShortPrefixWithoutZeros.includes(trimmedSearchTerm) ||
    (hwyTx ? hwyTx.includes(trimmedSearchTerm) : false) ||
    (hwyTxWithoutZeros ? hwyTxWithoutZeros.includes(trimmedSearchTerm) : false)
  );
};

export const routeOrder = {
  IH: 1,
  US: 2,
  SH: 3,
  FM: 4,
  RM: 5,
  OTHERS: 6,
};

export const filterRecordsByCustomTrue = (dataModel) => {
  let filteredData = {};

  for (const key in dataModel) {
    if (!Object.hasOwnProperty.call(dataModel, key)) {
      continue;
    }

    const records = dataModel[key];
    const filteredRecords = records.filter(record => record.Custom === 1);

    if (filteredRecords.length > 0) {
      filteredData[key] = filteredRecords;
    }
  }

  return filteredData;
}

export const filterRecordsByCustomFalse = (dataModel) => {
  let filteredData = {};

  for (const key in dataModel) {
    if (!Object.hasOwnProperty.call(dataModel, key)) {
      continue;
    }

    const records = dataModel[key];
    const filteredRecords = records.filter(record => record.Custom === 0);

    if (filteredRecords.length > 0) {
      filteredData[key] = filteredRecords;
    }
  }

  return filteredData;
}



