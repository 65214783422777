import { createContext, useContext, useState, useMemo } from 'react';

const CorridorSelectionContext = createContext();

export function CorridorSelectionProvider({ children }) {
  const [selectViewData, setSelectViewData] = useState({});
  const [selectViewDataTemp, setSelectViewDataTemp] = useState({});
  const [filters, setFilters] = useState({ routeTypes: [], custom: 0 });
  const [selectedCorridors, setSelectedCorridors] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectAllCorridors, setSelectAllCorridors] = useState(false);
  const [createNewSelected, setCreateNewSelected] = useState(false);

  const memorizedValue = useMemo(
    () => ({
      selectViewData,
      setSelectViewData,
      selectViewDataTemp,
      setSelectViewDataTemp,
      filters,
      setFilters,
      selectedCorridors,
      setSelectedCorridors,
      selectedSegments,
      setSelectedSegments,
      selectAllCorridors,
      setSelectAllCorridors,
      createNewSelected,
      setCreateNewSelected,
    }),
    [
      selectViewData,
      setSelectViewData,
      selectViewDataTemp,
      setSelectViewDataTemp,
      filters,
      setFilters,
      selectedCorridors,
      setSelectedCorridors,
      selectedSegments,
      setSelectedSegments,
      selectAllCorridors,
      setSelectAllCorridors,
      createNewSelected,
      setCreateNewSelected,
    ],
  );

  return <CorridorSelectionContext.Provider value={memorizedValue}>{children}</CorridorSelectionContext.Provider>;
}

export function useCorridorSelectionContext() {
  const context = useContext(CorridorSelectionContext);

  if (context === undefined) {
    throw new Error('useCorridorSelectionContext must be used within CorridorSelectionProvider');
  }

  return context;
}
