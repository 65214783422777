import { useSegmentEvalutionStore } from '../../../../../store/segment-evaluation/store';

function EvaluationResultView({ setIsFullMapView, isFullMapView, highlightSegment }) {
  const { setSelectedSwitchValue, selectedSwitchValue, setSelectedDropDownValue } = useSegmentEvalutionStore(
    state => state,
  );

  const onSwitchHandle = value => {
    if (value === 'Need') {
      setSelectedSwitchValue(value);
      setSelectedDropDownValue('Overall');
    } else {
      setSelectedSwitchValue(value);
      setSelectedDropDownValue('Pavement');
    }
  };

  return (
    <div
      className="filters-containers d-flex gap-5 mx-5 p-3 justify-content-center align-items-center"
      style={{ width: '90%' }}
    >
      {!highlightSegment && (
        <div className="map-switch d-flex">
          <div
            className={`w-50 d-flex justify-content-center align-items-center ${
              selectedSwitchValue === 'Need' ? 'selected-map-switch' : ''
            }`}
            onClick={() => onSwitchHandle('Need')}
          >
            Need
          </div>
          <div
            className={`w-50 d-flex justify-content-center align-items-center ${
              selectedSwitchValue === 'Performance' ? 'selected-map-switch' : ''
            }`}
            onClick={() => onSwitchHandle('Performance')}
          >
            Performance
          </div>
        </div>
      )}
    </div>
  );
}

export default EvaluationResultView;
