import { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'reactstrap';

import {
  highlightCorridor,
  zoomCorridor,
  resetHighlightLayer,
  convertMultiLineStringToGraphic,
} from '../../../Utils/MapHelpers';
import CorridorInformation from './CorridorInformation';
import useCustomSegments from './Hooks/useCustomSegments';
import { getOverallScore, getCorridorTierColors } from '../../../Utils/CorridorRankingHelper';

import MapTableOverview from './MapOverview/MapTableView';
import useMapView from './Hooks/useMapView';
import MapOverview from './MapOverview/MapOverview';

import './AssessmentResult.css';

function AssessmentResult({ isTableView }) {
  const [highlightCorridorId, setHighlightCorridorId] = useState(null);
  const [customHighlightCorridor, setCustomHighlightCorridor] = useState(null);

  const { mapContainerRef, viewRef, graphicsLayerRef, refreshMap, highlightLayerRef } = useMapView();
  const { filteredCustomSegments = [], filterSegment, globalObject } = useCustomSegments();

  const onZoomHandler = useCallback(() => {
    if (customHighlightCorridor) {
      viewRef.current.goTo({
        target: customHighlightCorridor,
        zoom: 9,
      });
      return;
    }
    zoomCorridor(highlightLayerRef.current, viewRef.current);
  }, [highlightLayerRef.current, viewRef.current, zoomCorridor, customHighlightCorridor]);

  const onClickBackHandler = useCallback(() => {
    resetHighlightLayer(highlightLayerRef.current);
    setCustomHighlightCorridor(null);
    setTimeout(() => {
      viewRef.current.goTo({
        center: [-99.9018, 31.9686], // Texas coordinates as center
        zoom: 5,
      });
    }, 500);
  }, [highlightLayerRef.current, resetHighlightLayer, customHighlightCorridor]);

  useEffect(() => {
    async function setHighlightCorridor() {
      if (highlightLayerRef?.current && highlightCorridor && highlightCorridorId) {
        await highlightCorridor(`ID = '${highlightCorridorId}'`, highlightLayerRef?.current, viewRef.current);
      }
    }
    setHighlightCorridor();
  }, [highlightCorridorId]);

  useEffect(() => {
    graphicsLayerRef.current.removeAll();
    if (filteredCustomSegments?.length > 0) {
      const graphics = [];
      let zoomToSegment = null;
      filteredCustomSegments.forEach(segment => {
        const geometry = segment?.Geometry;
        const ob = JSON.parse(geometry);
        const segmentGraphics =
          ob.features?.map(feature => {
            if (feature.geometry.type === 'MultiLineString') {
              const scoresArray = globalObject?.map(go => getOverallScore(go));
              const overallScore = getOverallScore(segment).toFixed(2);
              const colorCode = getCorridorTierColors(scoresArray, overallScore);
              const color = colorCode.backgroundColor === '#FFFFFF' ? 'gray' : colorCode.backgroundColor;

              if (highlightCorridorId === segment.Corridor_ID) {
                zoomToSegment = convertMultiLineStringToGraphic(feature, segment);
                setCustomHighlightCorridor(zoomToSegment);
                return convertMultiLineStringToGraphic(feature, { ...segment, Scr_Tot: getOverallScore(segment) });
              }
              return convertMultiLineStringToGraphic(feature, { ...segment, Scr_Tot: getOverallScore(segment) }, color);
            }
          }) ?? [];
        graphics.push(...segmentGraphics);
      });
      graphicsLayerRef.current.addMany(graphics);
      if (zoomToSegment) {
        viewRef.current.goTo({
          target: zoomToSegment,
          zoom: 7,
          duration: 1000,
        });
      }
    }
  }, [filteredCustomSegments, highlightCorridorId, globalObject]);

  return (
    <>
      <Row style={{ maxWidth: '100%' }} className="m-0 p-0">
        <Col md={isTableView ? 5 : 7} className="m-0 p-0">
          <div ref={mapContainerRef} className="map-container" style={{ height: '83vh' }}></div>
        </Col>
        <Col md={isTableView ? 7 : 5} className="m-0 p-0">
          {isTableView ? (
            <>
              {highlightCorridorId ? (
                <CorridorInformation
                  highlightCorridorId={highlightCorridorId}
                  setHighlightCorridorId={setHighlightCorridorId}
                  onClickBack={onClickBackHandler}
                  onClickZoom={onZoomHandler}
                />
              ) : (
                <MapTableOverview
                  refreshMap={refreshMap}
                  filterSegment={filterSegment}
                  setHighlightCorridorId={setHighlightCorridorId}
                />
              )}
            </>
          ) : (
            <>
              {highlightCorridorId ? (
                <CorridorInformation
                  highlightCorridorId={highlightCorridorId}
                  setHighlightCorridorId={setHighlightCorridorId}
                  onClickBack={onClickBackHandler}
                  onClickZoom={onZoomHandler}
                />
              ) : (
                <MapOverview
                  setHighlightCorridorId={setHighlightCorridorId}
                  refreshMap={refreshMap}
                  filterSegment={filterSegment}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default AssessmentResult;
