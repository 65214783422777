import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSegmentEvalutionStore } from '../../../store/segment-evaluation/store';
import EvaluationResult from './EvaluationResult';
import './styles.css';

function EvaluationResultContainer() {
  const { selectedCorridorId } = useSegmentEvalutionStore(state => state);

  if (!selectedCorridorId) {
    return <Navigate to="/segment-evaluation" />;
  }

  return <EvaluationResult />;
}

export default EvaluationResultContainer;
