import { getColorCode, colorCodeMapper, numberFormatter, getNeedColorCode } from '../../../helper';

const headerColor = {
  textAlign: 'center',
  font: 'normal normal normal 14px Franklin Gothic',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: '1',
  backgroundColor: 'white',
  border: 'none',
  fontWeight: 'bold',
  background: '#ADB5BD 0% 0% no-repeat padding-box',
};

export const freightColumnDetails = (segmentData, selectedSwitchValue) => {
  const isNeed = selectedSwitchValue === 'Need';
  const Facility_T = segmentData?.attributes?.Facility_T;
  const I_U = segmentData?.attributes?.I_U;
  const Bound_1_B1 = segmentData?.attributes?.Bound_1_B1;
  const Bound_2_B2 = segmentData?.attributes?.Bound_2_B2;

  return {
    'Freight Index': {
      __label: `Freight Index`,
      __value: isNeed
        ? getNeedColorCode(segmentData?.attributes?.Freight_N).valueWord
        : numberFormatter(segmentData?.attributes?.Freight_I),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Freight_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Freight_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Freight_I),
              colorCodeMapper['Freight'][Facility_T]?.Freight_I[I_U],
            ),
      },
      __headerStyle: headerColor,
    },
    'Truck TTI 1': {
      __label: `<div class="mainlane-heading-right">Truck</div><br /> ${Bound_1_B1} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.TTI_trk_N1)
        : numberFormatter(segmentData?.attributes?.TTI_trk_B1),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.TTI_trk_N1).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.TTI_trk_N1).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.TTI_trk_B1),
              colorCodeMapper['Freight'][Facility_T]?.TTI_trk_B1[I_U],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'Truck TTI 2': {
      __label: `<div class="mainlane-heading-left">TTI</div><br /> ${Bound_2_B2} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.TTI_trk_N2)
        : numberFormatter(segmentData?.attributes?.TTI_trk_B2),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.TTI_trk_N2).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.TTI_trk_N2).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.TTI_trk_B2),
              colorCodeMapper['Freight'][Facility_T]?.TTI_trk_B2[I_U],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px', paddingLeft: '3px' },
    },
    'Truck PTI 1': {
      __label: `<div class="mainlane-heading-right">Truck</div><br /> ${Bound_1_B1} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.PTI_trk_N1)
        : numberFormatter(segmentData?.attributes?.PTI_trk_B1),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.PTI_trk_N1).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.PTI_trk_N1).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.PTI_trk_B1),
              colorCodeMapper['Freight'][Facility_T]?.PTI_trk_B1[I_U],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'Truck PTI 2': {
      __label: `<div class="mainlane-heading-left">PTI</div><br /> ${Bound_2_B2} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.PTI_trk_N2)
        : numberFormatter(segmentData?.attributes?.PTI_trk_B2),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.PTI_trk_N2).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.PTI_trk_N2).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.PTI_trk_B2),
              colorCodeMapper['Freight'][Facility_T]?.PTI_trk_B2[I_U],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px', paddingLeft: '3px' },
    },
    'Bridge Load Rating': {
      __label: `Bridge Load Rating`,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Bridge_Loa_N)
        : numberFormatter(segmentData?.attributes?.Bridge_Loa),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Bridge_Loa_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Bridge_Loa_N).backgroundColor
          : getColorCode(numberFormatter(segmentData?.attributes?.Bridge_Loa), colorCodeMapper['Freight'].Bridge_Loa),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'Bridge Vertical Clearance': {
      __label: `Bridge Vertical Clearance`,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Bridge_Ver_N)
        : numberFormatter(segmentData?.attributes?.Bridge_Ver),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Bridge_Ver_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Bridge_Ver_N).backgroundColor
          : getColorCode(numberFormatter(segmentData?.attributes?.Bridge_Ver), colorCodeMapper['Freight'].Bridge_Ver),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
  };
};
