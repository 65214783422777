import React, { useState } from 'react';
import './Navbar.css';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { CPT_METHODOLOGY_DOC_URL, CET_METHODOLOGY_DOC_URL } from '../../Utils/Constants';

const Navbar = () => {
  const { authState, logout } = useAuth();
  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    navigate('/');
  };

  const handleDownload = fileUrl => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'Segment Evaluation Methodology';

    // initiate the download
    try {
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.log('Error occurred while preparing the download. Please try again.');
    }
  };

  return (
    <div>
      <div className="header p-0">
        <div className="navbar navbar-expand-lg bg-grey ">
          <div className="container d-flex flex-row-reverse ">
            {authState?.isAuthenticated ? (
              <div className="d-flex">
                <a href="mailto: Curtis.Jones@txdot.gov" className="contact-us">
                  Contact Us
                </a>
                <div id="logout-button" onClick={onLogout}>
                  <span className="m-2">
                    <i className="fa-solid fa-xl fa-circle-user"></i>{' '}
                  </span>
                  Log Out
                </div>
              </div>
            ) : (
              <div className="d-flex">
                <a href="mailto: Curtis.Jones@txdot.gov" className="contact-us">
                  Contact Us
                </a>
                <Link to="/login" id="login-button" className="text-decoration-none">
                  <span className="m-2">
                    <i className="fa-solid fa-xl fa-circle-user"></i>
                  </span>
                  Log In
                </Link>
              </div>
            )}
          </div>
        </div>
        <nav className="navbar navbar-expand-lg bg-blue">
          <div className="container">
            <NavLink className="navbar-brand" href="/">
              <img src="/assets/svg/TXDOTlogo_white_small.png" alt="TxDOT Logo" height="60" />
            </NavLink>
            <button
              className="navbar-toggler navbar-dark"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse bg-blue" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
                <li className="nav-item px-4">
                  <NavLink id="menu-home" className="nav-link text-white" aria-current="page" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item px-4">
                  <NavLink
                    id="menu-corridor-assessment"
                    className="nav-link dropdown-toggle text-white"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Corridor Assessment
                  </NavLink>
                  <div id="corridor-assessment-dropdown" className={`dropdown-menu main-menu-dropdown-content `}>
                    <div className="left-panel"></div>
                    <div className="right-panel">
                      <div className="title">Corridor Assessment</div>
                      <div className="links">
                        <NavLink to="/select-corridors">Select Corridors</NavLink>
                        <NavLink to="/map-view">Map View</NavLink>
                        <NavLink to="/table-view">Table View</NavLink>
                        <NavLink
                          to="#"
                          onClick={e => {
                            e.preventDefault();
                            handleDownload(CPT_METHODOLOGY_DOC_URL);
                          }}
                        >
                          Methodology
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item px-4">
                  <NavLink
                    id="menu-segment-evaluation"
                    className="nav-link dropdown-toggle text-white"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Segment Evaluation
                  </NavLink>
                  <div id="corridor-assessment-dropdown" className={`dropdown-menu main-menu-dropdown-content `}>
                    <div className="left-panel"></div>
                    <div className="right-panel">
                      <div className="title">Segment Evaluation</div>
                      <div className="links">
                        <NavLink to="/segment-evaluation">Import Corridor</NavLink>
                        <NavLink to="/segment-evaluation-results">Evaluation Result</NavLink>
                        <NavLink
                          to="#"
                          onClick={e => {
                            e.preventDefault();
                            handleDownload(CET_METHODOLOGY_DOC_URL);
                          }}
                        >
                          Methodology
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item ps-4">
                  <NavLink id="menu-performace-tracking" className="nav-link text-white" to="/performance-tracking">
                    Performance Tracking
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
