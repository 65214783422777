import ImportCorridorView from './ImportCorridorView/ImportCorridorView';

function SegmentMapView({ mapContainerRef }) {
  return (
    <div ref={mapContainerRef} id="mapContainer" className="map-container" style={{ height: '83vh' }}>
      <ImportCorridorView />
    </div>
  );
}

export default SegmentMapView;
