import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { filter, sortBy } from 'lodash';

import CorridorDropdown from './CorridorDropdown/CorridorDropdown';
import CorridorSummary from './CorridorSummary';
import CorridorActions from './CorridorActions';
import SectionHeader from './SectionHeader';
import { getFilteredData } from './helper';

import useMapView from './Hooks/useMapView';

import { useSegmentEvalutionStore } from '../../../store/segment-evaluation/store';
import TableWithPagination from '../../../Components/TableWithPagination/TableWithPagination';
import SegmentMapView from './SegmenMapView';
import useCorridorLayer from './Hooks/useCorridorLayer';
import { zoomCorridor } from '../../../.../../Utils/MapHelpers';

import './styles.modules.css';

function ImportCorridor() {
  const [selectedCorridorData, setSelectedCorridorData] = useState([]);
  const { selectedCorridorId, selectedMode, selectedDistrict } = useSegmentEvalutionStore(state => state);
  const { allCorridorData } = useCorridorLayer();
  const { viewRef, corridorLayerRef, corridorKeyMarkerLayerRef, mapContainerRef, refreshMap, isMapLoaded } =
    useMapView();

  useEffect(() => {
    if (isMapLoaded) {
      let definitionExpression = null;

      if (selectedCorridorId) {
        definitionExpression = `HWY = '${selectedCorridorId}'`;
        if (selectedDistrict) {
          definitionExpression = `HWY = '${selectedCorridorId}' AND Districts = '${selectedDistrict}'`;
        }
      } else if (selectedDistrict) {
        definitionExpression = `Districts = '${selectedDistrict}'`;
      } else if (selectedMode === 'Statewide') {
        definitionExpression = null;
      }

      corridorLayerRef.current.definitionExpression = definitionExpression;
      corridorKeyMarkerLayerRef.current.definitionExpression = definitionExpression;

      setTimeout(() => {
        zoomCorridor(corridorLayerRef?.current, viewRef.current);
      }, 500);
    }
  }, [selectedCorridorId, selectedDistrict, selectedMode, isMapLoaded]);

  useEffect(() => {
    const objectWithDesiredDistrict = filter(allCorridorData, item =>
      selectedMode === 'District' ? item.attributes.Districts === selectedDistrict : true,
    );
    const objectsWithDesiredHWY = filter(objectWithDesiredDistrict, item => item.attributes.HWY === selectedCorridorId);
    const sortedData = sortBy(objectsWithDesiredHWY, 'attributes.Segment');
    setSelectedCorridorData(sortedData);
  }, [allCorridorData, selectedCorridorId]);

  const filteredData = useMemo(() => getFilteredData(selectedCorridorData), [selectedCorridorData]);

  return (
    <Row style={{ maxWidth: '100%' }} className="m-0 p-0">
      <Col md={3} className="m-0 p-0">
        <Row className="import-container">
          <SectionHeader refreshMap={refreshMap} />
          <CorridorDropdown allCorridorData={allCorridorData} setSelectedCorridorData={setSelectedCorridorData} />
          {selectedCorridorId && (
            <>
              <div className="customDivider"></div>
              <CorridorSummary selectedCorridorData={selectedCorridorData} />
              <TableWithPagination data={filteredData} itemsPerPage={5} isPaginationAvail={true} showPointer={false} />
              <CorridorActions refreshMap={refreshMap} />
            </>
          )}
        </Row>
      </Col>
      <Col md={9} className="m-0 p-0">
        <SegmentMapView mapContainerRef={mapContainerRef} />
      </Col>
    </Row>
  );
}

export default ImportCorridor;
