import React from 'react';
import './ErrorPage.css';

const ErrorPage = () => {
  const handleReload = () => {
    window.location.reload();
  };

  const goHome = () => {
    window.location.href = '/';
  };

  return (
    <div className="error-page-container">
      <h1 className="error-page-title">Oops! Something went wrong.</h1>
      <p className="error-page-message">
        An unexpected error has occurred. Please try reloading the page or reporting the issue.
      </p>
      <div className="d-flex">
        <button onClick={handleReload} className="error-page-button">
          Reload Page
        </button>
        <button onClick={goHome} className="error-page-button">
          Go to Home Page
        </button>
        <a href="mailto: Curtis.Jones@txdot.gov" className="error-page-button">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default ErrorPage;
