import Select from 'react-select';

import { useCorridorSelectionContext } from '../Context/CorridorSelectionContext';
import { storeSelectViewFilters } from '../../../../Utils/SelectViewHelper';
import { ROADWAY_CLASSIFICATIONS, CUSTOM_OPTIONS } from '../../../../Utils/Constants';
import { routeOrder } from '../../../../Utils/SelectViewHelper';

function FilterSelector() {
  const { filters, setFilters, setSelectAllCorridors } = useCorridorSelectionContext();

  const routeTypeSort = arr => {
    return arr.sort((a, b) => {
      const orderA = routeOrder[a.value] || Infinity;
      const orderB = routeOrder[b.value] || Infinity;
      return orderA - orderB;
    });
  };

  const onSelectViewFiltersChanged = name => selected => {
    setFilters(prev => ({ ...prev, [name]: selected }));
    storeSelectViewFilters({ ...filters, [name]: selected });
    setSelectAllCorridors(false);
  };

  const onSearch = event => {
    const value = event?.target?.value || '';
    setFilters(prev => ({ ...prev, search: value }));
    setSelectAllCorridors(false);
    storeSelectViewFilters({ ...filters, search: value });
  };

  return (
    <div className="selectors-container">
      <div className="row">
        <div className="col-lg-4 label">Route Type:</div>
        <div className="col-lg-8">
          <Select
            isMulti
            value={routeTypeSort(filters.routeTypes)}
            options={ROADWAY_CLASSIFICATIONS}
            onChange={onSelectViewFiltersChanged('routeTypes')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 label">User-Defined Corridors:</div>
        <div className="col-lg-8">
          <Select
            isMulti={false}
            value={filters?.custom}
            placeholder="No Filter"
            options={CUSTOM_OPTIONS}
            onChange={onSelectViewFiltersChanged('custom')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 label">Search By Name:</div>
        <div className="col-lg-8">
          <input
            style={{ padding: '5px 10px' }}
            type="search"
            id="search"
            value={filters?.search || ''}
            onChange={onSearch}
            placeholder="Search (Example: IH0010, IH10, or I-10)"
          />
        </div>
      </div>
    </div>
  );
}

export default FilterSelector;
