import { getColorCode, colorCodeMapper, numberFormatter, getNeedColorCode } from '../../../helper';

const headerColor = {
  textAlign: 'center',
  font: 'normal normal normal 14px Franklin Gothic',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: '1',
  backgroundColor: 'white',
  border: 'none',
  fontWeight: 'bold',
  background: '#ADB5BD 0% 0% no-repeat padding-box',
};

export const mobilityColumnDetails = (segmentData, selectedSwitchValue) => {
  const isNeed = selectedSwitchValue === 'Need';
  const Facility_T = segmentData?.attributes?.Facility_T;
  const I_U = segmentData?.attributes?.I_U;
  const Bound_1_B1 = segmentData?.attributes?.Bound_1_B1;
  const Bound_2_B2 = segmentData?.attributes?.Bound_2_B2;

  return {
    'Mobility Index': {
      __label: `Mobility Index`,
      __value: isNeed
        ? getNeedColorCode(segmentData?.attributes?.Mobility_N).valueWord
        : numberFormatter(segmentData?.attributes?.Mobility_I),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Mobility_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Mobility_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Mobility_I),
              colorCodeMapper['Mobility'][Facility_T]?.Mobility_I,
            ),
      },
      __headerStyle: headerColor,
    },
    'Mainlane Future V/C': {
      __label: `Mainlane Future V/C `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Future_VC_N)
        : numberFormatter(segmentData?.attributes?.Future_VC),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Future_VC_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Future_VC_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Future_VC),
              colorCodeMapper['Mobility'][Facility_T]?.Future_VC,
            ),
      },
      __headerStyle: headerColor,
    },
    'Existing Mainlane Peak Hour V/C 1': {
      __label: `<div class="mainlane-heading-right special-haeding"}>Existing Peak V/C Hour</div><br /> ${Bound_1_B1} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Peak_VC_N1)
        : numberFormatter(segmentData?.attributes?.Peak_VC_1),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Peak_VC_N1).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Peak_VC_N1).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Peak_VC_1),
              colorCodeMapper['Mobility'][Facility_T]?.Peak_VC_1,
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px', width: '93px' },
    },
    'Existing Mainlane Peak Hour V/C 2': {
      __label: `<div class="mainlane-heading-left">Mainlane Ratios</div><br /> ${Bound_2_B2} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Peak_VC_N2)
        : numberFormatter(segmentData?.attributes?.Peak_VC_2),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Peak_VC_N2).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Peak_VC_N2).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Peak_VC_2),
              colorCodeMapper['Mobility'][Facility_T]?.Peak_VC_2,
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px', paddingLeft: '3px', width: '93px' },
    },
    'Frontage Road V/C Existing': {
      __label: `<div class="mainlane-heading-right">Frontage V/C</div><br /> Existing `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.FrontVC_21_N)
        : numberFormatter(segmentData?.attributes?.FrontVC_21),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.FrontVC_21_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.FrontVC_21_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.FrontVC_21),
              colorCodeMapper['Mobility'][Facility_T]?.FrontVC_21,
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'Frontage Road V/C Future': {
      __label: `<div class="mainlane-heading-left lower-word">Road Ratios</div><br /> Future `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.FrontVC_41_N)
        : numberFormatter(segmentData?.attributes?.FrontVC_41),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.FrontVC_41_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.FrontVC_41_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.FrontVC_41),
              colorCodeMapper['Mobility'][Facility_T]?.FrontVC_41,
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px', paddingLeft: '0px' },
    },
    'Interchange V/C Existing': {
      __label: `<div class="mainlane-heading-right">Interchange V/C</div><br /> Existing `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Inte_VC_21_N)
        : numberFormatter(segmentData?.attributes?.Inte_VC_21),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Inte_VC_21_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Inte_VC_21_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Inte_VC_21),
              colorCodeMapper['Mobility'][Facility_T]?.Inte_VC_21,
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'Interchange V/C Future': {
      __label: `<div class="mainlane-heading-left">/Intersection <span class="lower-word">Ratios</span></div><br /> Future `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Inte_VC_41_N)
        : numberFormatter(segmentData?.attributes?.Inte_VC_41),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Inte_VC_41_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Inte_VC_41_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Inte_VC_41),
              colorCodeMapper['Mobility'][Facility_T]?.Inte_VC_41,
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px', paddingLeft: '0px' },
    },
    'TTI 1': {
      __label: `<div class="mainlane-heading-right">Directional</div><br /> ${Bound_1_B1} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.TTI_all_N1)
        : numberFormatter(segmentData?.attributes?.TTI_all_B1),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.TTI_all_N1).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.TTI_all_N1).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.TTI_all_B1),
              colorCodeMapper['Mobility'][Facility_T]?.TTI_all_B1[I_U],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'TTI 2': {
      __label: `<div class="mainlane-heading-left">TTI</div><br /> ${Bound_2_B2} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.TTI_all_N2)
        : numberFormatter(segmentData?.attributes?.TTI_all_B2),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.TTI_all_N2).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.TTI_all_N2).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.TTI_all_B2),
              colorCodeMapper['Mobility'][Facility_T]?.TTI_all_B2[I_U],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'PTI 1': {
      __label: `<div class="mainlane-heading-right">Directional</div><br /> ${Bound_1_B1} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.PTI_all_N1)
        : numberFormatter(segmentData?.attributes?.PTI_all_B1),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.PTI_all_N1).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.PTI_all_N1).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.PTI_all_B1),
              colorCodeMapper['Mobility'][Facility_T]?.PTI_all_B1[I_U],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'PTI 2': {
      __label: `<div class="mainlane-heading-left"> PTI</div><br /> ${Bound_2_B2} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.PTI_all_N2)
        : numberFormatter(segmentData?.attributes?.PTI_all_B2),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.PTI_all_N2).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.PTI_all_N2).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.PTI_all_B2),
              colorCodeMapper['Mobility'][Facility_T]?.PTI_all_B2[I_U],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
  };
};
