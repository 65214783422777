function ImportCorridorView() {
  return (
    <div
      className="filters-containers d-flex gap-5 mx-5 p-3 justify-content-between align-items-center"
      style={{ width: "90%" }}
    >
      <div>
        <div className="map-title d-flex justify-content-center align-items-center">
          <div className="title">Segment Evaluation</div>
        </div>
      </div>
    </div>
  );
}

export default ImportCorridorView;
