import React, { Fragment } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from '../Components/Navbar/Navbar';
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb';

const CommonLayout = () => {
  const loc = useLocation();
  return (
    <Fragment>
      <div className="cover-container d-flex flex-column">
        <Navbar />
        {loc.pathname.split('/')[1] && <Breadcrumb />}
      </div>

      <Outlet />
    </Fragment>
  );
};

export default CommonLayout;
