import CorridorCreation from './index';
import { CorridorSelectionProvider } from './Context/CorridorSelectionContext';

import './CorridorSelection.css';

function CorridorSelectionContainer() {
  return (
    <CorridorSelectionProvider>
      <CorridorCreation />
    </CorridorSelectionProvider>
  );
}

export default CorridorSelectionContainer;
