import React, { useState, useEffect, useRef } from 'react';
import './MultiSelectComponent.css';

const MultiSelectComponent = props => {
  const { data, onSelect, preselectedOptions, onReset } = props;
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownMenuRef = useRef(null);

  useEffect(() => {
    if (data) {
      setOptions(data);
    }
    if (preselectedOptions && preselectedOptions !== undefined && preselectedOptions.length > 0) {
      const updatedSelectedOptions = options.filter(option => preselectedOptions.includes(option.value));
      setSelectedOptions(updatedSelectedOptions);
    }
  }, [data, preselectedOptions, options]);

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState);
    if (!dropdownOpen && dropdownMenuRef.current) {
      dropdownMenuRef.current.scrollTop = 0;
    }
  };

  const handleSelect = option => {
    console.log('### handleSelect ###', option);
    const isSelected = selectedOptions.some(item => item.value === option.value);

    let updatedSelectedOptions;
    if (isSelected) {
      updatedSelectedOptions = selectedOptions.filter(item => item.value !== option.value);
    } else {
      if (selectedOptions && selectedOptions !== undefined && selectedOptions.length < 5) {
        updatedSelectedOptions = [...selectedOptions, option];
      } else {
        updatedSelectedOptions = [...selectedOptions];
      }
    }

    setSelectedOptions(updatedSelectedOptions);
    onSelect(updatedSelectedOptions);
  };

  const filteredOptions = options.filter(option => option.label.toLowerCase().includes(searchTerm.toLowerCase()));

  const displaySelectedOptions = () => {
    if (selectedOptions.length === 0) {
      return 'Select Corridors';
    } else {
      let selectedItems = [];
      if (selectedOptions.length > 1) {
        selectedItems = [];
        selectedItems.push(<span key="more">{'(Multiple values)'}</span>);
      } else if (selectedOptions.length === 1) {
        selectedItems = [];
        selectedItems = selectedOptions.slice(0, 1).map((option, index) => <span key={index}>{option.label} </span>);
      }
      return selectedItems ?? 'Select Corridors';
    }
  };
  const reset = event => {
    event.preventDefault();
    setSelectedOptions([]);
    setSearchTerm('');
    onReset();
  };
  return (
    <div className="dropdown-wrapper">
      <form>
        <div className="dropdown">
          <button className="form-select btn multi-select-dropdown" type="button" onClick={toggleDropdown}>
            <span>
              {selectedOptions && selectedOptions !== undefined && selectedOptions.length > 0
                ? displaySelectedOptions()
                : 'Select Corridors'}
            </span>
          </button>
          <div
            className={`dropdown-menu multi-select-dropdown-items ${dropdownOpen ? 'show' : ''}`}
            ref={dropdownMenuRef}
          >
            <div className="input-group">
              <input
                type="text"
                className="form-control shadow-none multi-select-dropdown-input"
                placeholder="Search.."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />

              <span className="input-group-text multi-select-dropdown-search-icon">
                <i className="fa fa-search" aria-hidden="true"></i>
              </span>
              <button className="btn btn-sm reset-btn" onClick={reset}>
                Reset
              </button>
            </div>
            <div className="multi-select-dropdown-list">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option, index) => (
                  <li className="multi-select-dropdown-list-item" key={index + 'dd'}>
                    <div
                      key={index}
                      className="form-check"
                      onClick={() => handleSelect(option)}
                      style={{
                        pointerEvents:
                          selectedOptions.length >= 5 && !selectedOptions.some(item => item.value === option.value)
                            ? 'none'
                            : 'auto',
                        opacity:
                          selectedOptions.length >= 5 && !selectedOptions.some(item => item.value === option.value)
                            ? 0.7
                            : 1,
                      }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectedOptions.some(item => item.value === option.value)}
                        onChange={() => {}}
                      />
                      <label className="form-check-label">{option.label}</label>
                    </div>
                  </li>
                ))
              ) : (
                <div className="dropdown-header">No options found</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MultiSelectComponent;
