function TableLegend() {
  return (
    <div className="table-legend">
      <span>Trend:</span>
      <div className="legend-item">
        <span className="trend-icon icon-up">
          <i className="fa-solid fa-arrow-up"></i>
        </span>
        <span>Increasing</span>
      </div>
      <div className="legend-item">
        <span className="trend-icon icon-down">
          <i className="fa-solid fa-arrow-down"></i>
        </span>
        <span>Decreasing</span>
      </div>
      <div className="legend-item">
        <span className="trend-icon">
          <i className="fa-solid fa-minus"></i>
        </span>
        <span>Stable</span>
      </div>
    </div>
  );
}

export default TableLegend;
