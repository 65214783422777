function BoxElement({
  description,
  value1,
  value2,
  valueTitle1,
  valueTitle2,
  colorCode1,
  colorCode2,
}) {
  return (
    <div className="indicaotr-side-box d-flex justify-content-around align-items-center flex-column gap-3">
      <div className="d-flex gap-1">
        {value1 && (
          <div
            className="box-value box-full-value2 text-center d-flex flex-column justify-content-center align-items-center"
            style={{ backgroundColor: colorCode1 }}
          >
            <span className="fw-bold">{value1}</span>
            {valueTitle1 && <span className="value">{valueTitle1}</span>}
          </div>
        )}
        {value2 && (
          <div
            className="box-value box-full-value2  text-center d-flex flex-column justify-content-center align-items-center"
            style={{ backgroundColor: colorCode2 }}
          >
            <span className="fw-bold">{value2}</span>
            {valueTitle2 && <span className="value">{valueTitle2}</span>}
          </div>
        )}
      </div>
      <div className="indicator-description">{description}</div>
    </div>
  );
}

export default BoxElement;
