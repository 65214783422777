import { getColorCode, colorCodeMapper, numberFormatter, getNeedColorCode, convertToPercentage } from '../../../helper';

const headerColor = {
  textAlign: 'center',
  font: 'normal normal normal 14px Franklin Gothic',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: '1',
  backgroundColor: 'white',
  border: 'none',
  fontWeight: 'bold',
  background: '#ADB5BD 0% 0% no-repeat padding-box',
};

export const bridgeColumnDetails = (segmentData, selectedSwitchValue) => {
  const isNeed = selectedSwitchValue === 'Need';

  return {
    'Bridge Index': {
      __label: `Bridge Index`,
      __value: isNeed
        ? getNeedColorCode(segmentData?.attributes?.Bridge_N).valueWord
        : numberFormatter(segmentData?.attributes?.Bridge_I),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Bridge_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Bridge_N).backgroundColor
          : getColorCode(numberFormatter(segmentData?.attributes?.Bridge_I), colorCodeMapper['Bridge']['Bridge_I']),
      },
      __headerStyle: headerColor,
    },
    'Bridge Sufficiency': {
      __label: `Bridge Sufficiency `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Bridge_Suf_N)
        : numberFormatter(segmentData?.attributes?.Bridge_Suf),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Bridge_Suf_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Bridge_Suf_N).backgroundColor
          : getColorCode(numberFormatter(segmentData?.attributes?.Bridge_Suf), colorCodeMapper['Bridge']['Bridge_Suf']),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'Bridge Rating': {
      __label: `Bridge Rating `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Bridge_Rat_N)
        : numberFormatter(segmentData?.attributes?.Bridge_Rat),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Bridge_Rat_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Bridge_Rat_N).backgroundColor
          : getColorCode(numberFormatter(segmentData?.attributes?.Bridge_Rat), colorCodeMapper['Bridge']['Bridge_Rat']),
      },
      __headerStyle: { ...headerColor, paddingLeft: '1px' },
    },
    'Culvert Rating': {
      __label: `Culvert Rating `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Culvert_Ra_N)
        : numberFormatter(segmentData?.attributes?.Culvert_Ra),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Culvert_Ra_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Culvert_Ra_N).backgroundColor
          : getColorCode(numberFormatter(segmentData?.attributes?.Culvert_Ra), colorCodeMapper['Bridge']['Culvert_Ra']),
      },
      __headerStyle: { ...headerColor, paddingRight: '1px' },
    },
    'Functional Obsolete Bridges': {
      __label: `Functional Obsolete Bridges `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Obsolete_B_N)
        : convertToPercentage(segmentData?.attributes?.Obsolete_B),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Obsolete_B_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Obsolete_B_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Obsolete_B) * 100,
              colorCodeMapper['Bridge']['Obsolete_B'],
            ),
      },
      __headerStyle: { ...headerColor, paddingLeft: '1px' },
    },
  };
};
