import { Navigate } from 'react-router-dom';

import { localStorageGetWithExpiry } from '../../../Utils/LocalStorage';

import AssessmentResult from './AssessmentResult';

function AssessmentResultContainer({ isTableView }) {
  const selectedSegments = localStorageGetWithExpiry('selectedSegments');

  if (!selectedSegments) {
    return <Navigate to="/select-corridors" />;
  }

  return <AssessmentResult isTableView={isTableView} />;
}

export default AssessmentResultContainer;
