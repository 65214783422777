import { useEffect, useCallback, useState } from 'react';

import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

import { SEGMENT_EVALUTION_SERVICE_URL } from '../../../../../Utils/Constants';

import { createPopupTemplate_Segment } from '../../../../../Utils/Functions';

import { getlineLabelClass, queryFeatureLayer } from './helper';
import { useSegmentEvalutionStore } from '../../../../../store/segment-evaluation/store';

function useCorridorLayer() {
  const [allCorridorData, setAllCorridorData] = useState([]);
  const [corridorLayer, setCorridorLayer] = useState(null);
  const { selectedCorridorId, selectedDistrict } = useSegmentEvalutionStore(state => state);

  let definitionExpression = selectedCorridorId ? `HWY = '${selectedCorridorId}'` : null;
  if (selectedDistrict) {
    definitionExpression = `HWY = '${selectedCorridorId}' AND Districts = '${selectedDistrict}'`;
  }

  const generateLayer = useCallback(async () => {
    const lineLabelInfo = getlineLabelClass();
    const layer = new FeatureLayer({
      url: SEGMENT_EVALUTION_SERVICE_URL,
      id: 'Segments',
      outFields: ['*', 'Weighted'],
      title: 'Segments',
      popupTemplate: createPopupTemplate_Segment(),
      labelingInfo: [lineLabelInfo],
      definitionExpression: definitionExpression,
    });
    const data = await queryFeatureLayer(layer);
    setAllCorridorData(data);
    setCorridorLayer(layer);
  }, [selectedCorridorId, definitionExpression]);

  useEffect(() => {
    generateLayer();
  }, [generateLayer]);

  return {
    corridorLayer,
    allCorridorData,
  };
}

export default useCorridorLayer;
