import { useEffect, useCallback, useState } from 'react';

import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

import { SEGMENT_EVALUTION_SERVICE_URL } from '../../../../../Utils/Constants';

import { createPopupTemplate_Segment } from '../../../../../Utils/Functions';

import { getlineLabelClass, getRendererConfig, queryFeatureLayer } from './helper';

function useCorridorLayer() {
  const [allCorridorData, setAllCorridorData] = useState([]);
  const [corridorLayer, setCorridorLayer] = useState(null);

  const generateLayer = useCallback(async () => {
    const rendererConfig = getRendererConfig();
    const lineLabelInfo = getlineLabelClass();
    const layer = new FeatureLayer({
      url: SEGMENT_EVALUTION_SERVICE_URL,
      id: 'Segments',
      outFields: ['*', 'Weighted'],
      title: 'Segments',
      renderer: rendererConfig,
      popupTemplate: createPopupTemplate_Segment(),
      labelingInfo: [lineLabelInfo],
    });
    const data = await queryFeatureLayer(layer);
    setAllCorridorData(data);
    setCorridorLayer(layer);
  }, []);

  useEffect(() => {
    generateLayer();
  }, [generateLayer]);

  return {
    corridorLayer,
    allCorridorData,
  };
}

export default useCorridorLayer;
