/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "./ChartComponent.css";

const LineChart = ({ className, data, category, maxData }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const getMax = () => {
    return category === "Overall" ? 100 : 10;
  };
  const getStep = () => {
    return category === "Overall" ? 3 : 2;
  };
  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");
    chartInstance.current = new Chart(ctx, {
      type: "line",
      data: data,
      options: {
        skipNull: true,
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 1.5,
          },
        },

        scales: {
          x: {
            beginAtZero: true,
            display: true,

            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              padding: 20,
              font: {
                size: 14,
                weight: 600,
              },
            },
          },
          y: {
            beginAtZero: true,
            display: true,
            min: 0,
            max: getMax(),
            ticks: {
              padding: 12,
              stepSize: getStep(),
            },
            grid: {
              drawTicks: false,
              drawBorder: false,
              color: "rgba(0, 0, 0, 0.1)",
              lineWidth: 1,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return (
    <>
      <div className="chart-card">
        <div className="chart-header">{category}</div>
        <div className="chart-container">
          <canvas className={className} ref={chartRef}></canvas>
          <div className="y-axis-header">
            {category} (0-{maxData})
          </div>
        </div>
      </div>
    </>
  );
};

export default LineChart;
