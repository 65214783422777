import { Navigate } from 'react-router-dom';

import { localStorageGetWithExpiry } from '../../Utils/LocalStorage';

import PerformanceTracking from './PerformanceTracking';

import './PerformanceTracking.css';

function PerformanceTrackingContainer() {
  const selectedSegments = localStorageGetWithExpiry('selectedSegments');

  if (!selectedSegments) {
    return <Navigate to="/select-corridors" />;
  }

  return <PerformanceTracking />;
}

export default PerformanceTrackingContainer;
