import EvaluationResultView from './EvaluationResultView';

function SegmentMapView({ mapContainerRef, setIsFullMapView, isFullMapView, highlightSegment }) {
  return (
    <div ref={mapContainerRef} id="mapContainer" className="map-container" style={{ height: '83vh' }}>
      <EvaluationResultView
        setIsFullMapView={setIsFullMapView}
        isFullMapView={isFullMapView}
        highlightSegment={highlightSegment}
      />
    </div>
  );
}

export default SegmentMapView;
