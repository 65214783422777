import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useSegmentEvalutionStore = create()(
  devtools((set) => ({
    selectedCorridorId: "",
    selectedSwitchValue: "Need",
    selectedDropDownValue: "Overall",
    selectedMode: "Statewide",
    selectedDistrict : "",
    selectedMPO : "",
    setSelectedCorridorId: (selectedCorridorId) =>
      set({ selectedCorridorId }, false, "setSelectedCorridorId"),
    setSelectedSwitchValue: (selectedSwitchValue) =>
      set({ selectedSwitchValue }, false, "setSelectedSwitchValue"),
    setSelectedDropDownValue: (selectedDropDownValue) =>
      set({ selectedDropDownValue }, false, "setSelectedDropDownValue"),
    setSelectedMode: (selectedMode) =>
      set({ selectedMode }, false, "setSelectedMode"),
    setSelectedDistrict: (selectedDistrict) =>
      set({ selectedDistrict }, false, "setSelectedDistrict"),
    setSelectedMPO: (selectedMPO) =>
      set({ selectedMPO }, false, "setSelectedMPO"),
  }))
);
