import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import TableExport from '../TableExport/TableExport';

const TableWithPagination = ({
  globalObject,
  data,
  itemsPerPage,
  indexColumn,
  onRowSelected,
  customWeights,
  isPaginationAvail,
  showGlossaryButton = false,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const containerRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [prevX, setPrevX] = useState(0);
  // Calculate total pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Calculate current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Extract the column names from the first row
  const columns = useMemo(() => Object.keys(data[0] || []), [data]);

  // Handle page change
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  // Generate an array of page numbers to be displayed in pagination
  const getPageNumbers = useCallback(() => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(i);
        }
      } else if (currentPage > totalPages - 3) {
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pageNumbers.push(i);
        }
      }
    }

    return pageNumbers;
  }, [currentPage, totalPages]);

  const handleMouseDown = event => {
    setIsMouseDown(true);
    setPrevX(event.clientX);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseMove = event => {
    if (isMouseDown) {
      const delta = event.clientX - prevX;
      containerRef.current.scrollLeft += delta;
      setPrevX(event.clientX);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isMouseDown]);

  useEffect(() => {
    setCurrentPage(1);
  }, [data.length]);

  return (
    <div className="container table-container" ref={containerRef} onMouseDown={handleMouseDown}>
      <Table className="mt-3">
        {/* Render table headers */}
        <thead>
          <tr>
            {indexColumn && <th style={indexColumn?.__style}>{indexColumn.name}</th>}

            {columns.map((column, index) => {
              return (
                !currentItems?.[0]?.[column]?.__hide && (
                  <th
                    key={index}
                    style={{ ...currentItems?.[0]?.[column]?.__headerStyle }}
                    dangerouslySetInnerHTML={{
                      __html: currentItems?.[0]?.[column]?.__label || column,
                    }}
                    onClick={() =>
                      currentItems?.[0]?.[column]?.__onClick && currentItems?.[0]?.[column]?.__onClick(column)
                    }
                  />
                )
              );
            })}
          </tr>
        </thead>
        {/* Render table rows */}
        <tbody>
          {currentItems.map((item, index) => {
            return (
              <>
                <tr
                  onClick={e => onRowSelected && onRowSelected(e, item)}
                  key={index}
                  style={onRowSelected ? { cursor: 'pointer' } : {}}
                  className="table-row"
                >
                  {indexColumn && (
                    <th style={{ ...item['index']?.__style }}>
                      {/* {item["index"]?.__value || indexOfFirstItem + index + 1} */}
                      {indexOfFirstItem + index + 1}
                    </th>
                  )}
                  {columns?.map((column, colIndex) => {
                    if (typeof item[column] === 'object' && item[column].__hide) {
                      return;
                    } else if (typeof item[column] !== 'object') {
                      return (
                        <td style={{ ...item[column]?.__style }} key={colIndex}>
                          {item[column]}
                        </td>
                      );
                    } else if (item[column]?.__innerHTMLValue) {
                      return (
                        <td
                          style={{ ...item[column]?.__style }}
                          key={colIndex}
                          dangerouslySetInnerHTML={{
                            __html: item[column]?.__innerHTMLValue,
                          }}
                          onClick={() => item[column]?.__onClick && item[column]?.__onClick(item[column])}
                        >
                          {item[column].__value}
                        </td>
                      );
                    } else {
                      return (
                        <td style={{ ...item[column]?.__style }} key={colIndex}>
                          {item[column].__value}
                        </td>
                      );
                    }
                  })}
                </tr>
              </>
            );
          })}
        </tbody>
      </Table>
      {/* Render pagination */}
      <div className="row">
        <div className="col">
          {isPaginationAvail && (
            <Pagination>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink onClick={() => handlePageChange(1)}>First</PaginationLink>
              </PaginationItem>
              {getPageNumbers().map(pageNumber => (
                <PaginationItem key={pageNumber} active={pageNumber === currentPage}>
                  <PaginationLink onClick={() => handlePageChange(pageNumber)}>{pageNumber}</PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink onClick={() => handlePageChange(totalPages)}>Last</PaginationLink>
              </PaginationItem>
            </Pagination>
          )}
        </div>
        <div className="col d-flex justify-content-end">
          {showGlossaryButton && (
            <button className="glossary-table-button" onClick={() => console.log('glossary clicked')}>
              Glossary
            </button>
          )}
          {globalObject && <TableExport data={data} />}
        </div>
      </div>
    </div>
  );
};

export default TableWithPagination;
