import React, { useMemo } from 'react';
import { filter } from 'lodash';

import { DROPDOWN_LIST } from '../../../../../../Utils/Constants';
import { useSegmentEvalutionStore } from '../../../../../../store/segment-evaluation/store';
import { getNeedValue } from '../../../../../../Utils/Functions';

function NeedSummary({ allCorridorData }) {
  const { selectedDropDownValue } = useSegmentEvalutionStore(state => state);

  const memoizedData = useMemo(() => {
    const needValue = getNeedValue(selectedDropDownValue, 'Need');

    const filteredValue = filter(DROPDOWN_LIST, obj => obj.value === selectedDropDownValue);
    const needSegmentMap = {
      High_Need_Segments: { label: 'High Need Segments', value: 0, backgroundColor: '#25306E', color: '#FFFFFF' },
      Medium_Need_Segments: { label: 'Medium Need Segments', value: 0, backgroundColor: '#81B4E1' },
      Low_Need_Segments: { label: 'Low Need Segments', value: 0, backgroundColor: '#D9F1FD' },
    };

    allCorridorData.forEach(item => {
      const amount = item.attributes?.[needValue];
      if ((amount >= 0 && amount < 0.5) || amount === 'N/A') {
        // Do nothing
      } else if (amount >= 0.5 && amount < 1.5) {
        needSegmentMap.Low_Need_Segments.value++;
      } else if (amount >= 1.5 && amount < 2.5) {
        needSegmentMap.Medium_Need_Segments.value++;
      } else if (amount >= 2.5) {
        needSegmentMap.High_Need_Segments.value++;
      }
    });

    return {
      ...filteredValue[0],
      countLists: Object.values(needSegmentMap),
      value: `${filteredValue[0].value} ${selectedDropDownValue === 'Overall' ? 'Need' : 'Index'}`,
    };
  }, [allCorridorData, selectedDropDownValue]);

  return (
    <div className="segment-overview-section mx-3 d-flex justify-content-around">
      <div className="d-flex justify-content-center align-items-center gap-3 w-25 segment-overview-section-img">
        <div className="segment-img d-flex justify-content-center align-items-center position-relative">
          <img src={memoizedData?.img_url} alt={memoizedData?.value} className="position-absolute" />
        </div>
        <div>
          <p className="type-title m-0">{memoizedData?.value}</p>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center flex-wrap gap-3">
        {memoizedData?.countLists.map((item, key) => {
          return (
            <div
              key={key}
              className="box box-1 d-flex justify-content-center align-items-center flex-column"
              style={{
                backgroundColor: item.backgroundColor,
              }}
            >
              <div
                className="segment-value"
                style={{
                  color: item?.color,
                }}
              >
                {item?.value}
              </div>
              <div
                className="segment-detail"
                style={{
                  color: item?.color,
                }}
              >
                {item?.label}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default NeedSummary;
