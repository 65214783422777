import { getColorCode, colorCodeMapper, numberFormatter, convertToPercentage } from '../../../../helper';

import BoxElement from '../BoxElement';

function BridgeIndex({ segmentData }) {
  const attributesToMap = {
    Bridge_I: {
      value: numberFormatter(segmentData?.attributes?.Bridge_I),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Bridge_I),
        colorCodeMapper['Bridge']['Bridge_I'],
      ),
    },
    Bridge_Suf: {
      value: numberFormatter(segmentData?.attributes?.Bridge_Suf),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Bridge_Suf),
        colorCodeMapper['Bridge']['Bridge_Suf'],
      ),
    },
    Bridge_Rat: {
      value: numberFormatter(segmentData?.attributes?.Bridge_Rat),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Bridge_Rat),
        colorCodeMapper['Bridge']['Bridge_Rat'],
      ),
    },
    Culvert_Ra: {
      value: numberFormatter(segmentData?.attributes?.Culvert_Ra),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Culvert_Ra),
        colorCodeMapper['Bridge']['Culvert_Ra'],
      ),
    },
    Obsolete_B: {
      value: convertToPercentage(segmentData?.attributes?.Obsolete_B),
      colorCode: getColorCode(
        numberFormatter(segmentData?.attributes?.Obsolete_B) * 100,
        colorCodeMapper['Bridge']['Obsolete_B'],
      ),
    },
    NoB: {
      value: String(numberFormatter(segmentData?.attributes?.NoB, 1)),
      colorCode: getColorCode(numberFormatter(segmentData?.attributes?.NoB), colorCodeMapper['Bridge']['NoB']),
    },
    NoFOB: {
      value: String(numberFormatter(segmentData?.attributes?.NoFOB, 1)),
      colorCode: getColorCode(numberFormatter(segmentData?.attributes?.NoFOB, 0), colorCodeMapper['Bridge']['NoFOB']),
    },
  };

  return (
    <div className="indicator-rectengle-section mx-3 d-flex justify-content-start align-items-center pb-1 position-relative">
      <div className="indicator-box d-flex justify-content-center align-items-center flex-column gap-2">
        <div className="indicator-img">
          <img className="card-img-top" src="/assets/svg/Bridge.svg" alt="Pavement" />
        </div>
        <div className="indicator-type fw-bold">Bridge Index</div>
        <div className="indicator-value" style={{ color: attributesToMap['Bridge_I'].colorCode }}>
          {attributesToMap['Bridge_I'].value}
        </div>
      </div>
      <div className="indicator-side-detail d-flex justify-content-start align-items-center position-absolute">
        <BoxElement
          description="Bridge Sufficiency"
          value1={attributesToMap['Bridge_Suf'].value}
          colorCode1={attributesToMap['Bridge_Suf'].colorCode}
        />
        <BoxElement
          description="Bridge Rating"
          value1={attributesToMap['Bridge_Rat'].value}
          colorCode1={attributesToMap['Bridge_Rat'].colorCode}
        />
        <BoxElement
          description="Culvert Rating"
          value1={attributesToMap['Culvert_Ra'].value}
          colorCode1={attributesToMap['Culvert_Ra'].colorCode}
        />
        <BoxElement
          description="Functionally Obsolete Bridges"
          value1={attributesToMap['Obsolete_B'].value}
          colorCode1={attributesToMap['Obsolete_B'].colorCode}
        />
        <BoxElement
          description="Bridge Quantity"
          value1={attributesToMap['NoB'].value}
          colorCode1={attributesToMap['NoB'].colorCode}
        />
        <BoxElement
          description="Structurally Decificient Bridges Quantity"
          value1={attributesToMap['NoFOB'].value}
          colorCode1={attributesToMap['NoFOB'].colorCode}
        />
      </div>
    </div>
  );
}

export default BridgeIndex;
