import { useEffect, useCallback, useState } from 'react';

import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

import { SEGMENT_EVALUTION_KEY_MARKERS_SERVICE_URL } from '../../../../../Utils/Constants';
import { useSegmentEvalutionStore } from '../../../../../store/segment-evaluation/store';

import { getRendererConfig } from './helper';

function useCorridorKeyMarkerLayer() {
  const [corridorKeyMarkersLayer, setCorridorKeyMarkersLayer] = useState(null);
  const { selectedCorridorId, selectedDistrict } = useSegmentEvalutionStore(state => state);

  let definitionExpression = selectedCorridorId ? `HWY = '${selectedCorridorId}'` : null;
  if (selectedDistrict) {
    definitionExpression = `HWY = '${selectedCorridorId}' AND Districts = '${selectedDistrict}'`;
  }

  const generateLayer = useCallback(() => {
    const rendererConfig = getRendererConfig();
    const layer = new FeatureLayer({
      url: SEGMENT_EVALUTION_KEY_MARKERS_SERVICE_URL,
      outFields: ['*'],
      title: 'Segment Breakpoints',
      renderer: rendererConfig,
      definitionExpression: definitionExpression,
    });
    setCorridorKeyMarkersLayer(layer);
  }, [selectedCorridorId]);

  useEffect(() => {
    generateLayer();
  }, [generateLayer]);

  return {
    corridorKeyMarkersLayer,
  };
}

export default useCorridorKeyMarkerLayer;
