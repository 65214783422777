import "./LoadingSpinner.css";

const LoadingSpinner = ({ label = "Loading..."}) => {
  return (
    <div className="spinner-container">
      <div className="spinner"></div>
      <div className="label">{label}</div>
    </div>
  );
};

export default LoadingSpinner;