import { useMemo } from 'react';

import { DROPDOWN_LIST } from '../../../../../../Utils/Constants';
import { useSegmentEvalutionStore } from '../../../../../../store/segment-evaluation/store';
import { getNeedValue } from '../../../../../../Utils/Functions';
import { getIndexColorCode } from '../../../../helper';

const GOOD = '#92d050';
const FAIR = '#fec000';
const POOR = '#ff0000';

function PerformanceSummary({ allCorridorData }) {
  const { selectedDropDownValue, selectedSwitchValue } = useSegmentEvalutionStore(state => state);

  const memoizedData = useMemo(() => {
    const needValue = getNeedValue(selectedDropDownValue, 'Performance');
    const filteredValue = DROPDOWN_LIST.filter(obj => obj.value === selectedDropDownValue);

    const performanceSegmentCount = {
      Poor_Performance: {
        label: 'Poor Performance',
        value: 0,
        backgroundColor: POOR,
        color: '#FFFFFF',
      },
      Fair_Performance: {
        label: 'Fair Performance',
        value: 0,
        backgroundColor: FAIR,
      },
      Good_Performance: {
        label: 'Good Performance',
        value: 0,
        backgroundColor: GOOD,
      },
    };

    allCorridorData.forEach(corridor => {
      const { backgroundColor } = getIndexColorCode(
        corridor?.attributes?.[needValue],
        selectedDropDownValue,
        selectedSwitchValue,
        corridor,
      );
      if (backgroundColor === POOR) {
        performanceSegmentCount['Poor_Performance'].value++;
      } else if (backgroundColor === FAIR) {
        performanceSegmentCount['Fair_Performance'].value++;
      } else if (backgroundColor === GOOD) {
        performanceSegmentCount['Good_Performance'].value++;
      }
    });

    return {
      ...filteredValue[0],
      countLists: Object.values(performanceSegmentCount),
      value: `${filteredValue[0].value} Index`,
    };
  }, [allCorridorData, selectedDropDownValue, selectedSwitchValue]);

  return (
    <div className="segment-overview-section mx-3 d-flex justify-content-around">
      <div className="d-flex justify-content-center align-items-center gap-3 w-25 segment-overview-section-img">
        <div className="segment-img d-flex justify-content-center align-items-center position-relative">
          <img src={memoizedData?.img_url} alt={memoizedData?.value} className="position-absolute" />
        </div>
        <div>
          <p className="type-title m-0">{memoizedData?.value}</p>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center flex-wrap gap-3">
        {memoizedData?.countLists.map((item, key) => {
          return (
            <div
              key={key}
              className="box box-1 d-flex justify-content-center align-items-center flex-column"
              style={{
                backgroundColor: item.backgroundColor,
              }}
            >
              <div
                className="segment-value"
                style={{
                  color: item?.color,
                }}
              >
                {item?.value}
              </div>
              <div
                className="segment-detail"
                style={{
                  color: item?.color,
                }}
              >
                {item?.label}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PerformanceSummary;
