import { getColorCode, colorCodeMapper, numberFormatter, getNeedColorCode, convertToPercentage } from '../../../helper';

const headerColor = {
  textAlign: 'center',
  font: 'normal normal normal 14px Franklin Gothic',
  letterSpacing: '0px',
  color: '#FFFFFF',
  opacity: '1',
  backgroundColor: 'white',
  border: 'none',
  fontWeight: 'bold',
  background: '#ADB5BD 0% 0% no-repeat padding-box',
};

export const pavementColumnDetails = (segmentData, selectedSwitchValue) => {
  const isNeed = selectedSwitchValue === 'Need';
  const Bound_1_B1 = segmentData?.attributes?.Bound_1_B1;
  const Bound_2_B2 = segmentData?.attributes?.Bound_2_B2;

  return {
    'Pavement Index': {
      __label: `Pavement Index`,
      __value: isNeed
        ? getNeedColorCode(segmentData?.attributes?.Pavement_N).valueWord
        : numberFormatter(segmentData?.attributes?.Pavement_I),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Pavement_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Pavement_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Pavement_I),
              colorCodeMapper['Pavement']['Pavement_I'],
            ),
      },
      __headerStyle: headerColor,
    },
    'Distress NB': {
      __label: `<div class="mainlane-heading-right">Mainlane Distress</div><br /> ${Bound_1_B1}`,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Distress_N1)
        : numberFormatter(segmentData?.attributes?.Distress_1),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Distress_N1).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Distress_N1).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Distress_1),
              colorCodeMapper['Pavement']['Distress_1'],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '7px' },
    },
    'Distress SB': {
      __label: `<div class="mainlane-heading-left">Directional Score</div><br /> ${Bound_2_B2}`,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Distress_N2)
        : numberFormatter(segmentData?.attributes?.Distress_2),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Distress_N2).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Distress_N2).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Distress_2),
              colorCodeMapper['Pavement']['Distress_2'],
            ),
      },
      __headerStyle: { ...headerColor, paddingLeft: '1px' },
    },
    'Ride NB': {
      __label: `<div class="mainlane-heading-right">Mainlane Ride</div><br /> ${Bound_1_B1}`,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Ride_Sc_N1)
        : numberFormatter(segmentData?.attributes?.Ride_Sc_1),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Ride_Sc_N1).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Ride_Sc_N1).backgroundColor
          : getColorCode(numberFormatter(segmentData?.attributes?.Ride_Sc_1), colorCodeMapper['Pavement']['Ride_Sc_1']),
      },
      __headerStyle: { ...headerColor, paddingRight: '7px' },
    },
    'Ride SB': {
      __label: `<div class="mainlane-heading-left">Directional Score</div><br /> ${Bound_2_B2} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Ride_Sc_N2)
        : numberFormatter(segmentData?.attributes?.Ride_Sc_2),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Ride_Sc_N2).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Ride_Sc_N2).backgroundColor
          : getColorCode(numberFormatter(segmentData?.attributes?.Ride_Sc_2), colorCodeMapper['Pavement']['Ride_Sc_2']),
      },
      __headerStyle: { ...headerColor, paddingLeft: '1px' },
    },
    'Condition NB': {
      __label: `<div class="mainlane-heading-right">Frontage Condition</div><br /> ${Bound_1_B1} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Frontage_N1)
        : numberFormatter(segmentData?.attributes?.Frontage_1),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Frontage_N1).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Frontage_N1).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Frontage_1),
              colorCodeMapper['Pavement']['Frontage_1'],
            ),
      },
      __headerStyle: { ...headerColor, paddingRight: '7px' },
    },
    'Condition SB': {
      __label: `<div class="mainlane-heading-left">Road Score</div><br /> ${Bound_2_B2} `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Frontage_N2)
        : numberFormatter(segmentData?.attributes?.Frontage_2),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Frontage_N2).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Frontage_N2).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Frontage_2),
              colorCodeMapper['Pavement']['Frontage_2'],
            ),
      },
      __headerStyle: { ...headerColor, paddingLeft: '1px' },
    },
    'Sub-Standard Pavement SB': {
      __label: `Substandard Pavement `,
      __value: isNeed
        ? numberFormatter(segmentData?.attributes?.Sub_Standa_N)
        : convertToPercentage(segmentData?.attributes?.Sub_Standa),
      __style: {
        font: 'normal normal normal 14px Franklin Gothic',
        textAlign: 'center',
        border: '1px solid #ADB5BD',
        color: isNeed ? getNeedColorCode(segmentData?.attributes?.Sub_Standa_N).color : 'black',
        backgroundColor: isNeed
          ? getNeedColorCode(segmentData?.attributes?.Sub_Standa_N).backgroundColor
          : getColorCode(
              numberFormatter(segmentData?.attributes?.Sub_Standa) * 100,
              colorCodeMapper['Pavement']['Sub_Standa'],
            ),
      },
      __headerStyle: headerColor,
    },
  };
};
