
export const getRendererConfig = () => {
  return {
    type: "simple",
    symbol: {
      type: "simple-marker",
      style: "circle",
      color: "#6C757D",
      size: "5px",
      outline: {
        color: "#FFFFFF",
        width: 1,
      },
    },
  }
};

