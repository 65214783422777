import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TableFilter from './TableFilters/TableFilters';
import TableWithPagination from '../../../../Components/TableWithPagination/TableWithPagination';
import {
  getColorCode,
  getCorridorTierColors,
  getMetricTier,
  getOverallScore,
  getTierIntervalsPerMetric,
} from '../../../../Utils/CorridorRankingHelper';
import { localStorageGetWithExpiry } from '../../../../Utils/LocalStorage';
import { ROADWAY_CLASSIFICATIONS } from '../../../../Utils/Constants';
import { toPercentage } from '../../../../Utils/Functions';
import { useGlobalContext } from '../../../../Context/GlobalContext';

const MapTableOverview = ({ refreshMap, filterSegment, setHighlightCorridorId }) => {
  const [extractedData, setExtractedData] = useState([]);
  const [filters, setFilters] = useState({});
  const [customWeights, setCustomWeights] = useState({});
  const [activeMetric, setActiveMetric] = useState('overall');
  const [showValuesInstedOfScores, setShowValuesInstedOfScores] = useState(false);

  const { globalObject, districtFilterSW } = useGlobalContext();

  const scoresArray = useMemo(() => globalObject?.map(go => getOverallScore(go)), [globalObject, customWeights]);

  const onRowSelected = (_, rowData) => {
    setHighlightCorridorId(rowData?.Corridor_ID?.__value);
  };

  useEffect(() => {
    setFilters({ ...filters, district: districtFilterSW });
  }, [districtFilterSW]);

  const handleTableFilter = (type, search) => {
    if (type === 'filters') {
      setFilters(search);
      refreshMap();
      filterSegment();
    }
    if (type === 'weights') {
      setCustomWeights(search);
      refreshMap();
      filterSegment();
    }
    if (type === 'metric') {
      setActiveMetric(search);
    }
    if (type === 'switchValueScore') {
      setShowValuesInstedOfScores(search);
    }
  };
  const tierIntervals = useMemo(
    () => getTierIntervalsPerMetric(globalObject || [], (globalObject || [])?.length),
    [globalObject],
  );

  const tableViewColumns = useCallback(
    item => {
      const colorCode = getCorridorTierColors(
        scoresArray,
        getOverallScore(item), //.toFixed(2)
      );
      const Scr_Tot = getOverallScore(item).toFixed(2);
      const extendedString = showValuesInstedOfScores ? 'Value' : 'Score';
      switch (activeMetric) {
        case 'overall':
          return {
            'Overall Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Overall <br /> Score',
              __style: {
                textAlign: 'center',
                color: colorCode.color,
                backgroundColor: colorCode.backgroundColor,
              },
              __value: Scr_Tot,
            },
            'Pavement Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Pavement <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_1.toFixed(2), tierIntervals.pavement)),
                textAlign: 'center',
              },
              __value: item.Scr_1.toFixed(2),
            },
            'Bridge Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Bridge <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_2.toFixed(2), tierIntervals.bridge)),
                textAlign: 'center',
              },
              __value: item.Scr_2.toFixed(2),
            },
            'Safety Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Safety <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_3.toFixed(2), tierIntervals.safety)),
                textAlign: 'center',
              },
              __value: item.Scr_3.toFixed(2),
            },
            'Congestion Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Congestion <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_4.toFixed(2), tierIntervals.congestion)),
                textAlign: 'center',
              },
              __value: item.Scr_4.toFixed(2),
            },
            'Economic Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Economic <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_5.toFixed(2), tierIntervals.economic)),
                textAlign: 'center',
              },
              __value: item.Scr_5.toFixed(2),
            },
            'Connectivity Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Connectivity <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_6.toFixed(2), tierIntervals.connectivity)),
                textAlign: 'center',
              },
              __value: item.Scr_6.toFixed(2),
            },
          };

        case 'pavement':
          return {
            'Pavement Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Pavement <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_1, tierIntervals.pavement)),
                'text-align': 'center',
              },
              __value: item.Scr_1,
            },
            'Overall Score': { __hide: true, __value: Scr_Tot },
            [`Pavement Condition Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Pavement Condition <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? item.PaveCond.toFixed(2) : item.Scr_1_1.toFixed(2),
            },
            [`Pavement Hotspot Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Pavement Hotspot <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.PaveLess60) : item.Scr_1_2.toFixed(2),
            },
          };

        case 'bridge':
          return {
            'Bridge Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Bridge <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_2, tierIntervals.bridge)),
                'text-align': 'center',
              },
              __value: item.Scr_2,
            },
            'Overall Score': { __hide: true, __value: Scr_Tot },
            [`Bridge Sufficiency Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Bridge Sufficiency <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? item.BridgeSuff.toFixed(2) : item.Scr_2_1.toFixed(2),
            },
            [`Bridge Hotspot Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Bridge Hotspot <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.Bridge_60) : item.Scr_2_2.toFixed(2),
            },
          };

        case 'safety':
          return {
            'Safety Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Safety <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_3, tierIntervals.safety)),
                'text-align': 'center',
              },
              __value: item.Scr_3,
            },
            'Overall Score': { __hide: true, __value: Scr_Tot },
            [`K&A Crash Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `K&A Crash <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? item.KA_Crash.toFixed(2) : item.Scr_3_1.toFixed(2),
            },
            [`Total Crash Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Total Crash <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? item.TotalCrash.toFixed(2) : item.Scr_3_2.toFixed(2),
            },
          };

        case 'congestion':
          return {
            'Congestion Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Congestion <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_4, tierIntervals.congestion)),
                'text-align': 'center',
              },
              __value: item.Scr_4,
            },
            'Overall Score': { __hide: true, __value: Scr_Tot },
            [`Existing V/C > 0.80 Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Existing V/C > 0.80 <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.ExistingVC, 1) : item.Scr_4_1.toFixed(2),
            },
            [`Future V/C >0.80 Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Future V/C > 0.80 <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.FutureVC, 1) : item.Scr_4_2.toFixed(2),
            },
            [`Top 100 Congested for All Vehicles Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Top 100 Congested for All Vehicles <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.T100_All, 1) : item.Scr_4_3.toFixed(2),
            },
            [`Top 100 Congested for Trucks Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Top 100 Congested for Trucks <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.T100_Truck, 1) : item.Scr_4_4.toFixed(2),
            },
          };

        case 'economic':
          return {
            'Economic Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Economic <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_5, tierIntervals.economic)),
                'text-align': 'center',
              },
              __value: item.Scr_5,
            },
            'Overall Score': { __hide: true, __value: Scr_Tot },
            [`Freight Volume Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Freight Volume <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? parseInt(item.FreightVol).toLocaleString() : item.Scr_5_1.toFixed(2),
            },
            [`Commodity Flow Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Commodity Flow <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores
                ? parseInt(item.CommFlow) === 0
                  ? 'N/A'
                  : parseInt(item.CommFlow).toLocaleString()
                : item.Scr_5_2.toFixed(2),
            },
            [`Existing Employment Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Existing Employment <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? parseInt(item.ExistEmp) : item.Scr_5_3.toFixed(2),
            },
            [`Existing Population Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Existing Population <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? parseInt(item.ExistPop) : item.Scr_5_4.toFixed(2),
            },
            [`Traffic Growth Rate Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `Traffic Growth Rate <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.ProjGrowth) : item.Scr_5_5.toFixed(2),
            },
            [`% Privately Held Land Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center' },
              __label: `% Privately Held Land <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.PrivateLan) : item.Scr_5_6.toFixed(2),
            },
          };

        case 'connectivity':
          return {
            'Connectivity Score': {
              __headerStyle: { textAlign: 'center' },
              __label: 'Connectivity <br /> Score',
              __style: {
                ...getColorCode(getMetricTier(item.Scr_6, tierIntervals.connectivity)),
                'text-align': 'center',
              },
              __value: item.Scr_6,
            },
            'Overall Score': { __hide: true, __value: Scr_Tot },
            [`Access to Existing Multi-modal Facilities or Major Traffic Generators Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center', minWidth: '100px' },
              __label: `Access to Existing Multi-modal Facilities or Major Traffic Generators <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? item.MultiModal.toFixed(2) : item.Scr_6_1.toFixed(2),
            },
            [`Part of Hurricane Evacuation Route Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center', minWidth: '100px' },
              __label: `Part of Hurricane Evacuation Route <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.Hurricane, 1) : item.Scr_6_2.toFixed(2),
            },
            [`Part of National Freight Network or TxDOT Primary Freight Network Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center', minWidth: '100px' },
              __label: `Part of National Freight Network or TxDOT Primary Freight Network <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.US_Freight, 1) : item.Scr_6_3.toFixed(2),
            },
            [`Part of Energy Sector Route Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center', minWidth: '100px' },
              __label: `Part of Energy Sector Route <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.EnergySect, 1) : item.Scr_6_4.toFixed(2),
            },
            [`Part of TxDOT Texas Trunk System Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center', minWidth: '100px' },
              __label: `Part of TxDOT Texas Trunk System <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.Trunk_Sys, 1) : item.Scr_6_5.toFixed(2),
            },
            [`Part of Strategic Highway Network Metric ${extendedString}`]: {
              __headerStyle: { textAlign: 'center', minWidth: '100px' },
              __label: `Part of Strategic Highway Network <br /> Metric ${extendedString}`,
              __style: { 'text-align': 'center' },
              __value: showValuesInstedOfScores ? toPercentage(item.Strat_Hwy, 1) : item.Scr_6_6.toFixed(2),
            },
            ...(showValuesInstedOfScores
              ? {
                  [`Part of International Trade Metric Value ${extendedString}`]: {
                    __headerStyle: { textAlign: 'center', minWidth: '100px' },
                    __label: `Part of International Trade Corridor <br /> Metric ${extendedString}`,
                    __style: { 'text-align': 'center' },
                    __value: toPercentage(item.TradeCorr_Length, 1),
                  },
                  [`Part of Regional Trade Metric Value ${extendedString}`]: {
                    __headerStyle: { textAlign: 'center', minWidth: '100px' },
                    __label: `Part of Regional Trade Corridor <br /> Metric ${extendedString}`,
                    __style: { 'text-align': 'center' },
                    __value: toPercentage(item.RegTradeCorr_Length, 1),
                  },
                  [`Part of Local Trade Metric Value ${extendedString}`]: {
                    __headerStyle: { textAlign: 'center', minWidth: '100px' },
                    __label: `Part of Local Trade Corridor<br /> Metric ${extendedString}`,
                    __style: { 'text-align': 'center' },
                    __value: toPercentage(item.LocTradeCorr_Length, 1),
                  },
                }
              : {
                  [`Part of Trade Corridor Metric Score ${extendedString}`]: {
                    __headerStyle: { textAlign: 'center', minWidth: '100px' },
                    __label: `Part of Trade Corridor<br /> Metric ${extendedString}`,
                    __style: { 'text-align': 'center' },
                    __value: item.Scr_6_7?.toFixed(2),
                  },
                }),
          };

        default:
          break;
      }
    },
    [activeMetric, scoresArray, showValuesInstedOfScores, tierIntervals, customWeights],
  );

  useEffect(() => {
    if (globalObject && globalObject?.length > 0) {
      const extractedValues = globalObject.map((item, index) => {
        const {
          HWY,
          Description,
          Begin_Milepost,
          End_Milepost,
          Districts,
          MPO,
          Scr_1,
          Scr_2,
          Scr_3,
          Scr_4,
          Scr_5,
          Scr_6,
          Custom,
          Corridor_ID,
        } = item;

        return {
          Route: HWY,
          Description: Description,
          'Begin Marker': {
            __headerStyle: { textAlign: 'center' },
            __style: { textAlign: 'center' },
            __value: Begin_Milepost,
          },
          'End Marker': {
            __headerStyle: { textAlign: 'center' },
            __style: { textAlign: 'center' },
            __value: End_Milepost,
          },
          ...tableViewColumns(item),
          Districts: { __hide: true, __value: Districts },
          MPO: { __hide: true, __value: MPO },
          Scr_1: { __hide: true, __value: Scr_1 },
          Scr_2: { __hide: true, __value: Scr_2 },
          Scr_3: { __hide: true, __value: Scr_3 },
          Scr_4: { __hide: true, __value: Scr_4 },
          Scr_5: { __hide: true, __value: Scr_5 },
          Scr_6: { __hide: true, __value: Scr_6 },
          Custom: Custom,
          Corridor_ID: {
            __hide: true,
            __value: Corridor_ID,
          },
        };
      });
      setExtractedData(extractedValues);
    }
  }, [globalObject, tableViewColumns]);

  const metricSorting = useCallback(
    (a, b) => {
      switch (activeMetric) {
        case 'overall':
          return parseFloat(a?.['Overall Score']?.__value) < parseFloat(b?.['Overall Score']?.__value) ? 1 : -1;

        case 'pavement':
          return a?.['Pavement Score']?.__value < b?.['Pavement Score']?.__value ? 1 : -1;

        case 'bridge':
          return a?.['Bridge Score']?.__value < b?.['Bridge Score']?.__value ? 1 : -1;

        case 'safety':
          return a?.['Safety Score']?.__value < b?.['Safety Score']?.__value ? 1 : -1;

        case 'congestion':
          return a?.['Congestion Score']?.__value < b?.['Congestion Score']?.__value ? 1 : -1;

        case 'economic':
          return a?.['Economic Score']?.__value < b?.['Economic Score']?.__value ? 1 : -1;

        case 'connectivity':
          return a?.['Connectivity Score']?.__value < b?.['Connectivity Score']?.__value ? 1 : -1;

        default:
          break;
      }
    },
    [activeMetric],
  );

  const filteredData = useMemo(() => {
    const roadwayClassifications = filters?.roadwayClassifications?.map(roadway => roadway?.value) || [];
    const includeOthers = roadwayClassifications.includes('OTHERS');

    const updatedData = customWeights
      ? extractedData.sort((a, b) => {
          return parseFloat(a['Overall Score'].__value) < parseFloat(b['Overall Score'].__value) ? 1 : -1;
        })
      : extractedData;

    const filteredArr = updatedData
      .filter(
        rec => roadwayClassifications.length === 0 || roadwayClassifications?.indexOf(rec?.Route.substring(0, 2)) > -1,
      )
      .filter(rec => {
        if (filters?.district && filters?.district !== '') {
          return rec?.Districts?.__value?.includes(filters?.district);
        } else {
          return rec;
        }
      })
      .filter(rec => {
        if (filters?.urbanRural && filters?.urbanRural !== '') {
          return rec?.MPO?.__value?.includes(filters?.urbanRural);
        } else {
          return rec;
        }
      });

    if (includeOthers) {
      const corridorTypes = ROADWAY_CLASSIFICATIONS.map(r => r.value);
      const othersCorridors = extractedData.filter(
        rec =>
          !roadwayClassifications.includes(rec.Route.slice(0, 2)) && !corridorTypes.includes(rec.Route.slice(0, 2)),
      );
      filteredArr.push(...othersCorridors);
    }

    return filteredArr
      .map(corridor => {
        let colorCode = getCorridorTierColors(scoresArray, corridor['Overall Score'].__value);
        return {
          ...corridor,
          Route: {
            __value: (
              <div>
                {corridor.Route}
                {corridor.Custom === 1 && (
                  <img
                    src="/assets/svg/SpecialPlus_segments.svg"
                    alt="custom segment icon"
                    className="custom-segment-icon-alone"
                  />
                )}
              </div>
            ),
            HWY: corridor.Route,
          },
          'Overall Score': {
            ...corridor['Overall Score'],
            __style: {
              textAlign: 'center',
              color: colorCode.color,
              backgroundColor: colorCode.backgroundColor,
            },
          },
          Custom: {
            __hide: true,
          },
        };
      })
      .sort((a, b) => metricSorting(a, b));
  }, [filters, customWeights, extractedData, scoresArray, metricSorting]);

  useEffect(() => {
    const localStoragefilters = localStorageGetWithExpiry('customFilters');
    if (localStoragefilters) {
      let roadwayClassifications = [];
      ROADWAY_CLASSIFICATIONS.forEach(rec => {
        if (localStoragefilters[rec.value]) roadwayClassifications = [...roadwayClassifications, { ...rec }];
      });
      setFilters(prev => ({
        ...prev,
        roadwayClassifications,
        district: localStoragefilters?.district || '',
        urbanRural: localStoragefilters?.urbanRural || '',
      }));
    }
    const localStorageCustomWeight = localStorageGetWithExpiry('customWeights');
    if (localStorageCustomWeight) {
      setCustomWeights(localStorageCustomWeight);
    }
  }, []);

  return (
    <>
      <TableFilter
        handleTableFilter={handleTableFilter}
        filters={filters}
        customWeights={customWeights}
        tableview
        showValueSwitch={activeMetric === 'overall'}
        showValuesInstedOfScores={showValuesInstedOfScores}
      />
      {filteredData && (
        <TableWithPagination
          onRowSelected={onRowSelected}
          data={filteredData}
          itemsPerPage={15}
          indexColumn={{ name: 'Rank', __style: {} }}
          globalObject={globalObject}
          customWeights={customWeights}
          isPaginationAvail={true}
        />
      )}
    </>
  );
};

export default MapTableOverview;
