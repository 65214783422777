import { useRef, useEffect, useCallback, useState } from 'react';

import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import esriConfig from '@arcgis/core/config';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';

import {
  DEFAULT_MAP_CENTRAL_POINT,
  DEFAULT_SPATIAL_REF,
  MAP_API_KEY,
  RENDERER_BACKGROUND,
  CORRIDOR_FEATURE_SERVICE_URL,
  CORRIDOR_CREATE_NEW_SEGMENT_URL,
  CORRIDOR_CREATE_NEW_MARKER_URL,
  RENDERER_HIGHLIGHT_MARKER,
} from '../../../../../Utils/Constants';
import { createPopupTemplate } from '../../../../../Utils/Functions';
import LabelClass from '@arcgis/core/layers/support/LabelClass';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';

export const getlineLabelClass = () => {
  return new LabelClass({
    labelExpressionInfo: {
      expression: '$feature.BMP',
    },
    symbol: new TextSymbol({
      color: 'black',
      font: {
        size: 10,
        weight: 'bold',
      },
      verticalAlignment: 'middle',
      haloColor: 'white',
      haloSize: '1px',
    }),
    labelPlacement: 'above-along',
    deconflictionStrategy: 'none',
  });
};

function useMapView() {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const mapContainerRef = useRef(null);
  const mapViewRef = useRef(null);
  const viewRef = useRef(null);
  const corridorLayerRef = useRef();
  const corridorKeyMarkerLayerRef = useRef(null);

  const generateMap = useCallback(() => {
    esriConfig.apiKey = MAP_API_KEY;
    setIsMapLoaded(false);
    const lineLabelInfo = getlineLabelClass();
    // Create the corridor layer
    const corridorLayer = new FeatureLayer({
      popupTemplate: createPopupTemplate(),
      url: CORRIDOR_CREATE_NEW_SEGMENT_URL,
      title: 'Selected Corridors',
      id: 'selected-corridors',
      dockEnabled: false,
    });

    // Create the marker layer
    const corridorKeyMarkerLayer = new FeatureLayer({
      url: CORRIDOR_CREATE_NEW_MARKER_URL,
      outFields: ['*'],
      title: 'Segment Breakpoints',
      renderer: RENDERER_HIGHLIGHT_MARKER,
      labelingInfo: [lineLabelInfo],
    });
    // A copy of the corridor layer in the background
    const corridorLayerBackground = new FeatureLayer({
      url: CORRIDOR_FEATURE_SERVICE_URL,
      title: 'Candidate Corridors',
      id: 'candidate-corridors',
      dockEnabled: false,
      renderer: RENDERER_BACKGROUND,
    });

    // Create the map
    const map = new Map({
      basemap: 'arcgis-light-gray',
      layers: [corridorLayerBackground, corridorLayer, corridorKeyMarkerLayer],
      spatialReference: DEFAULT_SPATIAL_REF,
    });

    // Create the map view
    const view = new MapView({
      map: map,
      center: DEFAULT_MAP_CENTRAL_POINT,
      zoom: 5.75,
      container: mapContainerRef.current,
      constraints: {
        snapToZoom: false,
      },
    });

    // Initialize Refs values
    corridorLayerRef.current = corridorLayer;
    corridorKeyMarkerLayerRef.current = corridorKeyMarkerLayer;
    mapViewRef.current = map;
    viewRef.current = view;
    setIsMapLoaded(true);

    // Clean up
    return () => {
      view.destroy();
    };
  }, []);

  const refreshMap = useCallback(() => {
    if (viewRef.current) {
      viewRef.current.container = null;
      viewRef.current = null;
    }
    generateMap();
  }, [generateMap]);

  useEffect(() => {
    generateMap();
  }, [generateMap]);

  return {
    mapContainerRef,
    mapViewRef,
    viewRef,
    corridorLayerRef,
    corridorKeyMarkerLayerRef,
    refreshMap,
    isMapLoaded,
  };
}

export default useMapView;
