export const getRendererConfigOne = () => {
  return {
    type: "simple",
    symbol: {
      type: "simple-marker",
      style: "circle",
      color: "#F68B1F",
      size: "5px",
      outline: {
        color: "#FFFFFF",
        width: 1,
      },
    },
  };
};

export const getRendererConfigTwo = () => {
  return {
    type: "simple",
    symbol: {
      type: "simple-marker",
      style: "circle",
      color: "#6C757D",
      size: "5px",
      outline: {
        color: "#FFFFFF",
        width: 1,
      },
    },
  }
};

