import React from "react";

const Tab = ({ label, isActive, onClick }) => {
  const handleClick = () => {
    onClick(label);
  }

  return (
    <button className={isActive ? "active-tab" : "inactive-tab"} onClick={handleClick}>
      {label}
    </button>
  );
};

export default Tab;