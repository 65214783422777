export const localStorageSetWithExpiry = (key, value, expiryMinutes) => {
  const now = new Date();
  const toMilliseconds = expiryMinutes * 60000;
  const item = {
    value: value,
    expiry: now.getTime() + toMilliseconds,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const localStorageGetWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const localStorageSet = (key, value) => {
  const item = {
    value: value,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const localStorageGet = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  return item.value;
};

export const localStorageRemove = (keysToRemove) => {
  if (Array.isArray(keysToRemove)) {
    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
  } else {
    localStorage.removeItem(keysToRemove);
  }
};
